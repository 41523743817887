import React from "react";
import "./style.css";

export const Loading = () => {
    return (
        <div className="loading-screen">
            <svg
                className="loading-screen-spinner"
                width="65px"
                height="65px"
                viewBox="0 0 66 66"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    className="loading-screen-path"
                    fill="none"
                    strokeWidth="6"
                    strokeLinecap="round"
                    cx="33"
                    cy="33"
                    r="30"
                ></circle>
            </svg>
        </div>
    );
};
