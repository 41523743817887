import React, { useEffect, useState } from "react";
import httpClient from "../../client/client";
interface IImageUploadProps {
    image: string;
    onUploaded: (url: string) => void;
}
function ImageUpload(props: IImageUploadProps) {
    const [image, setImage] = useState<any>(null);
    const [url, setUrl] = useState<string>("");
    const [progress, setProgress] = useState(0);
    const allowImageResolution = ["960x960", "576x960", "960x576"];
    const acceptedImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    const maxFileSize = 0.3 * 1024 * 1024; // 1MB
    const handleChange = (e) => {
        const selectedFile = e.target.files[0];
        isValidImage(selectedFile).then((res) => {
            if (res) {
                setImage(selectedFile);
                handleUpload(selectedFile);
            } else {
                setImage(null);
            }
        });
    };
    useEffect(() => {
        setUrl(props.image);
    }, [props.image]);

    const uploadFile = async (_image) => {
        if (!_image) return;

        // Get the presigned URL from your Fastify backend
        try {
            // random file name from timestamp
            let imageName =
                new Date().getTime() +
                Math.random().toString(36).substring(2) +
                _image.name.substring(_image.name.lastIndexOf("."), _image.name.length);
            const response = await httpClient.post("/presigned-url", {
                filename: imageName,
                filetype: _image.type,
            });

            const presignedUrl = response.data.url;
            const imageUrl = response.data.imageUrl;

            // Upload the file to S3 using the presigned URL
            const requestOptions: RequestInit = {
                method: "PUT",
                headers: {
                    "Content-Type": _image.type,
                },
                body: _image,
                redirect: "follow",
            };

            await fetch(presignedUrl, requestOptions);
            setUrl(imageUrl);
            props.onUploaded(imageUrl);
            console.log("File uploaded successfully:", imageUrl);
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    const handleUpload = (_image) => {
        if (_image === null || _image === undefined) {
            alert("Please select an image to upload");
            return;
        }
        uploadFile(_image);
    };
    const isValidImage = async (file) => {
        if (file === null || file === undefined) return false;

        // Load the image data using a FileReader
        const image = new Image();
        image.src = URL.createObjectURL(file);

        return new Promise((resolve) => {
            image.onload = () => {
                const width = image.width;
                const height = image.height;

                const isImageTypesValid = acceptedImageTypes.includes(file.type);
                if (!isImageTypesValid) {
                    alert("Please upload an image with type " + acceptedImageTypes.map((item) => item + ","));
                    resolve(false);
                }

                const isImageResolutionsValid = allowImageResolution.includes(`${width}x${height}`);
                console.log(width, height);
                if (!isImageResolutionsValid) {
                    alert("Please upload an image with resolution " + allowImageResolution.map((item) => item + ","));
                    resolve(false);
                }

                const isFileSizeValid = file.size <= maxFileSize;
                if (!isFileSizeValid) {
                    alert(
                        "Please upload an image with size less than " +
                            maxFileSize / 1024 / 1024 +
                            "MB" +
                            ", but your image size is " +
                            file.size / 1024 / 1024 +
                            "MB"
                    );
                    resolve(false);
                }
                resolve(isImageTypesValid && isImageResolutionsValid && isFileSizeValid);
            };
        });
    };

    return (
        <div>
            <progress value={progress} max="100" />
            <br />
            <div>
                <label htmlFor="image-upload"></label>
                <input type="file" id="image-upload" onChange={handleChange} placeholder="Select an image" />
                <div className="text-sm text-red-500">
                    allow image {allowImageResolution.map((item) => item + "px, ")}
                </div>
                <div className="text-sm text-red-500">
                    accept image types {acceptedImageTypes.map((item) => item + ", ")}
                </div>
                <div className="text-sm text-red-500">allow image size less than {maxFileSize / 1024 / 1024}MB</div>
            </div>
            {url ? <img src={url} crossOrigin="anonymous" alt="Uploaded Images" height="300" width="400" /> : null}
        </div>
    );
}

export default ImageUpload;
