import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import number from "../../../helpers/number-format";
import { useUserContext } from "../../../hooks/auth";
import { DropDownMenu } from "../DropDownMenu";
import "./style.css";
import CountUp from "react-countup";

export const Navbar = (): JSX.Element => {
    const [startBalance, setStartBalance] = useState(0);
    const [endBalance, setEndBalance] = useState(0);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { user, reloadUserPoint } = useUserContext();
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const scrollToHowToPlay = () => {
        const element = document.getElementById("how-to-play");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    const scrollToRaffle = () => {
        const element = document.getElementById("box-list");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };
    
    useEffect(() => {
        if (user?.id) {
            setStartBalance(endBalance);
            setEndBalance(user.point);
        }
      }, [user]);

    return (
        <div className={"navbar-navbar"}>
            <Link className={"navbar-logo"} to="/">
                <img className={""} src={"/img/logo/RandomJigsaw.png"} />
            </Link>
            <div className={"navbar-menu"}>
                <div className={"navbar-home-button"}>
                    <img className={"navbar-iconsax-linear-box"} src={"/img/ItemBoxDesktop/home.png"} />
                    <Link className={"navbar-element-3337-12968"} to="/">
                        Home
                    </Link>
                </div>
                <div className={"navbar-raffle-button"}>
                    <Link
                        className={"raffle-button-link-container"}
                        to="/"
                        onClick={() => setTimeout(scrollToRaffle, 100)}
                    >
                        <img className={"jigsaw"} src={"/img/ItemBoxDesktop/jigsaw.png"} />
                        <text className="navbar-element-3337-12973">Random Jigsaws</text>
                    </Link>
                </div>
                <div className={"navbar-howto-button"}>
                    <Link
                        className={"howto-button-link-container"}
                        to="/"
                        onClick={() => setTimeout(scrollToHowToPlay, 100)}
                    >
                        <img
                            className={"navbar-iconsax-linear-infocircle"}
                            src={"/img/ItemBoxDesktop/iconsax-linear-infocircle-5.png"}
                        />
                        <text className="navbar-element-3337-12973">How It work</text>
                    </Link>
                </div>
            </div>
            {user != null ? (
                <div className={"navbar-profile-dropdown"}>
                    <div className={"nav-bar-div"}>
                        <img className={"nav-bar-icon"} src={"/img/coins.png"} />
                        {/* <div className={"nav-bar-coin-label"} onClick={reloadUserPoint}>
                            {number.format(user.point)}
                        </div> */}
                        <CountUp start={startBalance} end={endBalance} duration={2} decimals={2}>
                            {({ countUpRef, start }) => (
                            <span>
                            <span style={{color: "#ebab3c", paddingRight: "2px" }}>$</span>
                            <span ref={countUpRef} className="nav-bar-coin-label" onClick={reloadUserPoint} />
                            </span>
                            )}
                        </CountUp>
                        <Link to="/topup" className="nav-bar-icon-add-coin">
                            <img src={"/img/iconaddcoin.png"} />
                        </Link>
                    </div>
                    <div className={"navbar-profile-name"} onClick={toggleDropdown}>
                        <div className="text-ellipsis overflow-hidden ">{user.displayName}</div>
                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1 1L7 7L13 1"
                                stroke="#9C9DA1"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                    {isDropdownOpen && <DropDownMenu />}
                </div>
            ) : (
                <div className={"navbar-login-button"}>
                    <Link className={"navbar-element-3337-13086"} to="/login">
                        Log In
                    </Link>
                </div>
            )}
        </div>
    );
};
