import { useEffect, useState } from "react";
import { BoxProductForAdmin, getBoxProductByBoxIdForAdmin } from "../../../api/admin";
import { useUserContext } from "../../../hooks/auth";
import { useDialogContext } from "../../../hooks/dialog";
import { AdminHeader, AdminPanel } from "../../../components/Layout/AdminPanel";
import { AdminPageEnum, useAdminSystemContext } from "../../../hooks/admin";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import number from "../../../helpers/number-format";
import { AdminButton } from "../../../components/AdminButton";

export const BoxProductsAdminPage = () => {
    const { boxId } = useParams();

    const { isAdmin, user } = useUserContext();
    const { handlerActiveAdminPage } = useAdminSystemContext();
    const { showLoading, closeLoading } = useDialogContext();

    const dateFormater = new Intl.DateTimeFormat("th", { dateStyle: "medium", timeZone: "Asia/Bangkok" });
    const [boxProducts, setBoxProducts] = useState<BoxProductForAdmin[]>([]);

    const handleError = (err: { title: string; message: string }) => {
        toast(
            <div>
                <h1 className="font-bold text-red-400">{err.title}</h1>
                <p className="text-xs">{err.message}</p>
            </div>,
            {
                type: "error",
            },
        );
    };

    useEffect(() => {
        showLoading();
        if (user) {
            if (!isAdmin) {
                window.location.href = "/403";
            } else {
                if (boxId && !isNaN(Number(boxId))) {
                    getBoxProductByBoxIdForAdmin(Number(boxId))
                        .then(setBoxProducts)
                        .finally(() => {
                            closeLoading();
                        });
                } else {
                    closeLoading();
                    handleError({
                        title: "Something went wrong!",
                        message: "Please try again",
                    });
                }
            }
        }
    }, [user]);

    handlerActiveAdminPage(AdminPageEnum.boxs);
    return (
        <>
            <AdminPanel>
                <>
                    {/* Header */}
                    <AdminHeader
                        pageName="Dashboard"
                        historyList={[
                            {
                                name: "Boxs",
                                route: "/admin/boxs",
                            },
                            {
                                name: "BoxProducts",
                                route: `/admin/boxs/${boxId}/products`,
                            },
                        ]}
                    />
                    {/* Table Data */}
                    <div className="flex flex-col p-4 m-10 max-lg:m-0 max-lg:pt-10 bg-white rounded-lg shadow">
                        <h1 className="text-lg font-bold">BoxProduct</h1>
                        <p className="text-sm text-gray-400">ข้อมูลสินค้าในกล่องสุ่ม</p>
                        <table className="w-full p-2 mt-5 border table-auto">
                            <thead className="py-2 text-gray-400">
                                <tr className="py-2">
                                    <th className="text-left">Id</th>
                                    {/* <th className="text-left">boxId</th> */}
                                    <th className="text-left">Name</th>
                                    <th className="text-left">Brand</th>
                                    <th className="text-left">Image</th>
                                    <th className="text-left">Grade</th>
                                    <th className="text-left">Price</th>
                                    <th className="text-left">DropRate</th>
                                    <th className="text-left">MinMargin</th>
                                    <th className="text-left">MaxDrop</th>
                                    <th className="text-left">IsDefault</th>
                                    <th className="text-left"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {boxProducts.map((item, index) => (
                                    <tr
                                        key={"box-product-id-" + index}
                                        className="mt-5"
                                        style={{ borderTop: "1px solid #f0f0f0" }}
                                    >
                                        <td className="py-4 text-left">{item.id}</td>
                                        {/* <td className="py-4 text-left">{item.boxId}</td> */}
                                        <td className="py-4 text-left w-[200px]">{item.name}</td>
                                        <td className="py-4 text-left w-[200px]">{item.brand}</td>
                                        <td className="py-4 text-left w-[200px]">
                                            <img
                                                src={item.imageUrl}
                                                style={{
                                                    objectFit: "contain",
                                                    alignSelf: "stretch",
                                                    width: "89px",
                                                    height: "100px",
                                                }}
                                            ></img>
                                        </td>
                                        <td className="py-4 text-left">{item.grade}</td>
                                        <td className="py-4 text-left">{number.format(item.price)}</td>
                                        <td className="py-4 text-left">{item.currentDropRate}</td>
                                        <td className="py-4 text-left">{number.format(item.minMarginToDrop ?? 0)}</td>
                                        <td className="py-4 text-left">{number.format(item.maxDrop)}</td>
                                        <td className="py-4 text-left">
                                            {item.isDefaultBoxProduct ? "true" : "false"}
                                        </td>
                                        <td>
                                            <AdminButton
                                                main="secondary"
                                                onClick={() => {
                                                    window.location.href = `/admin/boxs/${item.boxId}/products/${item.id}`;
                                                }}
                                            >
                                                Manage
                                            </AdminButton>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            </AdminPanel>
        </>
    );
};
