import httpClient from "../client/client";
import { IFetchInventoryResponse } from "../screens/InventoryDesktop/InventoryDesktop";

export const sellItemsInInventory = async (inventoriesId: number[]): Promise<void> => {
    return httpClient.post("/account/inventory/sell", { inventoriesId: inventoriesId });
};

export const fetchItemsInInventory = async (): Promise<IFetchInventoryResponse> => {
    return httpClient.get<IFetchInventoryResponse>("/jigsaw-product/inventory/all").then((res) => {
        return res.data;
    });
};
