import ReactDOM from "react-dom";
// import MessengerCustomerChat from "react-messenger-customer-chat";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./tailwind-style.css";
import { App } from "./App";

const app = document.getElementById("app");
ReactDOM.render(
    <>
        <App />
        <ToastContainer />
        {/* <MessengerCustomerChat pageId="100090692531431" appId="1869246966822829" /> */}
    </>,
    app,
);
