import { useEffect, useState } from "react";
import { AccountForAdmin, getAccountsForAdmin } from "../../../api/admin";
import { useUserContext } from "../../../hooks/auth";
import { useDialogContext } from "../../../hooks/dialog";
import { AdminHeader, AdminPanel } from "../../../components/Layout/AdminPanel";
import { AdminPageEnum, useAdminSystemContext } from "../../../hooks/admin";
import number from "../../../helpers/number-format";
import { AdminButton } from "../../../components/AdminButton";
import AdminMembersTable from "../../../components/AdminTable/AdminMembersTable";

export const AccountsAdminPage = () => {
    const { isAdmin, user } = useUserContext();
    const { handlerActiveAdminPage } = useAdminSystemContext();
    const { showLoading, closeLoading } = useDialogContext();

    const dateFormater = new Intl.DateTimeFormat("th", { dateStyle: "medium", timeZone: "Asia/Bangkok" });
    const [accounts, setAccounts] = useState<AccountForAdmin[]>([]);

    useEffect(() => {
        showLoading();
        if (user) {
            if (!isAdmin) {
                window.location.href = "/403";
            } else {
                getAccountsForAdmin()
                    .then(setAccounts)
                    .finally(() => {
                        closeLoading();
                    });
            }
        }
    }, [user]);

    handlerActiveAdminPage(AdminPageEnum.accounts);
    return (
        <>
            <AdminPanel>
                <>
                    {/* Header */}
                    <AdminHeader
                        pageName="Dashboard"
                        historyList={[
                            {
                                name: "Accounts",
                                route: "/admin/accounts",
                            },
                        ]}
                    />

                    <div className="p-4 m-8 bg-white rounded-lg shadow">
                        <div className="flex justify-between">
                            <div className="flex flex-col">
                                <h1 className="text-lg font-bold">User Account</h1>
                                <p className="text-sm text-gray-400">บัญชีผู้ใช้</p>
                            </div>
                        </div>
                        <div>
                            <AdminMembersTable data={accounts} dateFormater={dateFormater} />
                        </div>
                    </div>
                </>
            </AdminPanel>
        </>
    );
};
