import httpClient from "../client/client";

export const fetchUserPoint = async (): Promise<{
    point: number;
    cweb3Point: number;
    ccoinbotPoint: number;
    ccommuPoint: number;
}> => {
    return httpClient.get("/account/points").then((res) => {
        return res.data;
    });
};
