import React from "react";
import { FooterDesk } from "../../components/Footer/Desktop/FooterDesk";
import { Navbar } from "../../components/Navbar/Desktop";
import "./style.css";
import { useUserContext } from "../../hooks/auth";
import { toast } from "react-toastify";

export const InvitefriendDesktop = (): JSX.Element => {
    const { user } = useUserContext();

    const copyToClipboard = () => {
        navigator.clipboard.writeText(user?.code ?? "");
        toast("คัดลอกรหัสเชิญเพื่อนสำเร็จ", { autoClose: 200 });
    };

    return (
        <div className={"invitefriend-desktop-invitefriend-desktop"}>
            <img className={"invitefriend-desktop-BG"} src={"/img/inviteFriend/bg-1.png"} />
            <Navbar />
            <div className={"invitefriend-desktop-content-section"}>
                <div
                    className={"invitefriend-desktop-banner"}
                    style={{
                        backgroundImage: "url(/img/inviteFriend/bg.gif)",
                    }}
                >
                    <h1 className={"invitefriend-desktop-banner-label"}>
                        <span className={"invitefriend-desktop-text-wrapper"}>คุณจะได้รับโบนัส </span>
                        <span className={"invitefriend-desktop-span"}>
                            {`${(user?.commisionRate ?? 0) * 100}%`}
                            <br />
                        </span>
                        <span className={"invitefriend-desktop-text-wrapper"}>ทุก ๆ การเติมเงินของเพื่อน</span>
                    </h1>
                </div>
                <div className={"invitefriend-desktop-invite-friend"}>
                    <div className={"invitefriend-desktop-container"}>
                        <div className={"invitefriend-desktop-blur-BG"} />
                        <div className={"invitefriend-desktop-topic"}>
                            <div className={"invitefriend-desktop-left"}>
                                <div className={"invitefriend-desktop-div"}>
                                    <img
                                        className={"invitefriend-desktop-line"}
                                        src={"/img/inviteFriend/line-12.png"}
                                    />
                                    <div className={"invitefriend-desktop-ellipse"} />
                                </div>
                            </div>
                            <div className={"invitefriend-desktop-invite-title"}>
                                <div className={"invitefriend-desktop-invite-label"}>เชิญเพื่อนของคุณ</div>
                            </div>
                            <div className={"invitefriend-desktop-right"}>
                                <div className={"invitefriend-desktop-div"}>
                                    <img
                                        className={"invitefriend-desktop-line-12"}
                                        src={"/img/inviteFriend/line-12-1.png"}
                                    />
                                    <div className={"invitefriend-desktop-ellipse"} />
                                </div>
                            </div>
                        </div>
                        <div className={"invitefriend-desktop-invite-info"}>
                            <div className={"invitefriend-desktop-code-invite-friend"}>
                                <div className={"invitefriend-desktop-code-invite-label"}>รหัสเชิญของคุณ</div>
                                <div className={"invitefriend-desktop-code-box"}>
                                    <div className={"invitefriend-desktop-overlap-group"}>
                                        <div className={"invitefriend-desktop-code-label"}>{user?.code ?? "-"}</div>
                                        <img
                                            onClick={copyToClipboard}
                                            className={"invitefriend-desktop-icon-copy cursor-pointer"}
                                            src={"/img/inviteFriend/iconcopy.png"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={"invitefriend-desktop-howto-invite-friend"}>
                                <div className={"invitefriend-desktop-text-wrapper-2"}>วิธีการเชิญเพื่อน</div>
                                <div className={"invitefriend-desktop-text-wrapper-3"}>
                                    ให้เพื่อนของคุณเข้าเว็บไซต์เเละสมัครสมาชิก โดยกรอกรหัส
                                    <br />
                                    การเชิญของคุณ ที่หน้าลงทะเบียน
                                </div>
                            </div>
                        </div>
                        <div className={"invitefriend-desktop-history"}>
                            <div className={"invitefriend-desktop-div-2"}>
                                <div className={"invitefriend-desktop-text-wrapper-4"}>เพื่อนที่ชวน</div>
                                <div className={"invitefriend-desktop-text-wrapper-4"}>เชิญเมื่อวันที่</div>
                                <div className={"invitefriend-desktop-text-wrapper-5"}>โบนัสที่ได้</div>
                            </div>
                            <div className={"invitefriend-desktop-text-wrapper-8 mt-6"}>ไม่มีประวัติการเชิญ</div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterDesk />
        </div>
    );
};
