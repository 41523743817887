import DataTable, { TableColumn } from "react-data-table-component";
import { IAdminLiveDropHistory } from "../../../api/admin";
import { customStyles } from "../AdminTable";

interface DataTableProps {
    data?: IAdminLiveDropHistory[];
    dateFormater: Intl.DateTimeFormat;
}

const AdminMysteryBoxHistory = (props: DataTableProps) => {
    const { data, dateFormater } = props;

    const columns: TableColumn<IAdminLiveDropHistory>[] = [
        {
            name: <div>Id</div>,
            cell: (row) => <div>{row.id}</div>,
        },
        {
            name: <div>BoxName</div>,
            cell: (row) => <div>{row.productName}</div>,
        },
        {
            name: <div>jigsawPieceName</div>,
            center: true,
            cell: (row) => <div>{row.jigsawPieceName}</div>,
        },
        {
            name: <div>ownerEmail</div>,
            center: true,
            cell: (row) => <div>{row.ownerEmail}</div>,
        },
        {
            name: <div>OpenAt</div>,
            center: true,
            cell: (row) => <div> {dateFormater.format(new Date(row.createdAt))}</div>,
        },
    ];

    return (
        <DataTable
            pagination
            noDataComponent={<>No Data Not Found</>}
            columns={columns}
            data={data ?? []}
            customStyles={customStyles}
            className="max-w-[1112px] overflow-x-scroll"
        />
    );
};

export default AdminMysteryBoxHistory;
