import { Link } from "react-router-dom";
import "./style.css";
import number from "../../../../helpers/number-format";

export const ItemBox = ({
    id,
    imageUrl,
    name,
    price,
}: {
    id: number;
    name: string;
    description: string;
    imageUrl: string;
    price: number;
}): JSX.Element => {
    return (
        <Link to={"/box/" + id} className={"home-desk-item-list"}>
            <div className={"home-desk-item-product "}>
                <div className={"home-desk-overlap-group-2"}>
                    <div className={"home-desk-BG"} />
                    <img className={"home-desk-product-image"} src={imageUrl} />
                    <div className={"home-desk-product-name"}>{name}</div>
                    <img className={"home-desk-price-BG"} src={"./img/pricebg@2x.png"} />
                    <div className={"home-desk-price-label"}>${number.format(price)} / PIECE</div>
                </div>
            </div>
        </Link>
    );
};
