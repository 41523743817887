import { JigsawInventoryPiece } from "../../../../interfaces/Box";
import "./style.css";
import JigsawInventoryPieceCard from "../JigsawInventoryPieceCard";
import { useEffect, useState } from "react";

interface IBurnPopup {
    unusedJigsawPiece: JigsawInventoryPiece[];
    handleBurnClick: (InventoryJigsawPieceId: number[]) => void;
    onClosed: () => void;
}

export const BurnPopup = (props: IBurnPopup): JSX.Element => {
    const [burnedJigsawPiece, setBurnedJigsawPiece] = useState<JigsawInventoryPiece[]>([]);
    useEffect(() => {
        if (props.unusedJigsawPiece.length >= 3) {
            setBurnedJigsawPiece(props.unusedJigsawPiece.slice(0, 3));
            console.log("props.unusedJigsawPiece", props.unusedJigsawPiece.slice(0, 3));
        } else {
            setBurnedJigsawPiece(props.unusedJigsawPiece);
        }
    }, [props.unusedJigsawPiece]);
    return (
        <>
            <div className="burn-popup-bg" onClick={props.onClosed}></div>
            <div className="burn-popup">
                <div className="burn-popup-header">BURN 3 PIECES for 1 free spin</div>
                <div className="burn-popup-detail">you have {props.unusedJigsawPiece.length} unused jigsaw pieces</div>
                <div className="my-6 flex gap-6">
                    {burnedJigsawPiece.map((item, index) => {
                        return (
                            <JigsawInventoryPieceCard
                                key={index}
                                image={item.img ? item.img : ""}
                                name={item.name ? item.name : ""}
                                round={item.roundNumber}
                            ></JigsawInventoryPieceCard>
                        );
                    })}
                </div>
                {burnedJigsawPiece.length >= 3 ? (
                    <div
                        className="burn-popup-btn"
                        onClick={() => {
                            props.handleBurnClick(burnedJigsawPiece.map((item) => item.id));
                            props.onClosed();
                        }}
                    >
                        <div>BURN 3 PIECES</div>
                    </div>
                ) : (
                    <div className="burn-popup-btn disabled">
                        <div>NOT ENOUGH PIECES</div>
                    </div>
                )}
            </div>
        </>
    );
};
