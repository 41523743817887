import DataTable, { TableColumn } from "react-data-table-component";
import { DetailShipping, ShippingDetail } from "../../../interfaces/Shipping";
import { customStyles } from "../AdminTable";
import { AdminButton } from "../../AdminButton";
import { useCallback, useState } from "react";
import { deleteShipping, patchShippingDetail } from "../../../api/shiping";
import Badge from "../../BadgeStatus";

interface AdminDeliveryTableProps {
    data?: ShippingDetail[];
    dateFormater: Intl.DateTimeFormat;
}

const AdminDeliveryTable = (props: AdminDeliveryTableProps) => {
    const { data } = props;
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [existDeliveryData, setExistDeliveryData] = useState<ShippingDetail>();

    const [formData, setFormData] = useState<DetailShipping>({
        nameShipping: '',
        phoneShipping: "",
        addressShipping: "",
        provinceShipping: "",
        districtShipping: "",
        subDistrictShipping: "",
        postalCodeShipping: "",
        remark: "",
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleOpenEditModal = useCallback(
        (row: ShippingDetail) => {
            setOpenEditModal(true);
            if (row) {
                setExistDeliveryData(row)
                setFormData({
                    nameShipping: row?.nameShipping,
                    phoneShipping: row?.phoneShipping,
                    addressShipping: row?.addressShipping,
                    provinceShipping: row?.provinceShipping,
                    districtShipping: row?.districtShipping,
                    subDistrictShipping: row?.subDistrictShipping,
                    postalCodeShipping: row?.postalCodeShipping,
                    remark: row?.remark
                });
            }
        },
        [existDeliveryData]
    );

    const handleOpenDeleteModal = useCallback((row: ShippingDetail) => {
        setOpenDeleteModal(true);
        if (row) {
            setExistDeliveryData(row);
        }
    }, [existDeliveryData]);

    const handleCloseDeleteModal = useCallback(() => setOpenDeleteModal(false), []);
    const handleOnSubmitDelete = useCallback(async() => {
        setOpenDeleteModal(false)
       if(existDeliveryData?.id){
            try {
                const res = await deleteShipping(existDeliveryData?.id);
                console.log("delivery data delete successfully", res);
                await  window.location.reload()
            } catch (error) {
                console.error("Failed to submit delete delivery data", error);
            }
       }
    }, [existDeliveryData]);

    const handleCloseEditModal = useCallback(() => setOpenEditModal(false), []);


    const handleSubmit =useCallback( async (event) => {
        event.preventDefault();
       if(existDeliveryData?.id){
            try {
                const res = await patchShippingDetail(existDeliveryData?.id, formData);
                console.log("delivery data update successfully", res);
                await  window.location.reload()
            } catch (error) {
                console.error("Failed to update delivery data", error);
            }
        }
    },[formData,existDeliveryData])

    const columns: TableColumn<ShippingDetail>[] = [
        {
            name: <div>Id</div>,
            cell: (row) => <div>{row.id}</div>,
        },
        {
            name: <div>Name</div>,
            width: "200px",
            cell: (row) => <div>{row.nameShipping}</div>,
        },
        {
            name: <div>Status</div>,
            width: "200px",
            cell: (row) => <div><Badge status={row.timeline.text}>{row.timeline.text}</Badge></div>,
        },
        {
            name: <div>Address</div>,
            cell: (row) => <div>{row.addressShipping}</div>,
        },
        {
            name: <div>Province</div>,
            cell: (row) => <div>{row.provinceShipping}</div>,
        },
        {
            name: <div>District</div>,
            cell: (row) => <div>{row.districtShipping}</div>,
        },
        {
            name: <div>SubDistrict</div>,
            cell: (row) => <div>{row.subDistrictShipping}</div>,
        },
        {
            name: <div>Postalcode</div>,
            cell: (row) => <div>{row.postalCodeShipping}</div>,
        },
        {
            name: <div>Phone</div>,
            cell: (row) => <div>{row.phoneShipping}</div>,
        },
        {
            name: <div>Remark</div>,
            cell: (row) => <div>{row.remark ?? "-"}</div>,
        },
        {
            name: <></>,
            center: true,
            cell: (row) => (
                <div className="flex gap-2">
                    <AdminButton
                        main="secondary"
                        onClick={() => {
                            handleOpenEditModal(row);
                        }}
                    >
                        Edit
                    </AdminButton>
                    <AdminButton
                        main="primary"
                        onClick={() => {
                            handleOpenDeleteModal(row);
                        }}
                    >
                        Delete
                    </AdminButton>
                </div>
            ),
        },
    ];


    return (
       <>
         <DataTable
            pagination
            customStyles={customStyles}
            noDataComponent={<>No Data Not Found</>}
            columns={columns}
            data={data ?? []}
            className="max-w-[1112px] overflow-x-scroll"
        />
        {openDeleteModal && (
                <div>
                    {/* TODO: Should spilt to compoenet */}
                    <div className="modal">
                        <div className="modal-content">
                            <div className="flex flex-col gap-8">
                                <div className="font-semibold">Are you sure you want to delete the category?</div>
                                <div className="flex gap-2 justify-end">
                                    <AdminButton main="secondary" 
                                    onClick={handleCloseDeleteModal}
                                    >
                                        Close
                                    </AdminButton>
                                    <AdminButton
                                        main="secondary"
                                        type="submit"
                                        onClick={handleOnSubmitDelete}
                                    >
                                        Confirm
                                    </AdminButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {openEditModal && (
                <div>
                    {/* TODO: Should spilt to compoenet */}
                    <div className="modal">
                        <div className="modal-content">
                            <h2 className="modal-head">Update Delivery</h2>
                            <form
                             onSubmit={handleSubmit}
                             >
                                <div className="flex flex-col gap-2 my-2">
                                        <div className="flex-1 flex gap-4">
                                            <div>
                                                <div>Name Shipping</div>
                                                <input
                                                    className="px-2"
                                                    name="nameShipping"
                                                    value={formData.nameShipping}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div>
                                                <div>Phone Shipping</div>
                                                <input
                                                    className="px-2"
                                                    name="phoneShipping"
                                                    value={formData.phoneShipping}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="flex-1 flex gap-4">
                                            <div>
                                                <div>Address Shipping</div>
                                                <input
                                                    className="px-2"
                                                    name="addressShipping"
                                                    value={formData.addressShipping}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div>
                                                <div>Province Shipping</div>
                                                <input
                                                    className="px-2"
                                                    name="provinceShipping"
                                                    value={formData.provinceShipping}
                                                    onChange={handleInputChange}
                                                />  
                                            </div>
                                            
                                        </div>
                                       
                                        <div className="flex-1 flex gap-4">
                                            <div>
                                                <div>District Shipping</div>
                                                <input
                                                    className="px-2"
                                                    name="districtShipping"
                                                    value={formData.districtShipping}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div>
                                                <div>SubDistrict Shipping</div>
                                                <input
                                                    className="px-2"
                                                    name="subDistrictShipping"
                                                    value={formData.subDistrictShipping}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="flex-1 flex gap-4">
                                            <div>
                                                <div>Postalcode Shipping</div>
                                                <input
                                                    className="px-2"
                                                    name="postalCodeShipping"
                                                    value={formData.postalCodeShipping}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div>
                                                <div>Remark</div>
                                                <input
                                                    className="px-2"
                                                    name="remark"
                                                    value={formData.remark}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                           
                                        </div> 
                                    <div className="flex gap-2 justify-end">
                                        <AdminButton main="secondary" onClick={handleCloseEditModal}>
                                            Close
                                        </AdminButton>
                                        <AdminButton main="secondary" type="submit">
                                            Submit
                                        </AdminButton>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
       </>
    );
};

export default AdminDeliveryTable;
