import httpClient from "../client/client";
import { balanceCWalletReponse, balanceCWalletRequest } from "../models/wallet";
export interface ICcoinBalance {
    ccoin: number;
    cwallet: number;
    ccoupon: number;
}
export const getWalletBalance = async (): Promise<ICcoinBalance> => {
    return httpClient.get<ICcoinBalance>("/cwallet/balance-c-wallet").then(res => {
        return res.data;
    });
};

export const withdrawWallet = async (body: balanceCWalletRequest): Promise<ICcoinBalance> => {
    return httpClient.post<ICcoinBalance>("/cwallet/withdraw-c-wallet", body).then(res => {
        return res.data;
    });
};
