import { useEffect, useState } from "react";

export const getValueFromURL = (url: string, parameter: string): string | undefined => {
    const regex = new RegExp(`[?&]${parameter}=([^&#]*)`);
    const match = regex.exec(url);
    return match && match[1] ? match[1] : undefined;
};

export const useTimeCounter = (startDate: Date) => {
    const [duration, setDuration] = useState<string>("");

    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = new Date();
            const diff = now.getTime() - startDate.getTime();

            // const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            // const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
            const hours = Math.floor(diff / (1000 * 60 * 60));
            const minutes = Math.floor((diff / (1000 * 60)) % 60);
            const seconds = Math.floor((diff / 1000) % 60);

            setDuration(`round duration ${hours}h ${minutes}m ${seconds}s`);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [startDate]);

    return duration;
};
