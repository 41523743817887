import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getShippingDetail } from "../../api/shiping";
import { Navbar } from "../../components/Navbar/Desktop";
import { useUserContext } from "../../hooks/auth";
import { ShippingWithTimelineDetail } from "../../interfaces/Shipping";
import { ItemToShip } from "../InventoryDesktop/ShippingProcessDesktop/ItemToShip/ItemToShip";
import "./style.css";
import number from "../../helpers/number-format";
import { FooterDesk } from "../../components/Footer/Desktop/FooterDesk";

export const ShippingDetail = () => {
    const navigate = useNavigate();
    const { stsTokenManager } = useUserContext();
    const { shippingId } = useParams();

    const [shippingDetail, setShippingDetail] = useState<ShippingWithTimelineDetail>();

    useEffect(() => {
        if (stsTokenManager?.accessToken) {
            if (!shippingId) navigate("/shipping");
            getShippingDetail(Number(shippingId)).then(setShippingDetail);
        }
    }, [stsTokenManager?.accessToken]);

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    const TimelineComponent = () => {
        return (
            <div className="flex flex-col p-8">
                {shippingDetail?.timeline.map((timeline, index) => (
                    <TimelineSection
                        isLatest={index === shippingDetail.timeline.length - 1}
                        date={moment(timeline.createdAt).format("ll")}
                        time={moment(timeline.createdAt).format("hh:mm") + " น."}
                        detail={timeline.text}
                    />
                ))}
            </div>
        );
    };

    const TimelineSection = ({
        date,
        time,
        detail,
        isLatest = false,
    }: {
        date: string;
        time: string;
        detail: string;
        isLatest?: boolean;
    }) => {
        return (
            <div className="grid grid-cols-12 text-gray-400 gap-x-8">
                <div className={`${isLatest ? "text-white" : ""} text-center col-span-3`}>
                    <p className="pb-2">{date}</p>
                    <p>{time}</p>
                </div>
                <div className="relative justify-center">
                    {!isLatest && <div className={`w-0.5 h-16 bg-gray-200`}></div>}
                    <div
                        style={{ marginLeft: "-7px", top: "0px" }}
                        className={`absolute w-4 h-4 ${
                            isLatest ? "bg-white" : "bg-gray-400"
                        } rounded-full justify-self-center top-1/2`}
                    ></div>
                </div>
                <div className={`${isLatest ? "text-yellow-500" : ""} whitespace-pre-wrap col-span-7`}>{detail}</div>
            </div>
        );
    };

    return (
        <div>
            {/* <img className={"inventory-desktop-BG"} src={"/img/bg2.png"} /> */}
            <div className={"bg-bulr"}>
                <Navbar />

                <div className={"shipping-process-desktop-content-section"}>
                    <div className={"shipping-process-desktop-delivery-title"}>
                        <div className={"shipping-process-desktop-vecter"}>
                            <div className={"shipping-process-desktop-div"}>
                                <img
                                    className={"shipping-process-desktop-line"}
                                    src={"/img/shippingProcess/line-12.png"}
                                />
                                <div className={"shipping-process-desktop-ellipse"} />
                            </div>
                        </div>
                        <img
                            className={"shipping-process-desktop-icon-delivery"}
                            src={"/img/shippingProcess/icondelivery.png"}
                        />

                        <div className={"inventory-desktop-label"}>Delivery</div>

                        <div className={"shipping-process-desktop-overlap-wrapper"}>
                            <div className={"shipping-process-desktop-div"}>
                                <img
                                    className={"shipping-process-desktop-line-12"}
                                    src={"/img/shippingProcess/line-12-1.png"}
                                />
                                <div className={"shipping-process-desktop-ellipse"} />
                            </div>
                        </div>
                    </div>
                    <div className={"shipping-process-desktop-body-section"}>
                        <div className={"shipping-process-desktop-address-detail"}>
                            <div className={"shipping-process-desktop-address-detail-2"}>
                                <div className="flex items-center text-xl font-bold gap-x-8">
                                    <h1 className="text-white">arrives between</h1>
                                    <h1 className="text-yellow-500">
                                        {shippingDetail?.shipping.arrivalTime
                                            ? moment(shippingDetail?.shipping.arrivalTime).format("llll")
                                            : "-"}
                                    </h1>
                                </div>
                                <div className="flex items-center text-lg gap-x-8">
                                    <h1 className="text-white">Tracking number</h1>
                                    <h1 className="text-yellow-500">
                                        {!!shippingDetail?.shipping.shippingNumber
                                            ? shippingDetail?.shipping.shippingNumber
                                            : "-"}
                                    </h1>
                                    {shippingDetail?.shipping.shippingNumber && (
                                        <div
                                            className={"shipping-success-desktop-text-wrapper-5"}
                                            onClick={() => {
                                                copyToClipboard(shippingDetail?.shipping.shippingNumber);
                                                toast("คัดลอกหมายเลขพัสดุสำเร็จ", { autoClose: 500 });
                                            }}
                                        >
                                            Copy
                                        </div>
                                    )}
                                </div>
                                <div className="w-full text-lg">
                                    <h1 className="mb-2 text-white">Details</h1>
                                    <div className={"shipping-success-desktop-overlap-group-wrapper"}>
                                        <div className={"shipping-success-desktop-overlap-group"}>
                                            <p className={"shipping-success-desktop-address-detail"}>
                                                {shippingDetail?.shipping.nameShipping}
                                                &nbsp;&nbsp;&nbsp;phone.&nbsp;
                                                {shippingDetail?.shipping.phoneShipping}
                                                <br />
                                                {shippingDetail?.shipping.addressShipping}
                                                &nbsp;
                                                {shippingDetail?.shipping.districtShipping}
                                                &nbsp;
                                                {shippingDetail?.shipping.provinceShipping}
                                                &nbsp;&nbsp;
                                                {shippingDetail?.shipping.postalCodeShipping}
                                            </p>
                                            <div className={"shipping-success-desktop-BG-2"} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ height: "1px" }} className="w-full bg-[#3A2F4E] mt-2"></div>
                                <TimelineComponent />
                            </div>
                        </div>
                        <div className={"shipping-process-desktop-dlivery-product-list"}>
                            <div className={"shipping-process-desktop-dlivery-product-list-2"}>
                                <div className={"shipping-process-desktop-dlivery-product-list-3"}>
                                    <div className={"shipping-process-desktop-dlivery-product-list-label"}>
                                        Items to be shipped {/* ({shippingDetail?.products.length} List) */}
                                    </div>
                                    <div className={"shipping-process-desktop-list"}>
                                        {shippingDetail?.products.map((item, index) => {
                                            return <ItemToShip key={index} itemInTheBox={item} />;
                                        })}
                                    </div>
                                </div>
                                <img
                                    className={"shipping-process-desktop-line-18"}
                                    src={"/img/shippingProcess/line-18.png"}
                                />
                                <div className={"shipping-process-desktop-frame"}>
                                    <div className={"shipping-process-desktop-frame-176"}>
                                        <div className={"shipping-process-desktop-text-wrapper-11"}>Shipping</div>
                                        <div className={"shipping-process-desktop-frame-2"}>
                                            <div className={"shipping-process-desktop-text-wrapper-12"}>
                                                {shippingDetail?.shipping.shippingFee
                                                    ? number.format(shippingDetail?.shipping.shippingFee)
                                                    : "-"}
                                            </div>
                                            <div className={"shipping-process-desktop-text-wrapper-10"}>USD</div>
                                        </div>
                                    </div>
                                    <div className={"shipping-process-desktop-frame-176"}>
                                        <div className={"shipping-process-desktop-text-wrapper-11"}>Total</div>
                                        <div className={"shipping-process-desktop-frame-2"}>
                                            <div className={"shipping-process-desktop-text-wrapper-12"}>
                                                 {shippingDetail?.shipping.shippingFee
                        ? number.format(shippingDetail?.shipping.shippingFee)
                        : "-"}
                                            </div>
                                            <div className={"shipping-process-desktop-text-wrapper-10"}>USD</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterDesk></FooterDesk>
        </div>
    );
};
