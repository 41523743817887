import number from "../../../../helpers/number-format";
import { ItemInTheBox_Unused } from "../../../../interfaces/ItemInTheBox";
import "./style.css";
interface Props {
    itemInTheBox: ItemInTheBox_Unused;
}
export const ItemToShip = (props: Props) => {
    return (
        <div className={"shipping-process-desktop-product-list-wrapper"}>
            <div className={"shipping-process-desktop-product-list"}>
                <div className={"shipping-process-desktop-product-list-detail"}>
                    <div className={"shipping-process-desktop-product-item-in-the-box-preview"}>
                        <div className={"shipping-process-desktop-product-info"}>
                            <img
                                alt={"Product"}
                                src={props.itemInTheBox.imageUrl}
                            />
                        </div>
                    </div>
                    <div className={"shipping-process-desktop-product-detail"}>
                        <p className={"shipping-process-desktop-p"}>
                            {props.itemInTheBox.name}
                        </p>
                        <div className={"shipping-process-desktop-product-price"}>
                            <div className={"shipping-process-desktop-text-wrapper-4"}>Price</div>
                            <div className={"shipping-process-desktop-text-wrapper-5"}>
                                ${number.format(props.itemInTheBox.price)}
                            </div>
                            <div className={"shipping-process-desktop-text-wrapper-7"}>x 1</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
