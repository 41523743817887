import clsx from "clsx";
import { AdminPageEnum, useAdminSystemContext } from "../../../hooks/admin";
import { Link } from "react-router-dom";

export const AdminPanel = ({ children }: { children?: JSX.Element }) => {
    const { showAdminMenu, activeAdminPage } = useAdminSystemContext();

    const dateTimeFormater = new Intl.DateTimeFormat("th", {
        dateStyle: "medium",
        timeStyle: "medium",
        timeZone: "Asia/Bangkok",
    });

    console.log("activeAdminPage ---->", activeAdminPage);

    return (
        <>
            <div className="flex flex-row min-h-screen bg-[#EBEDEF]">
                {/* Drawer */}
                <div
                    className={clsx(
                        { hidden: !showAdminMenu },
                        { "flex flex-col bg-[#f19d3c] w-[300px]": showAdminMenu }
                    )}
                >
                    {/* LOGO */}
                    <div className="flex-row bg-[#000000]">
                        <div className="flex flex-row h-[70px] justify-center items-center">
                            <Link to="/">
                                <img className="w-[180px]" src={"/img/logo/RandomJigsaw.png"} />
                            </Link>
                        </div>
                    </div>

                    {/* Menu List */}
                    <div className="flex flex-col bg-[#020101] h-full w-100">
                        <div className="py-4 overflow-y-auto">
                            <ul className="space-y-2 ">
                                <li>
                                    <a
                                        href="/admin/accounts"
                                        className={clsx(
                                            "flex flex-row justify-center items-center p-2 pl-5 text-white hover:bg-gray-700 ",
                                            { "bg-[#38aefc]": activeAdminPage === AdminPageEnum.accounts }
                                        )}
                                    >
                                        <span className="flex-1 ml-3 whitespace-nowrap">Member</span>
                                    </a>
                                </li>

                                <li>
                                    <a
                                        href="/admin/boxs"
                                        className={clsx(
                                            "flex flex-row justify-center items-center p-2 pl-5 text-white hover:bg-gray-700 ",
                                            { "bg-[#47556C]": activeAdminPage === AdminPageEnum.boxs }
                                        )}
                                    >
                                        <span className="flex-1 ml-3 whitespace-nowrap"> Mystery boxes</span>
                                    </a>
                                </li>

                                <li>
                                    <a
                                        href="/admin/openbox-history"
                                        className={clsx(
                                            "flex flex-row justify-center items-center p-2 pl-5 text-white hover:bg-gray-700",
                                            { "bg-[#47556C]": activeAdminPage === AdminPageEnum.openboxHistory }
                                        )}
                                    >
                                        <span className="flex-1 ml-3 whitespace-nowrap">Open Box History</span>
                                    </a>
                                </li>

                                <li>
                                    <a
                                        href="/admin/topup-history"
                                        className={clsx(
                                            "flex flex-row justify-center items-center p-2 pl-5 text-white hover:bg-gray-700",
                                            { "bg-[#47556C]": activeAdminPage === AdminPageEnum.topupHistory }
                                        )}
                                    >
                                        <span className="flex-1 ml-3 whitespace-nowrap">Top up History</span>
                                    </a>
                                </li>

                                <li>
                                    <a
                                        href="/admin/award-winner"
                                        className={clsx(
                                            "flex flex-row justify-center items-center p-2 pl-5 text-white hover:bg-gray-700",
                                            { "bg-[#47556C]": activeAdminPage === AdminPageEnum.awardwinner }
                                        )}
                                    >
                                        <span className="flex-1 ml-3 whitespace-nowrap">Award Winner History</span>
                                    </a>
                                </li>

                                {/* <li>
                                    <a
                                        href="/admin/random-jigsaw-history"
                                        className={clsx(
                                            "flex flex-row justify-center items-center p-2 pl-5 text-white hover:bg-gray-700",
                                            { "bg-[#47556C]": activeAdminPage === AdminPageEnum.randomjigsawhistory },
                                        )}
                                    >

                                        <span className="flex-1 ml-3 whitespace-nowrap">Random Jigsaw History</span>
                                    </a>
                                </li> */}

                                {/* <li>
                                    <a
                                        href="/admin/category"
                                        className={clsx(
                                            "flex flex-row justify-center items-center p-2 pl-5 text-white hover:bg-gray-700",
                                            { "bg-[#47556C]": activeAdminPage === AdminPageEnum.category }
                                        )}
                                    >
                                        <span className="flex-1 ml-3 whitespace-nowrap">Category</span>
                                    </a>
                                </li> */}

                                <li>
                                    <a
                                        href="/admin/delivery"
                                        className={clsx(
                                            "flex flex-row justify-center items-center p-2 pl-5 text-white hover:bg-gray-700",
                                            { "bg-[#47556C]": activeAdminPage === AdminPageEnum.delivery }
                                        )}
                                    >
                                        <span className="flex-1 ml-3 whitespace-nowrap">Delivery</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* Now Date Time */}
                    <div className="flex flex-row text-white bg-[#303D54] h-[70px] justify-center items-center p-3">
                        {dateTimeFormater.format(new Date())} {" น."}
                    </div>
                </div>

                <div className="flex flex-col w-full max-md:min-w-min bg-[#EBEDEF]">
                    {/* Header && Body */}
                    {children}
                </div>
            </div>
        </>
    );
};
