import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Navbar } from "../../components/Navbar/Desktop";
import { useUserContext } from "../../hooks/auth";
import { ShippingWithTimelineDetail } from "../../interfaces/Shipping";
import { ItemToShip } from "../InventoryDesktop/ShippingProcessDesktop/ItemToShip/ItemToShip";
import "./style.css";
import number from "../../helpers/number-format";
import { FooterDesk } from "../../components/Footer/Desktop/FooterDesk";

export const ShippingCreatDesktop = () => {
    const navigate = useNavigate();

    const [receiverName, setReceiverName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [subDistrict, setSubDistrict] = useState<string>("");
    const [district, setDistrict] = useState<string>("");
    const [province, setProvice] = useState<string>("");
    const [postCode, setPostCode] = useState<string>("");
    const [remark, setRemark] = useState<string>("");

    const fee = 0;

    return (
        <div>
            {/* <img className={"inventory-desktop-BG"} src={"/img/bg2.png"} /> */}
            <div className={"bg-bulr"}>
                <Navbar />

                <div className={"shipping-process-desktop-content-section"}>
                    <div className={"shipping-process-desktop-delivery-title"}>
                        <div className={"shipping-process-desktop-vecter"}>
                            <div className={"shipping-process-desktop-div"}>
                                <img
                                    className={"shipping-process-desktop-line"}
                                    src={"/img/shippingProcess/line-12.png"}
                                />
                                <div className={"shipping-process-desktop-ellipse"} />
                            </div>
                        </div>
                        <img
                            className={"shipping-process-desktop-icon-delivery"}
                            src={"/img/shippingProcess/icondelivery.png"}
                        />

                        <div className={"inventory-desktop-label"}>Delivery</div>

                        <div className={"shipping-process-desktop-overlap-wrapper"}>
                            <div className={"shipping-process-desktop-div"}>
                                <img
                                    className={"shipping-process-desktop-line-12"}
                                    src={"/img/shippingProcess/line-12-1.png"}
                                />
                                <div className={"shipping-process-desktop-ellipse"} />
                            </div>
                        </div>
                    </div>

                    <div className={"shipping-process-desktop-body-section"}>
                        <div className={"shipping-process-desktop-address-detail"}>
                            <div className={"shipping-process-desktop-address-detail-2"}>
                                <div className={"shipping-process-desktop-title-address-detail-label"}>
                                    delivery details
                                </div>

                                <div className="grid grid-cols-1 gap-2">
                                    <div className={"w-full"}>
                                        <div className={"shipping-process-desktop-text-wrapper"}>name - surname :</div>
                                        <input
                                            value={receiverName}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                setReceiverName(value);
                                            }}
                                            type={"text"}
                                            className={"shipping-process-desktop-input"}
                                        />
                                    </div>
                                    <div className={" "}>
                                        <div className={"shipping-process-desktop-text-wrapper"}>phone :</div>
                                        <input
                                            value={phoneNumber}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                setPhoneNumber(value);
                                            }}
                                            type={"text"}
                                            className={"shipping-process-desktop-input"}
                                        />
                                    </div>
                                    <div className={" "}>
                                        <div className={"shipping-process-desktop-text-wrapper"}>address :</div>
                                        <textarea
                                            value={address}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                setAddress(value);
                                            }}
                                            className={"shipping-process-desktop-input-2"}
                                        />
                                    </div>
                                    <div className={"shipping-process-desktop-address-detail-3"}>
                                        <div className={" "}>
                                            <div className={"shipping-process-desktop-text-wrapper"}>district :</div>
                                            <input
                                                value={subDistrict}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    setSubDistrict(value);
                                                }}
                                                type={"text"}
                                                className={"shipping-process-desktop-input-3"}
                                            />
                                        </div>
                                        <div className={" "}>
                                            <div className={"shipping-process-desktop-text-wrapper"}>province :</div>
                                            <input
                                                value={district}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    setDistrict(value);
                                                }}
                                                type={"text"}
                                                className={"shipping-process-desktop-input-3"}
                                            />
                                        </div>
                                    </div>
                                    <div className={"shipping-process-desktop-address-detail-3"}>
                                        <div className={" "}>
                                            <div className={"shipping-process-desktop-text-wrapper"}>country :</div>
                                            <input
                                                value={province}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    setProvice(value);
                                                }}
                                                type={"text"}
                                                className={"shipping-process-desktop-input-3"}
                                            />
                                        </div>
                                        <div className={" "}>
                                            <div className={"shipping-process-desktop-text-wrapper"}>post number :</div>
                                            <input
                                                value={postCode}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    setPostCode(value);
                                                }}
                                                type={"text"}
                                                className={"shipping-process-desktop-input-3"}
                                            />
                                        </div>
                                    </div>
                                </div>
                    
                                <div className={"shipping-process-desktop-button-group"}>
                                    <div className={"shipping-process-desktop-backward-button"}>
                                        <img
                                            className={"shipping-process-desktop-iconsax-linear-arrowleft"}
                                            src={"/img/shippingProcess/iconsax-linear-arrowleft.png"}
                                        />
                                        <div className={"shipping-process-desktop-text-wrapper-2"}>Back</div>
                                    </div>
                                    <div className={"shipping-process-desktop-pay-shipping-button"}>
                                        <div className={"shipping-process-desktop-text-wrapper-3"}>confirm</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"shipping-process-desktop-dlivery-product-list"}>
                            <div className={"shipping-process-desktop-dlivery-product-list-2"}>
                                <div className={"shipping-process-desktop-dlivery-product-list-3"}>
                                    <div className={"shipping-process-desktop-dlivery-product-list-label"}>
                                        Items to be shipped {/* ({props.selectedItems.length} List) */}
                                    </div>
                                </div>
                                <img
                                    className={"shipping-process-desktop-line-18"}
                                    src={"/img/shippingProcess/line-18.png"}
                                />

                                <div className={"shipping-process-desktop-frame-176"}>
                                    <div className={"shipping-process-desktop-text-wrapper-11"}>shipping</div>
                                    <div className={"shipping-process-desktop-frame-2"}>
                                        <div className={"shipping-process-desktop-text-wrapper-12"}>0</div>
                                        <div className={"shipping-process-desktop-text-wrapper-10"}>USD</div>
                                    </div>
                                </div>
                                <div className={"shipping-process-desktop-frame-177"}>
                                    <div className={"shipping-process-desktop-text-wrapper-13"}>Total</div>
                                    <div className={"shipping-process-desktop-frame-174"}>
                                        <div className={"shipping-process-desktop-text-wrapper-12"}>0</div>
                                        <div className={"shipping-process-desktop-text-wrapper-10"}>USD</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterDesk></FooterDesk>
        </div>
    );
};
