import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fetchItemsInInventory, sellItemsInInventory } from "../../api/Inventory";
import { FooterDesk } from "../../components/Footer/Desktop/FooterDesk";
import { Navbar } from "../../components/Navbar/Desktop";
import { useUserContext } from "../../hooks/auth";
import { IAwardWinnerInventoryResponse, InventoryBoxProduct, ItemInTheBox_Unused } from "../../interfaces/ItemInTheBox";
import { Loading } from "../Loading";
import { ShippingProcessDesktop } from "./ShippingProcessDesktop";
import "./style.css";
import { Link } from "react-router-dom";
export interface IFetchInventoryResponse {
    awardWinner: IAwardWinnerInventoryResponse[];
    jigsawProducts: IInventoryItem[];
}
export interface IInventoryItem {
    id: number;
    name: string;
    productPrice: number;
    detail: string;
    imageUrl: string;
    jigsawImageUrl: string;
    jigsawPrice: number;
    jigsawSizeX: number;
    jigsawSizeY: number;
    have: number;
    total: number;
    isDeleted: boolean;
}

export const InventoryDesktop = (): JSX.Element => {
    const { stsTokenManager, reloadUserPoint } = useUserContext();
    const [itemsInBox, setItemsInBox] = useState<IInventoryItem[]>([]);
    const [awardItems, setAwardItems] = useState<IAwardWinnerInventoryResponse[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedItems, setSelectedItems] = useState<IAwardWinnerInventoryResponse[]>([]);
    const [showShipping, setShowShipping] = useState<boolean>(false);

    useEffect(() => {
        if (stsTokenManager?.accessToken !== undefined) {
            setIsLoading(true);
            fetchMyInventory();
        }
    }, [stsTokenManager?.accessToken]);

    const fetchMyInventory = () => {
        fetchItemsInInventory()
            .then((itemsInBox) => {
                console.log("fetchItemsInInventory", itemsInBox);
                setItemsInBox(itemsInBox.jigsawProducts);
                setAwardItems(itemsInBox.awardWinner);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => setIsLoading(false));
    };

    const handleShipInventory = (item: IAwardWinnerInventoryResponse) => {
        setSelectedItems([item]);
        setShowShipping(true);
    };
    const handleSellItemsInInventory = () => {
        if (selectedItems.length === 0) {
            toast("กรุณาเลือกไอเท็มที่ต้องการขาย", {
                type: "error",
            });
            return;
        }
        sellItemsInInventory(selectedItems.map((item) => item.id)).then(() => {
            setSelectedItems([]);
            fetchMyInventory();
            reloadUserPoint();
            toast("ขายไอเท็มสำเร็จ", {
                type: "success",
            });
        });
    };

    const filteredItems = itemsInBox.filter((item) => {
        const itemWithoutSpaces = item.name.replace(/\s/g, "").toLowerCase();
        const searchTermWithoutSpaces = searchTerm.replace(/\s/g, "").toLowerCase();
        return itemWithoutSpaces.includes(searchTermWithoutSpaces);
    });

    const onBackwardClick = () => {
        setShowShipping(false);
        fetchMyInventory();
    };

    const sumPrice = selectedItems.reduce((acc, item) => {
        return acc + item.price;
    }, 0);

    return (
        <div className={"inventory-desktop-inventory-desktop"}>
            {/* <img alt="" className={"inventory-desktop-BG"} src={"/img/inventory/bg.png"} /> */}
            <div className="">
                <Navbar />
                {!showShipping ? (
                    <div className={"inventory-desktop-content-section"}>
                        <div className={"inventory-desktop-your-inventory-title"}>
                            <div className={"inventory-desktop-left-line"}>
                                <div className={"inventory-desktop-div"}>
                                    <img alt="" className={"inventory-desktop-line"} src={"/img/inventory/line.png"} />
                                    <div className={"inventory-desktop-ellipse"} />
                                </div>
                            </div>
                            <div className={"inventory-desktop-label"}>Your Award Winner Inventory</div>
                            <div className={"inventory-desktop-right-line"}>
                                <div className={"inventory-desktop-div"}>
                                    <img alt="" className={"inventory-desktop-img"} src={"/img/inventory/line-1.png"} />
                                    <div className={"inventory-desktop-ellipse"} />
                                </div>
                            </div>
                        </div>
                        <div className={"inventory-desktop-inventory"}>
                            {/* 
                            <div className={"inventory-desktop-BG-blur"} /> */}
                            {/* <div className={"inventory-desktop-search-my-product"}>
                                <div className={"inventory-desktop-search-product"}>
                                    <div className={"inventory-desktop-search-input"}>
                                        <img
                                            className={"inventory-desktop-img-2"}
                                            src={"/img/inventory/search-icon.png"}
                                        />
                                        <input
                                            type={"text"}
                                            className={"inventory-desktop-text-wrapper-3"}
                                            placeholder="Search Item..."
                                            onChange={(event) => setSearchTerm(event.target.value)}
                                        ></input>
                                    </div>
                                </div>
                            </div> */}
                            <div className="inventory-box-flex">
                                <div
                                    className={
                                        "inventory-desktop-row grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2"
                                    }
                                >
                                    {isLoading ? (
                                        <Loading />
                                    ) : (
                                        awardItems.map((item) => (
                                            <div className="mx-2">
                                                <div
                                                    className={` cursor-pointer`}
                                                    onClick={() => {
                                                        handleShipInventory(item);
                                                    }}
                                                >
                                                    <div className={"itembox-desktop-item-product"}>
                                                        <div
                                                            className={"home-desk-overlap-group-2 home-desk-item-list"}
                                                        >
                                                            <div className={"home-desk-BG"} />
                                                            <img
                                                                alt=""
                                                                className={"itembox-desktop-product-image"}
                                                                style={{ scale: "0.8" }}
                                                                src={item.product.imageUrl}
                                                            />
                                                            <div
                                                                className={"itembox-desktop-product-name"}
                                                                style={{
                                                                    paddingTop: "10px",
                                                                    fontSize: "1.2rem",
                                                                    lineHeight: "1.5rem",
                                                                }}
                                                            >
                                                                {item.product.name}
                                                            </div>

                                                            <img
                                                                alt=""
                                                                className={"home-desk-price-BG"}
                                                                src={"/img/inventory/button.png"}
                                                            />
                                                            <div
                                                                className={"item-desktop-price-label"}
                                                                style={{
                                                                    transform: "translate(0,-27px)",
                                                                    lineHeight: "1.5rem",
                                                                    fontSize: "1.0rem",
                                                                }}
                                                            >
                                                                {"YOU WIN"}
                                                                <br></br>
                                                                ROUND {item.round.round_number}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                    {awardItems.length === 0 && (
                                        <>
                                            <div className={"inventory-desktop-no-product text-center	"}>
                                                No Award winner In Inventory
                                                <br></br>
                                                <br></br>
                                                <span> Go To </span>
                                                <Link to="/">
                                                    <span className="text-[#E5BB33]"> Random Jigsaws </span>
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                </div>{" "}
                            </div>
                            <div className={"inventory-desktop-your-inventory-title"}>
                                <div className={"inventory-desktop-left-line"}>
                                    <div className={"inventory-desktop-div"}>
                                        <img
                                            alt=""
                                            className={"inventory-desktop-line"}
                                            src={"/img/inventory/line.png"}
                                        />
                                        <div className={"inventory-desktop-ellipse"} />
                                    </div>
                                </div>
                                <div className={"inventory-desktop-label"}>Your Jigsaw Inventory</div>
                                <div className={"inventory-desktop-right-line"}>
                                    <div className={"inventory-desktop-div"}>
                                        <img
                                            alt=""
                                            className={"inventory-desktop-img"}
                                            src={"/img/inventory/line-1.png"}
                                        />
                                        <div className={"inventory-desktop-ellipse"} />
                                    </div>
                                </div>
                            </div>
                            <div className="inventory-box-flex">
                                <div
                                    className={
                                        "inventory-desktop-row grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2"
                                    }
                                >
                                    {isLoading ? (
                                        <Loading />
                                    ) : (
                                        filteredItems.map((item) => (
                                            <div className="mx-2">
                                                <div
                                                    className={` cursor-pointer`}
                                                    onClick={() => {
                                                        if (item.isDeleted) {
                                                            toast("ไอเท็มนี้ถูกลบแล้ว", {
                                                                type: "error",
                                                            });
                                                        } else {
                                                            window.location.href = `/box/${item.id}`;
                                                        }
                                                    }}
                                                >
                                                    <div className={"itembox-desktop-item-product"}>
                                                        <div
                                                            className={"home-desk-overlap-group-2 home-desk-item-list"}
                                                        >
                                                            <div className={"home-desk-BG"} />
                                                            <img
                                                                alt=""
                                                                className={"itembox-desktop-product-image"}
                                                                style={{ scale: "0.8" }}
                                                                src={item.imageUrl}
                                                            />
                                                            <div
                                                                className={"itembox-desktop-product-name"}
                                                                style={{
                                                                    paddingTop: "10px",
                                                                    fontSize: "1.2rem",
                                                                    lineHeight: "1.5rem",
                                                                }}
                                                            >
                                                                {item.name}
                                                            </div>
                                                            <img
                                                                alt=""
                                                                className={"home-desk-price-BG"}
                                                                src={"/img/inventory/button.png"}
                                                            />
                                                            <div
                                                                className={"item-desktop-price-label"}
                                                                style={{ transform: "translate(0,-12px)" }}
                                                            >
                                                                {"jigsaw " + item.have + "/" + item.total}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                    {filteredItems.length === 0 && (
                                        <>
                                            <div className={"inventory-desktop-no-product text-center	"}>
                                                No Item In Inventory
                                                <br></br>
                                                <br></br>
                                                <span> Go To </span>
                                                <Link to="/">
                                                    <span className="text-[#E5BB33]"> Random Jigsaws </span>
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                </div>{" "}
                            </div>
                        </div>
                    </div>
                ) : (
                    <ShippingProcessDesktop
                        selectedItems={selectedItems}
                        onBackwardClick={onBackwardClick}
                    ></ShippingProcessDesktop>
                )}
                <FooterDesk />
            </div>
        </div>
    );
};
