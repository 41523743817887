import httpClient from "../client/client";
import { AccountRoleEnum } from "../enums/account";

export type OpenBoxHistoryForAdmin = {
    boxName: string;
    boxPrice: number;
    boxProductBrand: string;
    boxProductName: string;
    id: number;
    netPrice: number;
    openBoxTimeAt: Date;
    ownerEmail: string;
};

export interface IAdminLiveDropHistory {
    id: number;
    jigsawPieceRemain: number;
    productName: string;
    jigsawPieceName: string;
    imageUrl: string;
    ownerEmail: string;
    createdAt: string;
    stack:string
}

export interface RoundWinnerHistory {
    account_win : number,
    account_win_username: string,
    created_at: string,
    jigsaw_product_id: number,
    name: string,
    round_id: number,
    round_number: number,
    updated_at: string
    win_datetime:string
    imageUrl:string
}

export const getOpenBoxHistoryForAdmin = async (): Promise<IAdminLiveDropHistory[]> => {
    return httpClient.get<IAdminLiveDropHistory[]>("/admin/live-drop").then((res) => {
        console.log("getOpenBoxHistoryForAdmin", res.data);
        return res.data;
    });
};

export const getRoundWinnerForAdmin = async (): Promise<RoundWinnerHistory[]> => {
    return httpClient.get<RoundWinnerHistory[]>("/admin/round-winner").then((res) => {
        return res.data;
    });
};

export type TopupSummaryForAdmin = {
    total: number;
    thisMonth: number;
    toDay: number;
};

export const getTopupSummaryForAdmin = async (): Promise<TopupSummaryForAdmin> => {
    return httpClient.get("/admin/topup-summary").then((res) => res.data);
};

export type TopupHistoryForAdmin = {
    id: number;
    accountEmail: string;
    topupDateAt: Date | null;
    type: string;
    status: string;
    amount: number;
    fee: number;
    netAmount: number;
    slipData: string;
    thirdPartRequestId: string;
    thirdPartRequestData: string;
    thirdPartResponseData: string | null;
    thirdPartWebHookData: string | null;
    remark: string | null;
};

export const getTopupHistoryForAdmin = async (): Promise<TopupHistoryForAdmin[]> => {
    return httpClient.get("/admin/topup-history").then((res) => res.data);
};

export type AccountForAdmin = {
    id: number;
    myInviteCodeId: number;
    parentInviteCodeId: number | null;
    firebaseUid: string;
    firebaseProviderId: "google.com" | "password";
    role: AccountRoleEnum;
    email: string;
    displayName: string;
    username: string;
    firstName: string;
    lastName: string;
    banned: Date | null;
    bannedReason: string | null;
    point: number;
    level: number;
    exp: number;
    createdAt: Date;
    updatedAt: Date;
};

export const getAccountsForAdmin = async (): Promise<AccountForAdmin[]> => {
    return httpClient.get("/admin/account").then((res) => res.data);
};

export const getAccountByAccountIdForAdmin = async (accountId: number): Promise<AccountForAdmin> => {
    return httpClient.get("/admin/account/" + accountId).then((res) => res.data);
};

export const updateAccountByAccountIdForAdmin = async (
    accountId: number,
    data: AccountForAdmin
): Promise<AccountForAdmin> => {
    return httpClient
        .put("/admin/account/" + accountId, {
            role: data.role,
            displayName: data.displayName,
            username: data.username,
            firstName: data.firstName,
            lastName: data.lastName,
            point: data.point,
            level: data.level,
            exp: data.exp,
        })
        .then((res) => res.data);
};

export type BoxForAdmin = {
    id: number;
    name: string;
    imageUrl: string;
    detail: string;
    jigsawImageUrl: string;
    productPrice: number;
    jigsawPrice: number; // ไม่ใช้
    jigsawSizeX: number;
    jigsawSizeY: number;
    marginBreakPoint: number;
    itemRemain: number;
    margin: number;
    isDeleted: boolean;
    createdAt: Date;
    updatedAt: Date;
    categoryId: number;
};

export const getBoxsForAdmin = async (): Promise<BoxForAdmin[]> => {
    return httpClient.get("/admin/jigsaw-product").then((res) => {
        console.log("getBoxsForAdmin", res.data);
        return res.data;
    });
};

export const createBoxsForAdmin = async (data: BoxForAdmin): Promise<BoxForAdmin> => {
    return httpClient.post("/admin/jigsaw-product", data).then((res) => res.data);
};

export const getBoxByBoxIdForAdmin = async (boxId: number): Promise<BoxForAdmin> => {
    return httpClient.get("/admin/jigsaw-product/" + boxId).then((res) => res.data);
};
export const removeBoxByBoxIdForAdmin = async (boxId: number): Promise<BoxForAdmin> => {
    return httpClient.delete("/admin/jigsaw-product/" + boxId).then((res) => res.data);
};
export const updateBoxByBoxIdForAdmin = async (boxId: number, data: BoxForAdmin): Promise<BoxForAdmin> => {
    return httpClient.put("/admin/jigsaw-product/" + boxId, data).then((res) => data);
};

export type BoxProductForAdmin = {
    id: number;
    boxId: number;
    name: string;
    brand: string;
    imageUrl: string;
    currentDropRate: number;
    defaultDropRate: number;
    grade: number;
    price: number;
    minMarginToDrop: number | null;
    maxDrop: number;
    dropCount: number;
    isDefaultBoxProduct: boolean;
    isDeleted: boolean;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    shippingFee: number;
};

export const getBoxProductByBoxIdForAdmin = async (boxId: number): Promise<BoxProductForAdmin[]> => {
    return httpClient.get(`/admin/jigsaw-product/${boxId}/inventory`).then((res) => res.data);
};

export const getBoxProductByProductIdForAdmin = async (productId: number): Promise<BoxProductForAdmin> => {
    return httpClient
        .get(`/admin/jigsaw-product/inventory/${productId}`)
        .then((res) => res.data)
        .catch((err) => {
            console.log("getBoxProductByProductIdForAdmin", err);
            return err;
        });
};

export const updateBoxProductByProductIdForAdmin = async (
    productId: number,
    data: BoxProductForAdmin
): Promise<BoxProductForAdmin> => {
    return httpClient
        .put(`/admin/jigsaw-product/product/${productId}`, {
            boxId: data.boxId,
            name: data.name,
            brand: data.brand,
            imageUrl: data.imageUrl,
            currentDropRate: data.currentDropRate,
            defaultDropRate: data.defaultDropRate,
            grade: data.grade,
            price: data.price,
            minMarginToDrop: data.minMarginToDrop,
            maxDrop: data.maxDrop,
            dropCount: data.dropCount,
            isDefaultBoxProduct: data.isDefaultBoxProduct,
            isDeleted: data.isDeleted,
            shippingFee: data.shippingFee,
        })
        .then((res) => res.data);
};

export type Category = {
    id?: number;
    name: string;
    order: number;
    isDeleted?: boolean;
};

export const getCategory = async (): Promise<Category[]> => {
    return httpClient.get<Category[]>("/jigsaw-product-category").then((res) => {
        console.log("getCategory", res.data);
        return res.data;
    });
};

export const updateCategory = async (categoryId: number, body: Category): Promise<Category[]> => {
    try {
        const response = await httpClient.put(`/jigsaw-product-category/${categoryId}`, body, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error("Error while making the PUT request:", error);
        throw error;
    }
};

export const postCategory = async (body: Category): Promise<Category> => {
    try {
        const response = await httpClient.post("/jigsaw-product-category", body, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error("Error while making the POST request:", error);
        throw error;
    }
};

export const deleteCategory = async (categoryId: number) => {
    return httpClient.delete(`/jigsaw-product-category/${categoryId}`).then((res) => {
        console.log("getCategory", res.data);
        return res.data;
    });
};
