// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage, ref } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "",
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "",
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID || "",
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET || "",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID || "",
    appId: process.env.REACT_APP_FIREBASE_APPID || "",
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID || "",
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = undefined;
// export const storage = getStorage(app);
// export const storageRef = ref(storage);
const app = undefined;
export default app;
