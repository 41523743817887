import httpClient from "../client/client";
import { JigsawProduct, IJigsawProduct, IJigsawProductCategory } from "../interfaces/Box";

export const fetchAllBox = async (): Promise<IJigsawProduct[]> => {
    return httpClient.get<IJigsawProduct[]>("/jigsaw-product/all").then((res) => res.data);
};

export const fetchAllCategory = async (): Promise<IJigsawProductCategory[]> => {
    return httpClient.get<IJigsawProductCategory[]>("/jigsaw-product-category").then((res) => res.data);
};
