import React from "react";
import "./style.css";
export const NotFound = () => {
    return (
        <div className="notfound-screen">
            <h1>404</h1>
            <p>Page not found</p>
        </div>
    );
};
