import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    fetchBoxData,
    fetchBoxDataInventory,
    fetchBoxDataInventoryByUsername,
    fetchLiveDrop,
    fetchRoundHistory,
} from "../../api/ItemBox";
import { FooterDesk } from "../../components/Footer/Desktop/FooterDesk";
import { Navbar } from "../../components/Navbar/Desktop";
import {
    IFetchBoxDataInventory,
    JigsawInventoryPiece,
    JigsawPiece,
    JigsawPieceGroupCount,
    JigsawProduct,
    Round,
} from "../../interfaces/Box";
import { ILiveDrop } from "../../interfaces/ItemInTheBox";
import { Loading } from "../Loading";
import { LiveDrop } from "./LiveDrop";
import { MainItem } from "./MainBox";
import "./style.css";
import JigsawBoard from "../../components/Jigsaw/Board";
import { useUserContext } from "../../hooks/auth";
import SearchModal from "./SearchModal";
import { AxiosError } from "axios";
import JigsawInventoryPieceCard from "./MainBox/JigsawInventoryPieceCard";
import { getjigsawPiecesImgFromName } from "../../hooks/useJigsaw";

export const ItemBoxDesktop = (): JSX.Element => {
    const [boxData, setBoxData] = useState<JigsawProduct>();
    const [round, setRound] = useState<Round>();
    const { id } = useParams();
    const [liveDropItems, setLiveDropItems] = useState<ILiveDrop[]>([]);
    const [userAwardWinnerItems, setUserAwardWinnerItems] = useState<Round[]>([]);
    const [itemsInBox, setItemsInBox] = useState<JigsawPiece[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [width, setWidth] = useState(window.innerWidth);
    const [isSpinningCompleted, setIsSpinningCompleted] = useState<boolean>(true);
    const breakpoint = 1335;
    const [inventory, setInventory] = useState<IFetchBoxDataInventory>();
    const [myJigsaw, setMyJigsaw] = useState<JigsawPieceGroupCount[]>([]);
    const [openSearchJigsawPopup, setOpenSearchJigsawPopup] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>();
    const [boxDataInventoryWithSearchStatusText, setBoxDataInventoryWithSearchStatusText] = useState<string>();
    const [boxDataInventoryWithSearch, setBoxDataInventoryWithSearch] = useState<JigsawPieceGroupCount[]>([]);
    const [unusedJigsaw, setUnusedJigsaw] = useState<JigsawInventoryPiece[]>([]);
    const [seeAllInventory, setSeeAllInventory] = useState<boolean>(false);
    const [jigsawPieces, setJigsawPieces] = useState<JigsawPiece[]>([]);
    const { user } = useUserContext();

    const getInventoryByUsername = async () => {
        fetchBoxDataInventoryByUsername(Number(id), searchValue)
            .then((res) => {
                console.log("SearchModal getInventoryByUsername", res);
                let jigsaws: JigsawPieceGroupCount[] = [];
                res.jigsawPieces.forEach((item) => {
                    let count = 0;
                    res.jigsawInventoryPieces.forEach((item2) => {
                        if (item.id == item2.jigsawPieceId) {
                            count++;
                        }
                    });
                    jigsaws.push({
                        ...item,
                        amount: count,
                    });
                });
                console.log("SearchModal fetchBoxDataInventoryByUsername myJigsaw", jigsaws);
                setBoxDataInventoryWithSearch(jigsaws);
                setBoxDataInventoryWithSearchStatusText("");
            })
            .catch((err: AxiosError) => {
                console.log(err);
                setBoxDataInventoryWithSearch([]);
                setBoxDataInventoryWithSearchStatusText(err.response?.statusText);
            })
            .finally(() => setIsLoading(false));
    };

    const getRoundHistory = async () => {
        fetchRoundHistory(Number(id))
            .then((res) => {
                let awardWinnerList: Round[] = [];
                res.Round.forEach((round) => {
                    if (!(round.win_datetime == null || round.account_win_username == null)) {
                        awardWinnerList.push(round);
                    }
                });
                console.log("awardWinnerList", awardWinnerList);
                setUserAwardWinnerItems(awardWinnerList);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => setIsLoading(false));
    };

    const getInventory = async () => {
        fetchBoxDataInventory(Number(id))
            .then(async (res) => {
                let _myJigsaw: JigsawPieceGroupCount[] = [];
                res.jigsawPieces = await Promise.all(
                    res.jigsawPieces.map(async (item) => {
                        return {
                            ...item,
                            img: await getjigsawPiecesImgFromName(
                                boxData!.jigsawImageUrl,
                                boxData!.jigsawSizeX,
                                boxData!.jigsawSizeY,
                                item.col,
                                item.row
                            ),
                        };
                    })
                );
                res.jigsawInventoryPieces = await Promise.all(
                    res.jigsawInventoryPieces.map(async (item) => {
                        return {
                            ...item,
                            name: res.jigsawPieces.find((item2) => item2.id == item.jigsawPieceId)!.name,
                            img: res.jigsawPieces.find((item2) => item2.id == item.jigsawPieceId)!.img,
                        };
                    })
                );
                setJigsawPieces(res.jigsawPieces);
                let _unusedJigsaw: JigsawInventoryPiece[] = [];
                res.jigsawPieces.forEach((_jigsawPiece) => {
                    let count = 0;
                    res.jigsawInventoryPieces.forEach((item2) => {
                        if (_jigsawPiece.id == item2.jigsawPieceId && item2.roundId == round!.round_id) {
                            count++;
                            if (count > 1) {
                                _unusedJigsaw.push(item2);
                            }
                        }
                        if (_jigsawPiece.id == item2.jigsawPieceId && item2.roundId != round!.round_id) {
                            _unusedJigsaw.push(item2);
                        }
                    });
                    _myJigsaw.push({
                        ..._jigsawPiece,
                        amount: count,
                    });
                });
                // sort by date
                _unusedJigsaw.sort((a, b) => {
                    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
                });
                setUnusedJigsaw(_unusedJigsaw);
                setInventory(res);
                setMyJigsaw(_myJigsaw);
                console.log("myJigsaw", _myJigsaw);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => setIsLoading(false));
    };
    useEffect(() => {
        if (user === null || user === undefined) return;
        if (round === null) return;
        if (myJigsaw.length > 0) return;
        if (boxData === null || boxData === undefined) return;
        getInventory();
    }, [, user, boxData, round]);

    const getBoxData = async () => {
        getRoundHistory();
        fetchBoxData(Number(id))
            .then((res) => {
                setBoxData(res.jigsawProduct);
                console.log(boxData);
                setItemsInBox(res.jigsawPieces);
                setRound(res.round);
            })
            .finally(() => setIsLoading(false));
    };
    const reloadData = async () => {
        getBoxData();
        getInventory();
    };
    useEffect(() => {
        setIsLoading(true);
        getBoxData();
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    useEffect(() => {
        if (boxData) {
            fetchLiveDropList();
        }
    }, [boxData, user]);
    const fetchLiveDropList = async () => {
        setTimeout(() => {
            fetchLiveDropList();
        }, 5000);
        if (!boxData) return;
        let res = await fetchLiveDrop(boxData.id);
        let _liveDrop: ILiveDrop[] = [];
        const promises = res.data.map(async (item) => {
            const imageUrl = await getjigsawPiecesImgFromName(
                boxData!.jigsawImageUrl,
                boxData!.jigsawSizeX,
                boxData!.jigsawSizeY,
                item.jigsawPiecesCol,
                item.jigsawPiecesRow
            );

            _liveDrop.push({
                brand: boxData!.name,
                grade: 1,
                id: item.id,
                imageUrl: imageUrl,
                name: item.jigsawPiecesName,
                openBoxTimeAt: new Date(item.createdAt),
                price: 0,
                ownerUsername: item.ownerUsername,
                shippingFee: 50,
            });
        });

        // Use Promise.all to wait for all promises to resolve
        Promise.all(promises)
            .then(() => {
                _liveDrop.sort((a, b) => {
                    return b.openBoxTimeAt.getTime() - a.openBoxTimeAt.getTime();
                });
                setLiveDropItems(_liveDrop);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const onSearch = useCallback((value: string) => {
        setSearchValue(value);
    }, []);

    useEffect(() => {
        if (openSearchJigsawPopup) {
            const timer = setTimeout(() => {
                getInventoryByUsername();
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [searchValue]);

    const onOpenSearchJigsawDialog = useCallback((username: string) => {
        setOpenSearchJigsawPopup(true);
        setSearchValue(username);
    }, []);

    const onClose = useCallback(() => {
        setOpenSearchJigsawPopup(false);
        setSearchValue("");
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    const LiveDropForSmallDevice = () => {
        return (
            <>
                <div className="flex flex-col justify-between w-full gap-4 px-8 pt-8 md:hidden mb-10">
                    <div className={"itembox-desktop-live-drop-heading mx-auto"}>
                        <div className={"itembox-desktop-live-icon"} />
                        <div className={"itembox-desktop-text-wrapper"}> LIVE DROP</div>
                    </div>

                    <div id="live-drop-popup-right" className="grid grid-cols-1 gap-4  mx-10 sm:mx-28">
                        {liveDropItems.map((item) => (
                            <LiveDrop
                                key={item.id}
                                itemName={"JIGSAW " + item.name}
                                itemImage={item.imageUrl}
                                itemBrand={item.brand}
                                itemDescription={item.ownerUsername || ""}
                                date={item.openBoxTimeAt}
                                onOpenDialog={() => onOpenSearchJigsawDialog(item.ownerUsername || "")}
                            />
                        ))}
                    </div>
                </div>
                <div className="flex flex-col justify-between w-full gap-4 px-8 pt-8 md:hidden mb-10">
                    <div className={"itembox-desktop-live-drop-heading mx-auto"}>
                        <div className={"itembox-desktop-live-icon"} />
                        <div className={"itembox-desktop-text-wrapper"}>User Award winner</div>
                    </div>

                    <div id="live-drop-popup-right" className="grid grid-cols-1 gap-4   mx-10  sm:mx-28">
                        {userAwardWinnerItems.slice(0, 5).map((item) => (
                            <LiveDrop
                                key={item.round_id}
                                itemName={"Winner Round " + item.round_number.toString()}
                                itemImage={boxData?.imageUrl || ""}
                                itemBrand={""}
                                itemDescription={item.account_win_username || ""}
                                date={new Date(item.win_datetime || new Date())}
                                onOpenDialog={() => onOpenSearchJigsawDialog(item.account_win_username || "")}
                            />
                        ))}
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className={"itembox-desktop-itembox-desktop"}>
            <Navbar />
            <div className={"itembox-desktop-header-section"}>
                <img className={"itembox-desktop-header-BG"} src={"/img/shipping/bg.png"} alt="" />

                <div className={"itembox-desktop-div justify-center  md:justify-between pt-10"}>
                    {isSpinningCompleted && (
                        <div
                            id="live-drop-popup-left"
                            className={"itembox-desktop-live-drop-desktop hidden md:flex w-3/12 pl-0 md:pl-4 xl:pl-24 "}
                        >
                            <div className="grid grid-cols-1 gap-2 ">
                                <div className={"flex justify-center"}>
                                    <div className={"itembox-desktop-live-drop-heading"}>
                                        <div className={"itembox-desktop-live-icon"} />
                                        <div className={"itembox-desktop-text-wrapper"}>Jigsaw LIVE DROP</div>
                                    </div>
                                </div>
                                {liveDropItems.slice(0, 7).map((item) => (
                                    <LiveDrop
                                        key={item.id}
                                        itemName={"JIGSAW " + item.name}
                                        itemImage={item.imageUrl}
                                        itemBrand={item.brand}
                                        itemDescription={item.ownerUsername || ""}
                                        date={item.openBoxTimeAt}
                                        onOpenDialog={() => onOpenSearchJigsawDialog(item.ownerUsername || "")}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    {boxData && (
                        <div>
                            <MainItem
                                unusedJigsawPiece={unusedJigsaw}
                                boxData={boxData}
                                round={round}
                                ItemsInTheBox={itemsInBox}
                                myJigsaw={myJigsaw}
                                onSpinningCompleted={(isCompleted) => {
                                    console.log("setShowResultItem", isCompleted);
                                    if (isCompleted) {
                                        reloadData();
                                    }
                                    setIsSpinningCompleted(isCompleted);
                                }}
                            />
                        </div>
                    )}
                    {isSpinningCompleted && (
                        <div
                            id="live-drop-popup-right"
                            className={"itembox-desktop-live-drop-desktop hidden md:flex w-3/12 pr-0 md:pr-4 xl:pr-24"}
                        >
                            <div className="grid grid-cols-1 gap-2 ">
                                <div className={"flex justify-center"}>
                                    <div className={"itembox-desktop-live-drop-heading"}>
                                        <div className={"itembox-desktop-live-icon"} />
                                        <div className={"itembox-desktop-text-wrapper"}>User Award winner </div>
                                    </div>{" "}
                                </div>
                                {userAwardWinnerItems.slice(0, 5).map((item) => (
                                    <LiveDrop
                                        key={item.round_id}
                                        itemName={"Winner Round " + item.round_number.toString()}
                                        itemImage={boxData?.imageUrl || ""}
                                        itemBrand={""}
                                        itemDescription={item.account_win_username || ""}
                                        date={new Date(item.win_datetime || new Date())}
                                        onOpenDialog={() => onOpenSearchJigsawDialog(item.account_win_username || "")}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <LiveDropForSmallDevice />
            <>
                <div className="grid grid-cols-1">
                    <div className="grid grid-cols-1 mx-5 mt-4 md:mx-10 xl:mx-28">
                        <div className={" "}>
                            <div className="grid grid-cols-1 gap-10 ">
                                <div className={" flex flex-wrap justify-center items-center"}>
                                    <div className="grid grid-cols-1 ">
                                        <div className={"itembox-desktop-text-wrapper-2 item-name-title "}>
                                            {boxData?.name}
                                        </div>
                                        <div className=" text-xl font-light  text-white	text-center mb-2">
                                            {boxData?.detail}
                                        </div>
                                        {boxData && (
                                            <JigsawBoard
                                                x={boxData?.jigsawSizeX}
                                                y={boxData?.jigsawSizeY}
                                                src={boxData?.jigsawImageUrl}
                                                data={myJigsaw}
                                            />
                                        )}
                                    </div>
                                </div>
                                {user && boxData && (
                                    <div className="flex flex-col ">
                                        <div className="inventory-item-name-title text-center mb-7">
                                            UNUSED Jigsaw {unusedJigsaw.length} pieces
                                        </div>
                                        <div className="">
                                            {seeAllInventory ? (
                                                <div className="flex justify-center items-center flex-wrap gap-8">
                                                    {unusedJigsaw?.map((item) => (
                                                        <JigsawInventoryPieceCard
                                                            key={item.id}
                                                            name={item.name || "unknown"}
                                                            round={item.roundNumber}
                                                            image={item.img || ""}
                                                        ></JigsawInventoryPieceCard>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className="flex justify-center items-center flex-wrap gap-8 ">
                                                        {unusedJigsaw?.slice(0, 6).map((item) => (
                                                            <JigsawInventoryPieceCard
                                                                key={item.id}
                                                                name={item.name || "unknown"}
                                                                round={item.roundNumber}
                                                                image={item.img || ""}
                                                            ></JigsawInventoryPieceCard>
                                                        ))}
                                                    </div>
                                                    {unusedJigsaw.length > 6 && (
                                                        <div
                                                            className=" mt-2 cursor-pointer uppercase text-lg items-center justify-center py-4 text-base font-bold text-[#040403] transition duration-300 ease-in-out rounded-[25px] px-9 hover:shadow-signUp bg-signin "
                                                            onClick={() => setSeeAllInventory(true)}
                                                        >
                                                            <div className="text-center">SEE ALL</div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
            {openSearchJigsawPopup && (
                <>
                    <div className="fixed top-0 left-0 w-full h-full bg-transparent z-10" onClick={onClose} />
                    <SearchModal
                        onSearch={onSearch}
                        value={searchValue}
                        myJigsaw={boxDataInventoryWithSearch}
                        boxData={boxData}
                        searchStatus={boxDataInventoryWithSearchStatusText}
                    />
                </>
            )}

            <FooterDesk style={{ marginTop: "160px" }}></FooterDesk>
        </div>
    );
};
