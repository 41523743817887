import { promises } from "dns";
import httpClient from "../client/client";
import { getjigsawPiecesImgFromName } from "../hooks/useJigsaw";
import {
    IFetchBoxData,
    IFetchBoxDataInventory,
    IFetchRoundHistory,
    IJigsawHistory,
    JigsawProduct,
    OpenBoxResponse,
} from "../interfaces/Box";
import {
    IFetchJigsawPieceLiveDropResponse,
    IJigsawPieceLiveDrop,
    ILiveDrop,
    LiveDropResponse,
} from "../interfaces/ItemInTheBox";
import number from "../helpers/number-format";

export const fetchRandomJigsawHistories = async (jigsawProductId: number): Promise<IJigsawHistory[]> => {
    return httpClient.get(`/random-historyJigsaw/${jigsawProductId}`).then((res) => {
        console.log("get random-historyJigsaw", res.data);
        return res.data.jigsawHistory as IJigsawHistory[];
    });
};
export const fetchBoxData = async (boxId: number): Promise<IFetchBoxData> => {
    return httpClient.get<IFetchBoxData>(`/jigsaw-product/${boxId}`).then((res) => {
        console.log("get jigsaw-product", res.data);
        return res.data;
    });
};
export const fetchBoxDataInventory = async (boxId: number): Promise<IFetchBoxDataInventory> => {
    return httpClient.get<IFetchBoxDataInventory>(`/jigsaw-product/inventory/${boxId}`).then((res) => {
        console.log("get jigsaw-product inventory", res.data);
        return res.data;
    });
};
export const fetchBoxDataInventoryByUsername = async (boxId: number, username?: string) => {
    return httpClient.get<IFetchBoxDataInventory>(`/jigsaw-product/inventory/${username}/${boxId}`).then((res) => {
        console.log("get jigsaw-product inventory", res.data);
        return res.data;
    });
};
export const fetchRoundHistory = async (boxId: number) => {
    return httpClient.get<IFetchRoundHistory>(`/jigsaw-product/round/all/product/${boxId}`).then((res) => {
        console.log("get fetchRound", res.data);
        return res.data;
    });
};
export const openBoxAPI = async (
    boxId: number,
    roundId: number,
    InventoryJigsawPieceId?: number[]
): Promise<OpenBoxResponse> => {
    if (InventoryJigsawPieceId) {
        return httpClient
            .post<OpenBoxResponse>(`/random-burnJigsaw/${boxId}`, {
                roundId,
                InventoryJigsawPieceId,
            })
            .then((res) => {
                return res.data;
            });
    } else {
        return httpClient.post<OpenBoxResponse>(`/random-jigsaw/${boxId}`, { roundId: roundId }).then((res) => {
            return res.data;
        });
    }
};

export const mockFetchLiveDrop = async (
    boxData: JigsawProduct,
    jigsawSizeX: number,
    jigsawSizeY: number
): Promise<ILiveDrop[]> => {
    let mockColRow = [
        [
            Math.floor(Math.random() * (jigsawSizeX - 1 < 0 ? 1 : jigsawSizeX - 1)),
            Math.floor(Math.random() * (jigsawSizeY - 1 < 0 ? 1 : jigsawSizeY - 1)),
            "A0",
            new Date().getTime() - 1 * 60 * 60 * 24,
            Math.floor(Math.random() * 4),
        ],
        [
            Math.floor(Math.random() * (jigsawSizeX - 1 < 0 ? 1 : jigsawSizeX - 1)),
            Math.floor(Math.random() * (jigsawSizeY - 1 < 0 ? 1 : jigsawSizeY - 1)),
            "A1",
            new Date().getTime() - 3 * 60 * 60 * 24,
            Math.floor(Math.random() * 4),
        ],
        [
            Math.floor(Math.random() * (jigsawSizeX - 1 < 0 ? 1 : jigsawSizeX - 1)),
            Math.floor(Math.random() * (jigsawSizeY - 1 < 0 ? 1 : jigsawSizeY - 1)),
            "A2",
            new Date().getTime() - 4 * 60 * 60 * 24,
            Math.floor(Math.random() * 4),
        ],
        [
            Math.floor(Math.random() * (jigsawSizeX - 1 < 0 ? 1 : jigsawSizeX - 1)),
            Math.floor(Math.random() * (jigsawSizeY - 1 < 0 ? 1 : jigsawSizeY - 1)),
            "A3",
            new Date().getTime() - 5 * 60 * 60 * 24,
            Math.floor(Math.random() * 4),
        ],
    ];
    const promises: Promise<ILiveDrop>[] = mockColRow.map(async (item, index) => {
        let col = item[0];
        let row = item[1];
        let jigsawPieceImageUrl = await getjigsawPiecesImgFromName(
            boxData.imageUrl,
            jigsawSizeX,
            jigsawSizeY,
            Number(col),
            Number(row)
        );

        return {
            brand: boxData.name,
            grade: item[4],
            id: index,
            imageUrl: jigsawPieceImageUrl,
            name: item[2],
            openBoxTimeAt: new Date(item[3]),
            price: Math.floor(Math.random() * 10) + 1,
            shippingFee: 50,
        } as ILiveDrop;
    });

    const mockData: ILiveDrop[] = await Promise.all(promises);
    return mockData;
};
export const fetchLiveDrop = async (boxId: number): Promise<IFetchJigsawPieceLiveDropResponse> => {
    return httpClient
        .get<IFetchJigsawPieceLiveDropResponse>(
            `/Inventory/Jigsawlive-drop/${boxId}?jigsawProductID=${boxId}&limit=${4}&page=${1}`
        )
        .then((res) => {
            return res.data as IFetchJigsawPieceLiveDropResponse;
        });
};
export const mockFetchUserAwardWinner = async (boxData: JigsawProduct): Promise<ILiveDrop[]> => {
    return [
        {
            brand: boxData.name,
            grade: 2,
            id: 1,
            imageUrl: boxData.jigsawImageUrl,
            name: boxData.name,
            openBoxTimeAt: new Date(),
            price: boxData.productPrice,
            shippingFee: 50,
        },
        {
            brand: boxData.name,
            grade: 2,
            id: 2,
            imageUrl: boxData.jigsawImageUrl,
            name: boxData.name,
            openBoxTimeAt: new Date(),
            price: boxData.productPrice,
            shippingFee: 50,
        },
    ];
};
export const fetchUserAwardWinner = async (boxId: number): Promise<ILiveDrop[]> => {
    return httpClient.get<LiveDropResponse[]>(`/jigsaw-product/live-drop/${boxId}`).then((res) =>
        res.data.map((liveDrop) => {
            return {
                ...liveDrop.product,
                id: liveDrop.id,
                openBoxTimeAt: liveDrop.openBoxTimeAt,
            };
        })
    );
};
