import { useEffect } from "react";
import number from "../../../../helpers/number-format";
import { JigsawProduct, Round } from "../../../../interfaces/Box";
import { ItemInTheBox_Unused } from "../../../../interfaces/ItemInTheBox";
import "./style.css";

interface ItemResultProps {
    boxInfo: JigsawProduct;
    itemResult: ItemInTheBox_Unused[];
    round: Round;
    handleSellClick: () => void;
    onReOpenBoxClick: () => void;
}

export const ItemResultDesktop = ({
    boxInfo,
    itemResult,
    handleSellClick,
    onReOpenBoxClick,
    round,
}: ItemResultProps): JSX.Element => {
    useEffect(() => {
        console.log("round", round);
    }, [round]);
    const renderTextBrand = (grade: number, brand?: string) => {
        switch (grade) {
            case 0:
                return <div className={"itemresult-desktop-product-info text-[#D6D7D5]"}> {brand ?? "no brand"} </div>;
            case 1:
                return <div className={"itemresult-desktop-product-info text-[#5168F7]"}> {brand ?? "no brand"} </div>;
            case 2:
                return <div className={"itemresult-desktop-product-info text-[#7F4AF7]"}> {brand ?? "no brand"} </div>;
            case 3:
                return <div className={"itemresult-desktop-product-info text-[#C23EDE]"}> {brand ?? "no brand"} </div>;
            case 4:
                return <div className={"itemresult-desktop-product-info text-[#D95752]"}> {brand ?? "no brand"} </div>;
            case 5:
                return <div className={"itemresult-desktop-product-info text-[#ecc834]"}> {brand ?? "no brand"} </div>;
            default:
                return <div className={"itemresult-desktop-product-info text-[#000000]"}> {brand ?? "no brand"} </div>;
        }
    };

    const renderGradeBoxShadowAndLine = (grade: number) => {
        switch (grade) {
            case 0:
                return (
                    <div className={"itemresult-desktop-BG-group-grade-0"}>
                        <div className={"itemresult-desktop-overlap-group"}>
                            <div className={"itemresult-desktop-line bg-[#ecc834]"} />
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div className={"itemresult-desktop-BG-group-grade-1"}>
                        <div className={"itemresult-desktop-overlap-group"}>
                            <div className={"itemresult-desktop-line bg-[#ecc834]"} />
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className={"itemresult-desktop-BG-group-grade-2"}>
                        <div className={"itemresult-desktop-overlap-group"}>
                            <div className={"itemresult-desktop-line bg-[#ecc834]"} />
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className={"itemresult-desktop-BG-group-grade-3"}>
                        <div className={"itemresult-desktop-overlap-group"}>
                            <div className={"itemresult-desktop-line bg-[#ecc834]"} />
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div className={"itemresult-desktop-BG-group-grade-4"}>
                        <div className={"itemresult-desktop-overlap-group"}>
                            <div className={"itemresult-desktop-line bg-[#ecc834]"} />
                        </div>
                    </div>
                );
            case 5:
                return (
                    <div className={"itemresult-desktop-BG-group-grade-5"}>
                        <div className={"itemresult-desktop-overlap-group"}>
                            <div className={"itemresult-desktop-line bg-[#ecc834]"} />
                        </div>
                    </div>
                );
            default:
                return (
                    <div className={"itemresult-desktop-BG-group-grade-x"}>
                        <div className={"itemresult-desktop-overlap-group"}>
                            <div className={"itemresult-desktop-line bg-[#ecc834]"} />
                        </div>
                    </div>
                );
        }
    };

    return (
        <div className={"itemresult-desktop-product-result relative px-8"}>
            <div className={"itemresult-desktop-product-get"}>
                <div className={"itemresult-desktop-box-title flex-col justify-center items-center md:flex-row"}>
                    <img className={"itemresult-desktop-box-img mx-auto"} src={boxInfo.imageUrl} />
                    <div>
                        <h1 className={"itemresult-desktop-name-box text-center"}>{boxInfo.name}</h1>
                        <p className={"itemresult-desktop-name-box-detail text-center"}>Round {round.round_number}</p>
                    </div>
                </div>
                {itemResult.length > 0 ? (
                    itemResult.map((item) => {
                        return (
                            <div key={item.id} className={"itemresult-desktop-product"}>
                                <div className={"itemresult-desktop-overlap"}>
                                    {renderGradeBoxShadowAndLine(item.grade)}
                                    <div className={"itemresult-desktop-product-detail"}>
                                        <div className={"itemresult-desktop-frame"}>
                                            <div className={"itemresult-desktop-price-label"}>
                                                <div className={"itemresult-desktop-div"}>
                                                    <div className={"itemresult-desktop-price-BG"} />
                                                    <div className={"itemresult-desktop-text-wrapper"}>Jigsaw</div>
                                                </div>
                                            </div>
                                            <img className={"itemresult-desktop-product-image"} src={item.imageUrl} />
                                            <div className={"itemresult-desktop-product-title"}>{item.name}</div>
                                            {renderTextBrand(item.grade, item.brand)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className={"itemresult-desktop-product"}>
                        <div className="text-center text-white">ไม่มีรายการสินค้า</div>
                    </div>
                )}
                <div className={"itemresult-desktop-button-group flex flex-col md:flex-row md:items-start"}>
                    <div className={"itemresult-desktop-open-again-button"}>
                        <div
                            className={"itemresult-desktop-open-again-button-label px-8 w-full"}
                            onClick={onReOpenBoxClick}
                        >
                            REOPEN
                        </div>
                        <div className={"itemresult-desktop-open-again-description"}>
                            Jigsaw will keep in the inventory
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
