import React, { useEffect, useState } from "react";
import { JigsawPieceBoard } from "../components/Jigsaw/Board";

export interface Inventory {
    row: number;
    col: number;
    amount: number;
}
export const getjigsawPiecesImgFromName = (
    jigsawImageUrl: string,
    jigsawSizeX: number,
    jigsawSizeY: number,
    col: number,
    row: number
): Promise<string> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "anonymous"; // to handle CORS issues
        img.onload = function () {
            // Create a temporary canvas to draw the jigsaw image
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            canvas.width = img.width;
            canvas.height = img.height;
            ctx!.drawImage(img, 0, 0);

            // Calculate the dimensions and position of the piece
            const pieceWidth = img.width / jigsawSizeX;
            const pieceHeight = img.height / jigsawSizeY;
            const startX = col * pieceWidth;
            const startY = row * pieceHeight;

            // Extract the piece
            const pieceImageData = ctx!.getImageData(startX, startY, pieceWidth, pieceHeight);
            const pieceCanvas = document.createElement("canvas");
            const pieceCtx = pieceCanvas.getContext("2d");

            pieceCanvas.width = pieceWidth;
            pieceCanvas.height = pieceHeight;
            pieceCtx!.putImageData(pieceImageData, 0, 0);

            // Return the piece as a data URL
            resolve(pieceCanvas.toDataURL());
        };
        img.onerror = reject;
        img.src = jigsawImageUrl;
    });
};

export default function useJigsaw(x = 10, y = 4, src: string, inventory: Inventory[] = []) {
    const [piecesCount, setPiecesCount] = useState([0, 0]);
    const [jigsawPieces, setJigsawPieces] = useState([] as JigsawPieceBoard[][]);
    const [sumWidth, setSumWidth] = useState(0);
    const [sumHeight, setSumHeight] = useState(0);
    const handleImage = () => {
        const piecesX = x;
        const piecesY = y;
        setPiecesCount([piecesX, piecesY]);
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = function () {
            // let ratio = (img.height / img.width) * 1.0;
            // console.log("ratio", window.innerWidth);
            // img.height = ratio * window.innerWidth * 0.8;
            // img.width = window.innerWidth * 0.8;
            // console.log("img", img.width);

            const pieces = divideImageIntoPieces(img, y, x,inventory);
            setJigsawPieces(pieces);
        };
        img.src = src + '?t=' + new Date().getTime();
    };
    const reloadImage = () => {
        handleImage();
    };
    const divideImageIntoPieces = (image, rows, columns,inventory) => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const pieceWidth = image.width / columns;
        const pieceHeight = image.height / rows;
        const pieces: JigsawPieceBoard[][] = [];
        setSumWidth(pieceWidth * columns);
        setSumHeight(pieceHeight * rows);
        const inventSet = {};

        for (const item of inventory) {
            const letter = String.fromCharCode(65 + item.row);
            inventSet[`${letter}${item.col + 1}`] = item.amount;
        }

        for (let row = 0; row < rows; row++) {
            const letter = String.fromCharCode(65 + row);
            const itemPiece: JigsawPieceBoard[] = [];
            for (let col = 0; col < columns; col++) {
                canvas.width = pieceWidth;
                canvas.height = pieceHeight;
                const name = `${letter}${col + 1}`;

                if (context) {
                    context.drawImage(
                        image,
                        col * pieceWidth,
                        row * pieceHeight,
                        pieceWidth,
                        pieceHeight,
                        0,
                        0,
                        pieceWidth,
                        pieceHeight
                    );

                    // Create an image data URL for the piece
                    const pieceDataUrl = canvas.toDataURL();
                    // Create an object representing the piece with its data URL
                    itemPiece.push({
                        x: col * pieceWidth,
                        y: row * pieceHeight,
                        dataUrl: pieceDataUrl,
                        name: name,
                        width: pieceWidth,
                        height: pieceHeight,
                        isReceived: inventSet[name] > 0,
                        num: inventSet[name],
                    });
                }
            }
            pieces.push(itemPiece);
        }

        return pieces;
    };

    // Use the useEffect hook to mimic componentDidMount
    useEffect(() => {
        handleImage();
    }, []);

    return { sumHeight, sumWidth, piecesCount, jigsawPieces, reloadImage };
}
