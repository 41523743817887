import { useEffect, useState } from "react";
import { fetchAllBox, fetchAllCategory } from "../../../api/Home";
import { FooterDesk } from "../../../components/Footer/Desktop/FooterDesk";
import { Navbar } from "../../../components/Navbar/Desktop";
import { IJigsawProductCategory, IJigsawProduct } from "../../../interfaces/Box";
import { Loading } from "../../Loading";
import { ItemBox } from "./ItemBox";
import "./style.css";
import Slider from "slider-moon";
import "slider-moon/dist/style.css";

export const HomeDesk = (): JSX.Element => {
    const [itemList, setItemList] = useState<IJigsawProduct[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [categoryIndex, setCategoryIndex] = useState<number>(0);
    const [clickedCategory, setClickedCategory] = useState<boolean>(false);
    const [category, setCategory] = useState<IJigsawProductCategory[]>([]);
    useEffect(() => {
        setLoading(true);
        fetchAllCategory()
            .then(res => {
                setCategory([
                    {
                        id: 0,
                        name: "all prizes",
                        order: 0,
                        isDeleted: false,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                        deletedAt: new Date()
                    },
                    ...res
                ]);
                fetchAllBox()
                    .then(res => {
                        //  check is array?
                        if (Array.isArray(res)) {
                            setItemList(res);
                        } else {
                            console.error("Error: res is not array", res);
                            setItemList([]);
                        }
                    })
                    .finally(() => setLoading(false));
            })
            .catch(err => {
                console.error(err);
            });

        let categoryChecker = setInterval(() => {
            let sliders = document.getElementsByClassName("slider-bullets-item");
            if (sliders) {
                // @ts-ignore
                Array.from(sliders).forEach((slider, index) => {
                    if (slider.classList.contains("active")) {
                        setCategoryIndex(index);
                    }
                });
            }
        }, 100);
        return () => {
            clearInterval(categoryChecker);
        };
    }, []);
    // Set up a useEffect to run whenever clickedCategory changes
    useEffect(() => {
        // Check the value of clickedCategory and trigger the click if it's false
        if (itemList.length > 0 && !clickedCategory) {
            const intervalId = setInterval(() => {
                var nextButton = document.getElementsByClassName("slider-nav-next")[0];
                if (nextButton == undefined) return;
                // Perform the click action here
                (nextButton as HTMLElement).click();
            }, 5000);
            // Cleanup the interval when the component unmounts or when clickedCategory becomes true
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [clickedCategory, itemList]);
    useEffect(() => {}, [itemList]);
    const scrollToHowToPlay = () => {
        const element = document.getElementById("how-to-play");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };
    const scrollToBoxList = () => {
        const element = document.getElementById("box-list");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };
    if (loading) return <Loading />;
    function onCategoryMenuClicked(index: number): void {
        let menu = document.getElementsByClassName("slider-bullets-item");
        if (menu && index < menu.length) {
            (menu[index] as HTMLElement).click();
            setClickedCategory(true);
        }
    }

    return (
        <div className={"home-desk-home-desk"}>
            <Navbar />
            <div className={"home-desk-hero-section"}>
                <div className={"home-desk-hero-content"}>
                    <h1 className={"home-desk-h-1"}>
                        <img alt="" src={"/img/home/jigsaw-header.png"} />
                        {/* <span className={"home-desk-text-wrapper-3"}>
                                Random Jigsaw
                                <br />
                            </span>
                        <span className={"home-desk-span"}>ได้แล้ววันนี้</span> */}
                    </h1>
                    <div className={"home-desk-sub-hero-content"}>
                        <div className={"home-desk-frame"}>
                            <p className={"home-desk-supreme-off-white-BAPE-apple"}>
                                Randomly jigsaw puzzles to win luxury prizes for Apple ,Car, Brand name and more here.
                            </p>
                            <div className={"home-desk-group-button flex flex-wrap"}>
                                <div className={"home-desk-random-button"}>
                                    <div className={"home-desk-overlap-group"} onClick={() => scrollToBoxList()}>
                                        <img className={"pr-2"} src={"/img/home/jigsaw.png"} />
                                        Random Now
                                    </div>
                                </div>
                                <div className={"home-desk-overlap-wrapper"}>
                                    <div className={"home-desk-overlap"}>
                                        <img
                                            alt=""
                                            className={"pr-2 w-9"}
                                            src={"/img/ItemBoxDesktop/iconsax-linear-infocircle-5-hover.png"}
                                        />
                                        How It Work
                                        {/* <div
                      className={"home-desk-random-label-2"}
                      onClick={() => scrollToHowToPlay()}
                    >
                      How It Work
                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={"home-desk-content-section"}
                style={
                    {
                        /* backgroundImage: "url(/img/home/bg-1.png)", */
                        /*   background:
                        "linear-gradient(167deg , rgba(36,31,22,1) 35%, rgba(30,26,19,1) 65%, rgba(0,0,0,1) 100%)", */
                    }
                }
            >
                <div className={"home-desk-content-flex"}>
                    <section id="how-to-play">
                        <div className={"home-desk-how-to-work-label"}>
                            <div className={"home-desk-group"}>
                                <div className={"home-desk-div-2"}>
                                    <img alt="" className={"home-desk-line"} src={"/img/home/line-12@2x.png"} />
                                    <div className={"home-desk-ellipse"} />
                                </div>
                            </div>
                            <div className={"home-desk-how-it-work "}>
                                <div className="md:text-4xl">HOW IT WORK</div>
                            </div>
                            <div className={"home-desk-group-100"}>
                                <div className={"home-desk-div-2"}>
                                    <img alt="" className={"home-desk-line-12"} src={"/img/home/line-12@2x.png"} />
                                    <div className={"home-desk-ellipse"} />
                                </div>
                            </div>
                        </div>
                        <img alt="" className={"home-desk-how-to-work-image"} src={"/img/home/howtoworkimage.png"} />
                    </section>
                    <div className="grid grid-cols-1 gap-2">
                        <section id="box-list">
                            <div className="flex justify-center mb-5">
                                <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
                                    {category.map((item, index) => (
                                        <div
                                            className={`uppercase text-white cursor-pointer mr-3 hover:text-orange-300 home-category-button ${
                                                categoryIndex == index ? "active" : ""
                                            }`}
                                            onClick={() => onCategoryMenuClicked(index)}
                                        >
                                            <img alt="" src={`./img/home/category/${item.name}.svg`} />
                                            <div>{item.name}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="item-box-flex">
                                <div className={"home-desk-how-to-work-label"}>
                                    <div className={"home-desk-group"}>
                                        <div className={"home-desk-div-2"}>
                                            <img alt="" className={"home-desk-line"} src={"/img/home/line-12@2x.png"} />
                                            <div className={"home-desk-ellipse"} />
                                        </div>
                                    </div>
                                    <div className={"home-desk-how-it-work"}>
                                        <div className="md:text-4xl uppercase">
                                            {category.filter(item => item.id == categoryIndex)[0].name}
                                        </div>
                                    </div>
                                    <div className={"home-desk-group-100"}>
                                        <div className={"home-desk-div-2"}>
                                            <img
                                                alt=""
                                                className={"home-desk-line-12"}
                                                src={"/img/home/line-12@2x.png"}
                                            />
                                            <div className={"home-desk-ellipse"} />
                                        </div>
                                    </div>
                                </div>
                                <Slider
                                    slideClass={"my-slider"}
                                    infinite={true}
                                    bullets={true}
                                    arrowsNav={true}
                                    animation={"scale"}
                                    callback={() => {
                                        console.log("here");
                                    }}
                                >
                                    <div>
                                        <div className="slider my-slider">
                                            <ul className="slider-wrapper" style={{ display: "flex", height: "100%" }}>
                                                {category.map((category, key) => {
                                                    return (
                                                        <li
                                                            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:ml-20"
                                                            style={{ height: "100%" }}
                                                            key="1"
                                                        >
                                                            {itemList.map((item, key) => {
                                                                if (
                                                                    item.categoryId == category.id ||
                                                                    category.id == 0
                                                                ) {
                                                                    return (
                                                                        <div className="home-desk-image-slide">
                                                                            <ItemBox
                                                                                key={item.id}
                                                                                imageUrl={item.imageUrl}
                                                                                name={item.name}
                                                                                id={item.id}
                                                                                price={item.productPrice}
                                                                                description={item.detail}
                                                                            />{" "}
                                                                        </div>
                                                                    );
                                                                }
                                                            })}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <FooterDesk />
        </div>
    );
};
