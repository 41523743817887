import { useEffect, useState } from "react";
import { fetchAllShipping } from "../../api/shiping";
import { FooterDesk } from "../../components/Footer/Desktop/FooterDesk";
import { Navbar } from "../../components/Navbar/Desktop";
import { ShippingDetail } from "../../interfaces/Shipping";
import { Loading } from "../Loading";
import { ShippingList } from "./ShippingList/ShippingList";
import "./style.css";
import { useUserContext } from "../../hooks/auth";

export const ShippingSuccessDesktop = () => {
    const { stsTokenManager } = useUserContext();
    const [shippingList, setShippingList] = useState<ShippingDetail[]>([]);
    const [loading, setLoading] = useState<Boolean>(true);

    useEffect(() => {
        if (stsTokenManager?.accessToken) {
            fetchAllShipping().then((res) => {
                setShippingList(res);
                setLoading(false);
            });
        }
    }, [stsTokenManager?.accessToken]);

    if (loading) {
        return <Loading />;
    }
    return (
        <div className={"shipping-success-desktop-shipping-success-desktop"}>
            {/* <img className={"shipping-success-desktop-BG"} src={"/img/bg2.png"} /> */}
            <div className="">
                <Navbar></Navbar>
                <div className={"shipping-success-desktop-content-section"}>
                    <div className={"shipping-success-desktop-product-get"}>
                        <div className={"shipping-success-desktop-product-get-title"}>
                            <div className={"shipping-success-desktop-vector"}>
                                <div className={"shipping-success-desktop-div"}>
                                    <img
                                        className={"shipping-success-desktop-line"}
                                        src={"/img/shipping/line-12.png"}
                                    />
                                    <div className={"shipping-success-desktop-ellipse"} />
                                </div>
                            </div>
                            <div className={"shipping-success-desktop-title"}>
                                <img
                                    className={"shipping-success-desktop-iconsax-linear-truckfast"}
                                    src={"/img/shipping/iconsax-linear-truckfast.png"}
                                />

                                <div className={"inventory-desktop-label"}>Delivery</div>
                            </div>
                            <div className={"shipping-success-desktop-overlap-wrapper"}>
                                <div className={"shipping-success-desktop-div"}>
                                    <img
                                        className={"shipping-success-desktop-line-12"}
                                        src={"/img/shipping/line-12-1.png"}
                                    />
                                    <div className={"shipping-success-desktop-ellipse"} />
                                </div>
                            </div>
                        </div>
                        {shippingList?.length === 0 ? (
                            <div className="grid grid-cols-1 gap-2 mt-32 ">
                                <div style={{ color: "white" }}>ไม่พบสินค้าที่ต้องได้รับ</div>
                            </div>
                        ) : shippingList?.length < 3 ? (
                            <div className="grid grid-cols-1 gap-2 mx-5 md:mx-10">
                                <div className={"shipping-success-desktop-lists-2"}>
                                    {shippingList?.map((item) => (
                                        <ShippingList shippingDetail={item} key={item.id}></ShippingList>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="grid grid-cols-1 gap-2 mx-5 md:mx-10">
                                <div className={"shipping-success-desktop-lists"}>
                                    {shippingList?.map((item) => (
                                        <ShippingList shippingDetail={item} key={item.id}></ShippingList>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <FooterDesk></FooterDesk>
            </div>
        </div>
    );
};
