import { useState } from "react";
import { TabName } from "../AuthCard";
import { AuthService } from "../../../../api/auth";
import { toast } from "react-toastify";
import { TabComponent } from "../ForgotPassword/ForgotPassword";

type ISignUpBodyProps = {
    onClickTabCallBack: (tabName: TabName) => void;
    signUpWithGoogleCallBack: (inviteCode?: string) => void;
    signUpWithEmailAndPasswordCallBack: (data: {
        username: string;
        email: string;
        password: string;
        passwordCheck: string;
        parentInviteCode?: string;
    }) => Promise<void>;
    currentTab: TabName;
};

const SignUpBody = (props: ISignUpBodyProps) => {
    const [isSignUp, setIsSignUp] = useState(false);

    const initFormSignUp = {
        email: "",
        password: "",
        passwordCheck: "",
        parentInviteCode: "",
    };

    const [inviteCodeSignUpGoogle, setInviteCodeSignUpGoogle] = useState<string>("");

    const [formSignUp, setFormSignUp] = useState<{
        email: string;
        password: string;
        passwordCheck: string;
        parentInviteCode: string;
    }>(initFormSignUp);

    const handleClickSignup = async () => {
        if (formSignUp.parentInviteCode) verifyInviteCode(formSignUp.parentInviteCode, signup);
        else await signup();
    };

    const verifyInviteCode = (inviteCode: string, onVerifyPass: () => void) => {
        AuthService.verifyInviteCode({ inviteCode })
            .then(onVerifyPass)
            .catch((err) => {
                toast(err?.response?.data?.error?.message, {
                    type: "error",
                });
            });
    };

    const signup = async () => {
        await props.signUpWithEmailAndPasswordCallBack({
            username: formSignUp.email,
            email: formSignUp.email,
            password: formSignUp.password,
            passwordCheck: formSignUp.passwordCheck,
            parentInviteCode: formSignUp.parentInviteCode ?? undefined,
        });
    };

    const handleClickSignupWithGoogle = () => {
        if (inviteCodeSignUpGoogle)
            verifyInviteCode(inviteCodeSignUpGoogle, () => props.signUpWithGoogleCallBack(inviteCodeSignUpGoogle));
        else props.signUpWithGoogleCallBack();
    };

    const InviteCodeInputLabel = () => {
        return (
            <label htmlFor="inviteCode" className="block mb-3 text-sm font-medium text-primary-400">
                รหัสชวนเพื่อน (ได้โบนัสทันที 100 บาท)
            </label>
        );
    };

    return (
        <>
            <div className="flex flex-wrap -mx-4">
                <div className="w-full px-4">
                    <div className=" p-[60px]">
                        <TabComponent currentTab={props.currentTab} onClickTabCallBack={props.onClickTabCallBack} />
                        <div>
                            <div className="mb-4">
                                <label htmlFor="email" className="block mb-3 text-sm font-medium text-white">
                                    Email*
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Enter your Email"
                                    className="w-full px-6 py-3 text-base bg-black border border-white rounded-md"
                                    value={formSignUp.email}
                                    onChange={(e) =>
                                        setFormSignUp({
                                            ...formSignUp,
                                            email: e.target.value as string,
                                        })
                                    }
                                />
                            </div>

                            <div className="flex flex-row">
                                <div className="flex flex-row space-x-5">
                                    <div className="mb-4">
                                        <label htmlFor="password" className="block mb-3 text-sm font-medium text-white">
                                            Password*
                                        </label>
                                        <input
                                            type="password"
                                            name="password"
                                            placeholder="Enter your Password"
                                            className="w-full px-6 py-3 text-base bg-black border border-white rounded-md"
                                            value={formSignUp.password}
                                            onChange={(e) =>
                                                setFormSignUp({
                                                    ...formSignUp,
                                                    password: e.target.value as string,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label
                                            htmlFor="password-check"
                                            className="block mb-3 text-sm font-medium text-white"
                                        >
                                            Confirm password*
                                        </label>
                                        <input
                                            type="password"
                                            name="password-check"
                                            placeholder="Enter your Password"
                                            className="w-full px-6 py-3 text-base bg-black border border-white rounded-md"
                                            value={formSignUp.passwordCheck}
                                            onChange={(e) =>
                                                setFormSignUp({
                                                    ...formSignUp,
                                                    passwordCheck: e.target.value as string,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* <div className="mb-4">
                <InviteCodeInputLabel />
                <input
                  type="text"
                  name="inviteCode"
                  placeholder="Enter your invite code"
                  className="w-full px-6 py-3 text-base bg-black border rounded-md border-primary-400"
                  value={formSignUp.parentInviteCode}
                  onChange={(e) =>
                    setFormSignUp({
                      ...formSignUp,
                      parentInviteCode: e.target.value as string,
                    })
                  }
                />
              </div> */}

                            <div className="mb-6">
                                <button
                                    onClick={async () => {
                                        // Set sign up press status to true
                                        // To show loading button
                                        setIsSignUp(true);
                                        await handleClickSignup();

                                        // Set sign up press status to false
                                        // To hide loading button
                                        setIsSignUp(false);
                                    }}
                                    className="flex items-center justify-center w-full py-4 text-base font-medium text-[#734700] transition duration-300 ease-in-out rounded-[25px] px-9 bg-signin"
                                >
                                    {/* สมัครสมาชิก */}
                                    {isSignUp ? (
                                        <div className="flex-container">
                                            <span className="logging-in-loader" />
                                            <div> Register </div>
                                        </div>
                                    ) : (
                                        "Register"
                                    )}
                                </button>
                            </div>
                        </div>

                        <div className="flex flex-row justify-center mb-8">
                            <p className="text-sm font-medium text-white">or</p>
                        </div>

                        {/* <div className="mb-4">
              <InviteCodeInputLabel />
              <input
                type="text"
                name="inviteCode"
                placeholder="Enter your invite code"
                className="w-full px-6 py-3 text-base bg-black border rounded-md border-primary-400"
                value={inviteCodeSignUpGoogle}
                onChange={(e) =>
                  setInviteCodeSignUpGoogle(e.target.value as string)
                }
              />
            </div> */}
                        <div className="flex flex-row justify-center mb-10">
                            <button
                                onClick={handleClickSignupWithGoogle}
                                className="flex flex-row w-full h-15 items-center justify-center rounded-[25px] p-3 text-base font-medium text-body-color shadow-one hover:text-primary  bg-[#242B51]"
                            >
                                Register with google
                                <svg
                                    className="ml-3"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clipPath="url(#clip0_95:967)">
                                        <path
                                            d="M20.0001 10.2216C20.0122 9.53416 19.9397 8.84776 19.7844 8.17725H10.2042V11.8883H15.8277C15.7211 12.539 15.4814 13.1618 15.1229 13.7194C14.7644 14.2769 14.2946 14.7577 13.7416 15.1327L13.722 15.257L16.7512 17.5567L16.961 17.5772C18.8883 15.8328 19.9997 13.266 19.9997 10.2216"
                                            fill="#4285F4"
                                        />
                                        <path
                                            d="M10.2042 20.0001C12.9592 20.0001 15.2721 19.1111 16.9616 17.5778L13.7416 15.1332C12.88 15.7223 11.7235 16.1334 10.2042 16.1334C8.91385 16.126 7.65863 15.7206 6.61663 14.9747C5.57464 14.2287 4.79879 13.1802 4.39915 11.9778L4.27957 11.9878L1.12973 14.3766L1.08856 14.4888C1.93689 16.1457 3.23879 17.5387 4.84869 18.512C6.45859 19.4852 8.31301 20.0005 10.2046 20.0001"
                                            fill="#34A853"
                                        />
                                        <path
                                            d="M4.39911 11.9777C4.17592 11.3411 4.06075 10.673 4.05819 9.99996C4.0623 9.32799 4.17322 8.66075 4.38696 8.02225L4.38127 7.88968L1.19282 5.4624L1.08852 5.51101C0.372885 6.90343 0.00012207 8.4408 0.00012207 9.99987C0.00012207 11.5589 0.372885 13.0963 1.08852 14.4887L4.39911 11.9777Z"
                                            fill="#FBBC05"
                                        />
                                        <path
                                            d="M10.2042 3.86663C11.6663 3.84438 13.0804 4.37803 14.1498 5.35558L17.0296 2.59996C15.1826 0.901848 12.7366 -0.0298855 10.2042 -3.6784e-05C8.3126 -0.000477834 6.45819 0.514732 4.8483 1.48798C3.2384 2.46124 1.93649 3.85416 1.08813 5.51101L4.38775 8.02225C4.79132 6.82005 5.56974 5.77231 6.61327 5.02675C7.6568 4.28118 8.91279 3.87541 10.2042 3.86663Z"
                                            fill="#EB4335"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_95:967">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </button>
                        </div>

                        <div className="flex flex-row justify-center space-x-4">
                            <div>
                                <div className="text-sm font-medium text-white">have account?</div>
                            </div>
                            <div>
                                <div
                                    className="text-sm font-bold cursor-pointer text-sky-400"
                                    onClick={() => {
                                        props.onClickTabCallBack(TabName.signIn);
                                    }}
                                >
                                    Log In
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignUpBody;
