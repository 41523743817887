import { createContext, useContext, useState } from "react";

export interface IAdminSystemContext {
    activeAdminPage: AdminPageEnum;
    showAdminMenu: boolean;
    handlerShowAdminMenu: (d: boolean) => void;
    handlerActiveAdminPage: (d: AdminPageEnum) => void;
}

export enum AdminPageEnum {
    accounts = "Accounts",
    boxs = "Boxs",
    openboxHistory = "OpenboxHistory",
    topupHistory = "TopupHistory",
    awardwinner = "Awardwinner",
    category = "Category",
    randomjigsawhistory = "RandomJigsawHistory",
    delivery = "Delivery",
}

const AdminSystemContext = createContext<IAdminSystemContext>({} as IAdminSystemContext);

export const AdminSystemWrapper = ({ children }: { children?: JSX.Element }) => {
    const [showAdminMenu, setShowAdminMenu] = useState(localStorage.getItem("SHOW_ADMIN_MENU") ? true : false);
    const [activeAdminPage, setActiveAdminPage] = useState<AdminPageEnum>(AdminPageEnum.accounts);

    const handlerShowAdminMenu = (d: boolean) => {
        if (d) {
            localStorage.setItem("SHOW_ADMIN_MENU", "true");
        } else {
            localStorage.removeItem("SHOW_ADMIN_MENU");
        }
        setShowAdminMenu(d);
    };

    const handlerActiveAdminPage = (d: AdminPageEnum) => {
        setActiveAdminPage(d);
    };

    const sharedState = {
        showAdminMenu,
        handlerShowAdminMenu,
        activeAdminPage,
        handlerActiveAdminPage,
    };

    return (
        <>
            <AdminSystemContext.Provider value={sharedState}>{children}</AdminSystemContext.Provider>
        </>
    );
};

export function useAdminSystemContext() {
    return useContext(AdminSystemContext);
}
