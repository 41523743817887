import { useState } from "react";
import SignInBody from "./SignIn/SignInBody";
import SignUpBody from "./SignUp/SignUpBody";
import { useUserContext } from "../../../hooks/auth";
import "./AuthCard.css";
import ForgotPassword from "./ForgotPassword/ForgotPassword";

export enum TabName {
    signIn = "SIGN_IN",
    signUp = "SIGN_UP",
    forgotPassword = "FORGOT_PASSWORD",
}

const AuthCard = (doneLogin: any) => {
    const { signInGoogle, signUpGoogle, signInWithCcoin, signUpEmailAndPassword } = useUserContext();

    const [activeTabName, setActiveTabName] = useState<TabName>(TabName.signIn);
    const activeTabNameToggleHandler = (tabName: TabName) => {
        setActiveTabName(tabName);
    };

    const renderBodyTab = (currentTabName: TabName) => {
        switch (currentTabName) {
            case TabName.signIn:
                return (
                    <SignInBody
                        currentTab={activeTabName}
                        onClickTabCallBack={(tabName) => {
                            activeTabNameToggleHandler(tabName);
                        }}
                        signInWithGoogleCallBack={signInGoogle}
                        signInWithCcoinCallBack={signInWithCcoin}
                    />
                );
            case TabName.signUp:
                return (
                    <SignUpBody
                        currentTab={activeTabName}
                        onClickTabCallBack={(tabName) => {
                            activeTabNameToggleHandler(tabName);
                        }}
                        signUpWithGoogleCallBack={signUpGoogle}
                        signUpWithEmailAndPasswordCallBack={signUpEmailAndPassword}
                    />
                );
            case TabName.forgotPassword:
                return (
                    <ForgotPassword
                        currentTab={activeTabName}
                        onClickTabCallBack={(tabName) => {
                            activeTabNameToggleHandler(tabName);
                        }}
                    />
                );
            default:
                return (
                    <SignUpBody
                        currentTab={activeTabName}
                        onClickTabCallBack={(tabName) => {
                            activeTabNameToggleHandler(tabName);
                        }}
                        signUpWithGoogleCallBack={signUpGoogle}
                        signUpWithEmailAndPasswordCallBack={signUpEmailAndPassword}
                    />
                );
        }
    };
    return (
        <>
            <div className="flex flex-row w-[1200px] items-start auth-container max-xl:justify-center max-xl:items-center">
                <div
                    className="w-1/2 h-[900px] z-100 bg-cover flex max-xl:hidden"
                    style={{
                        backgroundImage: "url(/img/login/box-hero.png)",
                    }}
                ></div>
                <div className="autBox w-1/2 h-[900px] z-101 borded rounded-tr-xl rounded-br-xl">
                    {renderBodyTab(activeTabName)}
                </div>
            </div>
        </>
    );
};

export default AuthCard;
