import DataTable, { TableColumn, TableStyles } from "react-data-table-component";
import { AccountForAdmin } from "../../../api/admin";
import number from "../../../helpers/number-format";
import { AdminButton } from "../../AdminButton";
import { customStyles } from "../AdminTable";

interface DataTableProps {
    data?: AccountForAdmin[];
    dateFormater: Intl.DateTimeFormat;
}

const AdminMembersTable = (props: DataTableProps) => {
    const { data, dateFormater } = props;

    const columns: TableColumn<AccountForAdmin>[] = [
        {
            name: <div>Id</div>,
            width: "50px",
            cell: (row) => <div>{row.id}</div>,
        },
        {
            name: <div>Provider</div>,
            width: "100px",
            cell: (row) => <div>{row.firebaseProviderId}</div>,
        },
        {
            name: <div>Role</div>,
            width: "80px",
            cell: (row) => <div>{row.role}</div>,
        },
        {
            name: <div>Email</div>,
            width: "180px",
            cell: (row) => <div>{row.email}</div>,
        },
        {
            name: <div>DisplayName</div>,
            width: "180px",
            cell: (row) => <div>{row.displayName}</div>,
        },
        {
            name: <div>Point</div>,
            width: "100px",
            cell: (row) => <div>{number.format(row.point)}</div>,
        },
        {
            name: <div>CreatedAt</div>,
            width: "150px",
            cell: (row) => <div> {dateFormater.format(new Date(row.createdAt))}</div>,
        },
        {
            name: <div>UpdatedAt</div>,
            width: "150px",
            cell: (row) => <div> {dateFormater.format(new Date(row.createdAt))}</div>,
        },
        {
            name: <></>,
            cell: (row) => (
                <AdminButton
                    main="secondary"
                    onClick={() => {
                        window.location.href = `/admin/account/${row.id}`;
                    }}
                >
                    Manage
                </AdminButton>
            ),
        },
    ];

    return (
        <DataTable
            pagination
            customStyles={customStyles}
            noDataComponent={<>No Data Not Found</>}
            columns={columns}
            data={data ?? []}
            className="max-w-[1112px] overflow-x-scroll"
        />
    );
};

export default AdminMembersTable;
