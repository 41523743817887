import { useState } from "react";
import { TabName } from "../AuthCard";
import { requestResetPassword } from "../../../../api/google-authen";
import { toast } from "react-toastify";

const ForgotPassword = ({
    onClickTabCallBack,
    currentTab,
}: {
    onClickTabCallBack: (tab: TabName) => void;
    currentTab: TabName;
}) => {
    const [email, setEmail] = useState<string>("");

    const handleRequestResetPassword = () => {
        requestResetPassword(email)
            .then(() =>
                toast(
                    <div>
                        <h1 className="font-bold text-green-500">We sent you an email to continue.</h1>
                        <p className="text-xs">Please check your email to reset password.</p>
                    </div>,
                    {
                        type: "success",
                    },
                ),
            )
            .catch(() => {
                toast("ไม่พบอีเมลนี้ในระบบ กรุณาลองใหม่อีกครั้ง", {
                    type: "error",
                });
            });
    };

    return (
        <div className="flex flex-wrap -mx-4">
            <div className="w-full px-4">
                <div className="p-[60px]">
                    <TabComponent onClickTabCallBack={onClickTabCallBack} currentTab={currentTab} />
                    <div>
                        <div className="mb-8">
                            <label htmlFor="username" className="block mb-3 text-sm font-medium text-white">
                                Email*
                            </label>
                            <input
                                type="text"
                                name="username"
                                placeholder="Enter your Email"
                                className="w-full px-6 py-3 text-base bg-black border border-white rounded-md"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value as string);
                                }}
                            />
                        </div>

                        <div className="flex flex-col mb-8">
                            <div className="mb-6">
                                <button
                                    disabled={!email}
                                    onClick={handleRequestResetPassword}
                                    className="flex items-center justify-center w-full py-4 text-base font-medium text-white transition duration-300 ease-in-out rounded-md primary-bg px-9 hover:bg-opacity-80 hover:shadow-signUp"
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;

export const TabComponent = ({
    onClickTabCallBack,
    currentTab,
}: {
    currentTab: TabName;
    onClickTabCallBack: (tab: TabName) => void;
}) => {
    const isActive = (tab: TabName) => {
        return currentTab === tab ? "text-white" : "text-gray-400";
    };
    return (
        <div className="flex items-start justify-center lg:justify-start mb-8">
            <div className="border-b border-gray-200">
                <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 ">
                    <li className="mr-2">
                        <div
                            onClick={() => onClickTabCallBack(TabName.signIn)}
                            className="inline-flex p-2 lg:p-4 border-b-2 rounded-t-lg primary-border active "
                            aria-current="page"
                        >
                            <h3
                                className={`mb-3 text-md md:text-xl font-bold text-center ${isActive(
                                    TabName.signIn,
                                )} cursor-pointer`}
                            >
                                Log In
                            </h3>
                        </div>
                    </li>
                    <li className="mr-2">
                        <div
                            onClick={() => onClickTabCallBack(TabName.signUp)}
                            className="inline-flex p-2 lg:p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 group"
                        >
                            <h3
                                className={`mb-3 text-md md:text-xl font-bold text-center ${isActive(
                                    TabName.signUp,
                                )} cursor-pointer`}
                            >
                                Register
                            </h3>
                        </div>
                    </li>
                    <li className="mr-2">
                        <div
                            onClick={() => onClickTabCallBack(TabName.forgotPassword)}
                            className="inline-flex p-2 lg:p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 group"
                        >
                            <h3
                                className={`mb-3 text-md md:text-xl font-bold text-center ${isActive(
                                    TabName.forgotPassword,
                                )} cursor-pointer`}
                            >
                                Forgot Password
                            </h3>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};
