import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ShippingDetail } from "../../../interfaces/Shipping";
import "./style.css";

interface Props {
    shippingDetail: ShippingDetail;
}
export const ShippingList = (props: Props) => {
    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    return (
        <div className="grid grid-cols-1 ">
            <div className={"shipping-success-desktop-shipping-list-item"} style={{}}>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-2 my-5">
                    <div className=" col-span-1  ">
                        <div className="flex   justify-center">
                            <img
                                className={"shipping-success-desktop-product-image"}
                                src={"/img/shipping/productimage.png"}
                            />
                        </div>
                    </div>
                    <div className=" col-span-2 md:mx-2">
                        <div className={"shipping-success-desktop-shipping-detail-3"}>
                            <div className={"shipping-success-desktop-date-get-product"}>
                                <div className={"shipping-success-desktop-text-wrapper-2"}>arrives between</div>
                                <div className={"shipping-success-desktop-text-wrapper-3"}>
                                    {props.shippingDetail.arrivalTime
                                        ? moment(props.shippingDetail.arrivalTime).format("llll")
                                        : "-"}
                                </div>
                            </div>
                            <div className={"shipping-success-desktop-parcel-number"}>
                                <div className={"shipping-success-desktop-text-wrapper-4"}>Tracking number</div>
                                <div
                                    className={
                                        "shipping-success-desktop-air-jordan-retro-SUPREME-LUCKY-air-jordan-retro"
                                    }
                                >
                                    {props.shippingDetail.shippingNumber ? props.shippingDetail.shippingNumber : "-"}
                                </div>
                                {props.shippingDetail.shippingNumber && (
                                    <div
                                        className={"shipping-success-desktop-text-wrapper-5"}
                                        onClick={() => {
                                            copyToClipboard(props.shippingDetail.shippingNumber);
                                            toast("คัดลอกหมายเลขพัสดุสำเร็จ", { autoClose: 500 });
                                        }}
                                    >
                                        Copy
                                    </div>
                                )}
                            </div>
                            <div className={"shipping-success-desktop-product-detail"}>
                                <div className={"shipping-success-desktop-text-wrapper-4"}>Details</div>
                                <p
                                    className={
                                        "shipping-success-desktop-air-jordan-retro-SUPREME-LUCKY-air-jordan-retro"
                                    }
                                >
                                    {props.shippingDetail.products.map((product) => {
                                        return (
                                            <span className={"shipping-success-desktop-span"} key={product.id}>
                                                {product.name}
                                            </span>
                                        );
                                    })}
                                </p>
                            </div>
                            <div className={"shipping-success-desktop-overlap-group-wrapper"}>
                                <div className={"shipping-success-desktop-overlap-group"}>
                                    <p className={"shipping-success-desktop-address-detail"}>
                                        {props.shippingDetail.nameShipping}
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;phone.
                                        {props.shippingDetail.phoneShipping}
                                        <br />
                                        {props.shippingDetail.addressShipping}
                                        &nbsp;
                                        {props.shippingDetail.districtShipping}
                                        &nbsp;
                                        {props.shippingDetail.provinceShipping}
                                        &nbsp;&nbsp;
                                        {props.shippingDetail.postalCodeShipping}
                                    </p>
                                    <div className={"shipping-success-desktop-BG-2"} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" col-span-1 ">
                        <div className="flex   justify-center">
                            <div className={"shipping-success-desktop-text-wrapper-6"}>
                                {props.shippingDetail.status}
                            </div>

                            {/*  <Link to={"/shipping/detail/" + props.shippingDetail.id}>
                            <button className="flex items-center justify-center  py-4 text-base font-medium text-[#734700] transition duration-300 ease-in-out rounded-[25px] px-9 hover:shadow-signUp bg-signin">
                                SEE DETAILS
                            </button>
                        </Link>  */}
                        </div>
                        <div className="flex flex-wrap justify-center mt-4 md:mt-32">
                            <Link to={"/shipping/detail/" + props.shippingDetail.id}>
                                <button className="flex items-center justify-center  py-4 text-base font-medium text-[#734700] transition duration-300 ease-in-out rounded-[25px] px-9 hover:shadow-signUp bg-signin">
                                    SEE DETAILS
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                {/*  <div className="grid grid-cols-1 grid-cols-4 gap-4 mb-5">
              <div className="col-start-4">
              <div className="flex flex-wrap justify-center">
                <Link to={"/shipping/detail/" + props.shippingDetail.id}>
                    <button className="flex items-center justify-center  py-4 text-base font-medium text-[#734700] transition duration-300 ease-in-out rounded-[25px] px-9 hover:shadow-signUp bg-signin">
                        SEE DETAILS
                    </button>
                </Link>
                </div>
                </div>
            </div> */}

                {/*  <div className={"shipping-success-desktop-shipping-detail"}>
                <div className={"shipping-success-desktop-shipping-detail-2"}>
                    <img className={"shipping-success-desktop-product-image"} src={"/img/shipping/productimage.png"} />
                    <div className={"shipping-success-desktop-shipping-detail-3"}>
                        <div className={"shipping-success-desktop-date-get-product"}>
                            <div className={"shipping-success-desktop-text-wrapper-2"}>arrives between</div>
                            <div className={"shipping-success-desktop-text-wrapper-3"}>
                                {props.shippingDetail.arrivalTime
                                    ? moment(props.shippingDetail.arrivalTime).format("llll")
                                    : "-"}
                            </div>
                        </div>
                        <div className={"shipping-success-desktop-parcel-number"}>
                            <div className={"shipping-success-desktop-text-wrapper-4"}>Tracking number</div>
                            <div className={"shipping-success-desktop-air-jordan-retro-SUPREME-LUCKY-air-jordan-retro"}>
                                {props.shippingDetail.shippingNumber ? props.shippingDetail.shippingNumber : "-"}
                            </div>
                            {props.shippingDetail.shippingNumber && (
                                <div
                                    className={"shipping-success-desktop-text-wrapper-5"}
                                    onClick={() => {
                                        copyToClipboard(props.shippingDetail.shippingNumber);
                                        toast("คัดลอกหมายเลขพัสดุสำเร็จ", { autoClose: 500 });
                                    }}
                                >
                                    Copy
                                </div>
                            )}
                        </div>
                        <div className={"shipping-success-desktop-product-detail"}>
                            <div className={"shipping-success-desktop-text-wrapper-4"}>Details</div>
                            <p className={"shipping-success-desktop-air-jordan-retro-SUPREME-LUCKY-air-jordan-retro"}>
                                {props.shippingDetail.products.map((product) => {
                                    return (
                                        <span className={"shipping-success-desktop-span"} key={product.id}>
                                            {product.name}
                                        </span>
                                    );
                                })}
                            </p>
                        </div>
                        <div className={"shipping-success-desktop-overlap-group-wrapper"}>
                            <div className={"shipping-success-desktop-overlap-group"}>
                                <p className={"shipping-success-desktop-address-detail"}>
                                    {props.shippingDetail.nameShipping}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;phone.
                                    {props.shippingDetail.phoneShipping}
                                    <br />
                                    {props.shippingDetail.addressShipping}
                                    &nbsp;
                                    {props.shippingDetail.districtShipping}
                                    &nbsp;
                                    {props.shippingDetail.provinceShipping}
                                    &nbsp;&nbsp;
                                    {props.shippingDetail.postalCodeShipping}
                                </p>
                                <div className={"shipping-success-desktop-BG-2"} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"shipping-success-desktop-product-detail-2"}>
                    <div className={"shipping-success-desktop-text-wrapper-6"}>{props.shippingDetail.status}</div>
                    <Link to={"/shipping/detail/" + props.shippingDetail.id}>
                        <div className={"shipping-success-desktop-address-detail-button"}>
                            <div className={"shipping-success-desktop-address-detail-label-wrapper"}>
                                <div className={"shipping-success-desktop-address-detail-label"}>SEE DETAILS</div>
                                <button className="flex items-center justify-center  py-4 text-base font-medium text-[#734700] transition duration-300 ease-in-out rounded-[25px] px-9 hover:shadow-signUp bg-signin">
                                    SEE DETAILS
                                </button>
                            </div>
                        </div>
                    </Link>
                </div>
            </div> */}
            </div>
        </div>
    );
};
