import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useUserContext } from "../../../hooks/auth";
import "./style.css";

export const DropDownMenu = (): JSX.Element => {
    const { isAdmin, logout } = useUserContext();
    const handleLogout = () => {
        logout();
        toast("Log out success", { autoClose: 500 });
    };
    return (
        <div className={"drop-down-menu-drop-down-menu-wrapper"}>
            <div className={"drop-down-menu-drop-down-menu"}>
                <div className={"drop-down-menu-menu-list"}>
                    {/* <Link
            to={"/invite-friend"}
            className={"drop-down-menu-dropdown-menu"}
          >
            <svg
              className={"drop-down-menu-icon-star"}
              width="22"
              height="18"
              viewBox="0 0 22 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 2H1M4 16H1M2 9H1M14.39 2.21L15.8 5.03C15.99 5.42 16.5 5.79 16.93 5.87L19.48 6.29C21.11 6.56 21.49 7.74 20.32 8.92L18.33 10.91C18 11.24 17.81 11.89 17.92 12.36L18.49 14.82C18.94 16.76 17.9 17.52 16.19 16.5L13.8 15.08C13.37 14.82 12.65 14.82 12.22 15.08L9.83 16.5C8.12 17.51 7.08 16.76 7.53 14.82L8.1 12.36C8.21 11.9 8.02 11.25 7.69 10.91L5.7 8.92C4.53 7.75 4.91 6.57 6.54 6.29L9.09 5.87C9.52 5.8 10.03 5.42 10.22 5.03L11.63 2.21C12.38 0.68 13.62 0.68 14.39 2.21Z"
                stroke="#616161"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <div className={"drop-down-menu-text-wrapper"}>
              เชิญเพื่อนได้แต้ม
            </div>
          </Link> */}
                    {isAdmin && (
                        <Link to="/admin/accounts" className={"drop-down-menu-dropdown-menu"}>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M19.7274 20.4471C19.2716 19.1713 18.2672 18.0439 16.8701 17.2399C15.4729 16.4358 13.7611 16 12 16C10.2389 16 8.52706 16.4358 7.12991 17.2399C5.73276 18.0439 4.72839 19.1713 4.27259 20.4471"
                                    stroke="#616161"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                />
                                <circle cx="12" cy="8" r="4" stroke="#616161" strokeWidth="1.5" strokeLinecap="round" />
                            </svg>

                            <div className={"drop-down-menu-text-wrapper"}>Administrator</div>
                        </Link>
                    )}
                    <Link to="/inventory" className={"drop-down-menu-dropdown-menu"}>
                        <svg
                            className={"drop-down-menu-icon-star"}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.0008 6V5.2C8.0008 3.43 8.0008 2 11.2008 2H12.8008C16.0008 2 16.0008 3.43 16.0008 5.2V6M8.0008 22H16.0008C20.0208 22 20.7408 20.39 20.9508 18.43L21.7008 10.43C21.9708 7.99 21.2708 6 17.0008 6H7.0008C2.7308 6 2.0308 7.99 2.3008 10.43L3.0508 18.43C3.2608 20.39 3.9808 22 8.0008 22Z"
                                stroke="#616161"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M14.0011 14.02V13C14.0011 12 14.0011 12 13.0011 12H11.0011C10.0011 12 10.0011 12 10.0011 13V14.03M14.0011 14.02C14.0011 15.11 13.9911 16 12.0011 16C10.0211 16 10.0011 15.12 10.0011 14.03M14.0011 14.02C16.7653 13.6759 19.3972 12.6369 21.6511 11M10.0011 14.03C7.41109 13.74 4.87109 12.81 2.62109 11.27"
                                stroke="#616161"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>

                        <div className={"drop-down-menu-text-wrapper"}>Your Inventory</div>
                    </Link>
                    <div className={"drop-down-menu-dropdown-menu"}>
                        <svg
                            className={"drop-down-menu-icon-star"}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.0017 14H13.0017C14.1017 14 15.0017 13.1 15.0017 12V2H6.00172C4.50172 2 3.19172 2.83 2.51172 4.05"
                                stroke="#616161"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M2 17C2 18.66 3.34 20 5 20H6C6 18.9 6.9 18 8 18C9.1 18 10 18.9 10 20H14C14 18.9 14.9 18 16 18C17.1 18 18 18.9 18 20H19C20.66 20 22 18.66 22 17V14H19C18.45 14 18 13.55 18 13V10C18 9.45 18.45 9 19 9H20.29L18.58 6.01C18.4038 5.70402 18.1504 5.44968 17.845 5.27243C17.5396 5.09518 17.1931 5.00124 16.84 5H15V12C15 13.1 14.1 14 13 14H12"
                                stroke="#616161"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M2 8H8M2 11H6M2 14H4M8 22C8.53043 22 9.03914 21.7893 9.41421 21.4142C9.78929 21.0391 10 20.5304 10 20C10 19.4696 9.78929 18.9609 9.41421 18.5858C9.03914 18.2107 8.53043 18 8 18C7.46957 18 6.96086 18.2107 6.58579 18.5858C6.21071 18.9609 6 19.4696 6 20C6 20.5304 6.21071 21.0391 6.58579 21.4142C6.96086 21.7893 7.46957 22 8 22ZM16 22C16.5304 22 17.0391 21.7893 17.4142 21.4142C17.7893 21.0391 18 20.5304 18 20C18 19.4696 17.7893 18.9609 17.4142 18.5858C17.0391 18.2107 16.5304 18 16 18C15.4696 18 14.9609 18.2107 14.5858 18.5858C14.2107 18.9609 14 19.4696 14 20C14 20.5304 14.2107 21.0391 14.5858 21.4142C14.9609 21.7893 15.4696 22 16 22ZM22 12V14H19C18.45 14 18 13.55 18 13V10C18 9.45 18.45 9 19 9H20.29L22 12Z"
                                stroke="#616161"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>

                        <Link className={"drop-down-menu-text-wrapper"} to="/shipping">
                            Delivery
                        </Link>
                    </div>
                    <div className={"drop-down-menu-dropdown-menu"}>
                        <svg
                            className={"drop-down-menu-icon-star"}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M10.7516 16.8594V18.8894C10.7516 20.6094 9.15156 21.9994 7.18156 21.9994C5.21156 21.9994 3.60156 20.6094 3.60156 18.8894V16.8594C3.60156 18.5794 5.20156 19.7994 7.18156 19.7994C9.15156 19.7994 10.7516 18.5694 10.7516 16.8594Z"
                                stroke="#616161"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M10.7498 14.1098C10.7498 14.6098 10.6098 15.0698 10.3698 15.4698C9.77984 16.4398 8.56984 17.0498 7.16984 17.0498C5.76984 17.0498 4.55984 16.4298 3.96984 15.4698C3.72984 15.0698 3.58984 14.6098 3.58984 14.1098C3.58984 13.2498 3.98984 12.4798 4.62984 11.9198C5.27984 11.3498 6.16984 11.0098 7.15984 11.0098C8.14984 11.0098 9.03984 11.3598 9.68984 11.9198C10.3498 12.4698 10.7498 13.2498 10.7498 14.1098Z"
                                stroke="#616161"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M10.7516 14.1092V16.8592C10.7516 18.5792 9.15156 19.7992 7.18156 19.7992C5.21156 19.7992 3.60156 18.5692 3.60156 16.8592V14.1092C3.60156 12.3892 5.20156 10.9992 7.18156 10.9992C8.17156 10.9992 9.06156 11.3492 9.71156 11.9092C10.3516 12.4692 10.7516 13.2492 10.7516 14.1092ZM21.9996 10.9692V13.0292C21.9996 13.5792 21.5596 14.0292 20.9996 14.0492H19.0396C17.9596 14.0492 16.9696 13.2592 16.8796 12.1792C16.8196 11.5492 17.0596 10.9592 17.4796 10.5492C17.8496 10.1692 18.3596 9.94922 18.9196 9.94922H20.9996C21.5596 9.96922 21.9996 10.4192 21.9996 10.9692Z"
                                stroke="#616161"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M2 10.5V8.5C2 5.78 3.64 3.88 6.19 3.56C6.45 3.52 6.72 3.5 7 3.5H16C16.26 3.5 16.51 3.51 16.75 3.55C19.33 3.85 21 5.76 21 8.5V9.95H18.92C18.36 9.95 17.85 10.17 17.48 10.55C17.06 10.96 16.82 11.55 16.88 12.18C16.97 13.26 17.96 14.05 19.04 14.05H21V15.5C21 18.5 19 20.5 16 20.5H13.5"
                                stroke="#616161"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>

                        <Link to={"/topup"} className={"drop-down-menu-text-wrapper"}>
                            Top up
                        </Link>
                    </div>
                    <div className={"drop-down-menu-dropdown-menu"}>
                        <svg
                            className={"drop-down-menu-icon-star"}
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M14.1 6.56023C13.79 2.96023 11.94 1.49023 7.89 1.49023H7.76C3.29 1.49023 1.5 3.28023 1.5 7.75023V14.2702C1.5 18.7402 3.29 20.5302 7.76 20.5302H7.89C11.91 20.5302 13.76 19.0802 14.09 15.5402M8 11.0002H19.38M17.15 7.65023L20.5 11.0002L17.15 14.3502"
                                stroke="#616161"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>

                        <div className={"drop-down-menu-text-wrapper"} onClick={handleLogout}>
                            Log out
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
