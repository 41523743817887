import $ from "jquery";
import { useEffect } from "react";
import { ItemInTheBox_Unused } from "../../../../../interfaces/ItemInTheBox";
import "./style.css";

interface Props {
    ItemsInTheBox: ItemInTheBox_Unused[];
    ItemResult: ItemInTheBox_Unused;
    onSpinFinished: () => void;
}
export const Reel = ({ onSpinFinished, ItemsInTheBox, ItemResult }: Props) => {
    const renderTextBrand = (grade: number, brand?: string) => {
        switch (grade) {
            case 0:
                return `<div class="selection-product-brand text-[#FBE350]">${brand ?? "no brand"}</div>`;
            case 1:
                return `<div class="selection-product-brand text-[#5168F7]">${brand ?? "no brand"}</div>`;
            case 2:
                return `<div class="selection-product-brand text-[#7F4AF7]">${brand ?? "no brand"}</div>`;
            case 3:
                return `<div class="selection-product-brand text-[#C23EDE]">${brand ?? "no brand"}</div>`;
            case 4:
                return `<div class="selection-product-brand text-[#D95752]">${brand ?? "no brand"}</div>`;
            case 5:
                return `<div class="selection-product-brand text-[#ecc834]">${brand ?? "no brand"}</div>`;
            default:
                return `<div class="selection-product-brand text-[#000000]">${brand ?? "no brand"}</div>`;
        }
    };

    const renderGradeLine = (grade: number) => {
        switch (grade) {
            case 0:
                return `<div class="AD34-selection-BG-line bg-[#FBE350]" ></div>`;
            case 1:
                return `<div class="AD34-selection-BG-line bg-[#5168F7]" ></div>`;
            case 2:
                return `<div class="AD34-selection-BG-line bg-[#7F4AF7]" ></div>`;
            case 3:
                return `<div class="AD34-selection-BG-line bg-[#C23EDE]" ></div>`;
            case 4:
                return `<div class="AD34-selection-BG-line bg-[#D95752]" ></div>`;
            case 5:
                return `<div class="AD34-selection-BG-line bg-[#ecc834]" ></div>`;
            default:
                return `<div class="AD34-selection-BG-line bg-[#000000]" ></div>`;
        }
    };

    const items = ItemsInTheBox.map((item, index) => {
        return `
    <div class="swiper-slide " style="scale:1.2" id="${index}" key="${index}"> 
      <div class="selection-product-info">
      <div class="AD34-selection-BG">
        ${renderGradeLine(item.grade)}
      </div>
        <img class="selection-product-image" src="${item.imageUrl}" />
        <div class="selection-description">
          <p class="selection-product-name">${item.name}</p>
          ${renderTextBrand(item.grade, item.brand)}
        </div>
      </div>
    </div>`;
    });
    const itemResult = `
    <div class="swiper-slide " style="scale:1.2" id="itemResult"> 
      <div class="selection-product-info">
      <div class="AD34-selection-BG">
        ${renderGradeLine(ItemResult.grade)}
      </div>
        <img class="selection-product-image" src="${ItemResult.imageUrl}" />
        <div class="selection-description">
          <p class="selection-product-name">${ItemResult.name}</p>
          ${renderTextBrand(ItemResult.grade, ItemResult.brand)}
        </div>
      </div>
    </div>`;

    const setUpMiddleLine = () => {
        const middleElement = document.querySelector(".middle");
        let animationDuration = 0.3;
        const durationIncrement = (1 - 0.1) / 10000;

        setInterval(() => {
            if (animationDuration <= 0.05) {
                animationDuration = 0.05;
            } else {
                animationDuration -= durationIncrement;
            }
            // @ts-ignore
            middleElement.style.animationDuration = `${animationDuration}s`;
        }, 1);
    };

    function renderReel() {
        $(".slider-wrapper").empty();
        for (var i = 0; i < 80; i++) {
            if (i == 59) {
                $(".slider-wrapper").append(itemResult);
            } else {
                $(".slider-wrapper").append(items[i % items.length]);
            }
        }
    }

    const stopSpin = (timer: NodeJS.Timer) => {
        clearInterval(timer);
        // remove all element
        $(".slider-wrapper").empty();
        onSpinFinished();
    };

    const estimateMiddlePositionItemResult = () => {
        const itemWidth = 200;
        return 55 * itemWidth;
    };

    const reachResultItem = (right: number, middlePosition: number) => {
        return right >= middlePosition;
    };

    const estimateOfsetLeftItemResult = () => {
        return window.innerWidth * 0.44;
    };

    const getMiddleResultItemPosition = () => {
        const reesultItemOffsetLeft = document.getElementById("itemResult")?.offsetLeft;
        return reesultItemOffsetLeft
            ? reesultItemOffsetLeft - estimateOfsetLeftItemResult()
            : estimateMiddlePositionItemResult();
    };

    const spin = () => {
        renderReel();

        let right = 0;
        let speed = 20;

        const middlePosition = getMiddleResultItemPosition();

        $(".overlay").css("display", "none");

        const timer = setInterval(function () {
            right += speed;
            $(".slider-wrapper").css("right", right + "px");

            if (reachResultItem(right, middlePosition)) stopSpin(timer);
            else {
                const distanceLeft = middlePosition - right;
                if (distanceLeft > 8000) {
                    speed = 15;
                } else if (distanceLeft > 7000) {
                    speed = 18;
                } else if (distanceLeft > 4000) {
                    speed = 20;
                } else if (distanceLeft > 2800) {
                    speed = 20;
                } else if (distanceLeft > 2500) {
                    speed = 20;
                } else if (distanceLeft > 1500) {
                    speed = 25;
                } else if (distanceLeft > 1250) {
                    speed = 25;
                } else if (distanceLeft > 1100) {
                    speed = 25;
                } else if (distanceLeft > 1000) {
                    speed = 20;
                } else if (distanceLeft > 750) {
                    speed = 20;
                } else if (distanceLeft > 500) {
                    speed = 20;
                } else if (distanceLeft > 100) {
                    speed = 2;
                } else {
                    speed = 1;
                }
            }
        }, 10);
    };

    useEffect(() => {
        setUpMiddleLine();
        spin();
    }, []);

    return (
        <div style={{ width: "100%" }} className="overflow-hidden">
            <div className="overlay"></div>
            <div className="slider-wrapper"></div>
            <div className="middle"></div>
        </div>
    );
};
