// import { useEffect, useState } from "react";
import { useState } from "react";
import { TabName } from "../AuthCard";
// import { TabComponent } from "../ForgotPassword/ForgotPassword";
import { ECCoinAppName } from "../../../../hooks/auth";
import "./customDropdown.css";

// Define the options with labels and image paths
const dropdownOptions = [
    { label: "CCoinBot", value: ECCoinAppName.CCOINBOT, image: "/img/logo/ccoinbot.png" },
    { label: "CWeb3", value: ECCoinAppName.CWEB3, image: "/img/logo/cweb3.png" },
    { label: "CCommu", value: ECCoinAppName.CCOMMU, image: "/img/logo/ccommu.png" }
];

// Custom Dropdown Component
const CustomDropdown = ({ options, selected, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="custom-dropdown">
            <div className="selected-option" onClick={() => setIsOpen(!isOpen)}>
                <img src={selected.image} alt={selected.label} className="option-image" />
                {selected.label}
            </div>
            {isOpen && (
                <div className="options">
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className="option"
                            onClick={() => {
                                onSelect(option.value); // Pass only the value here
                                setIsOpen(false);
                            }}
                        >
                            <img src={option.image} alt={option.label} className="option-image" />
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

type ISignInBodyProps = {
    onClickTabCallBack: (tabName: TabName) => void;
    signInWithGoogleCallBack: () => void;
    signInWithCcoinCallBack: (data: { appName: ECCoinAppName; username: string; password: string }) => Promise<void>;
    currentTab: TabName;
};

const SignInBody = (props: ISignInBodyProps) => {
    const initFormSignIn = {
        emailOrUsername: "",
        password: "",
        appName: dropdownOptions[0].value
    };
    const [formSignIn, setFormSignIn] = useState<{
        emailOrUsername: string;
        password: string;
        appName: ECCoinAppName;
    }>(initFormSignIn);

    const [isLogginIn, setIsLogginIn] = useState<boolean>(false);

    const [emailError, setEmailError] = useState("");
    const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
    const [isTermsAccepted, setIsTermsAccepted] = useState(true);

    const handleTermsChange = event => {
        setIsTermsAccepted(event.target.checked);
    };

    const handleTermsModal = open => {
        setIsTermsModalOpen(open);
        // Prevent the main content from scrolling when the modal is open
        const body = document.querySelector("body");
        if (body) {
            body.style.overflow = open ? "hidden" : "auto";
        }
    };

    const handleInputChange = e => {
        const inputValue = e.target.value;
        setFormSignIn({
            ...formSignIn,
            emailOrUsername: inputValue
        });
    };

    const handleDropdownSelect = selectedValue => {
        const selectedOption = dropdownOptions.find(option => option.value === selectedValue);
        if (selectedOption) {
            setFormSignIn({ ...formSignIn, appName: selectedOption.value });
        }
    };

    return (
        <>
            <div className="flex flex-wrap -mx-4">
                <div className="w-full px-4">
                    <div className="p-[60px]">
                        {/* <TabComponent currentTab={props.currentTab} onClickTabCallBack={props.onClickTabCallBack} /> */}
                        <div>
                            <div className="mb-8">
                                <label className="block mb-3 text-sm font-medium text-white">Login via*</label>
                                <CustomDropdown
                                    options={dropdownOptions}
                                    selected={dropdownOptions.find(option => option.value === formSignIn.appName)} // Find the selected option object
                                    onSelect={handleDropdownSelect}
                                />
                            </div>

                            <div className="mb-8">
                                <label htmlFor="username" className="block mb-3 text-sm font-medium text-white">
                                    Username*
                                </label>
                                <input
                                    type="email"
                                    name="username"
                                    placeholder="Enter your Username"
                                    className="w-full px-6 py-3 text-base bg-black border border-white rounded-md"
                                    value={formSignIn.emailOrUsername}
                                    onChange={handleInputChange}
                                />
                                {emailError && <p className="text-red-500 text-sm mt-2">{emailError}</p>}
                            </div>

                            <div className="flex flex-col mb-8">
                                <label htmlFor="password" className="block mb-3 text-sm font-medium text-white">
                                    Password*
                                </label>
                                <div className="flex flex-row items-start justify-start space-x-4">
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Enter your Password"
                                        className="w-full  px-6 py-3 text-base bg-black border border-white rounded-md"
                                        value={formSignIn.password}
                                        onChange={e => {
                                            setFormSignIn({
                                                ...formSignIn,
                                                password: e.target.value as string
                                            });
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Checkbox for Terms and Conditions */}
                            <div className="mb-6">
                                <label className="flex items-center space-x-3 mb-3">
                                    <input
                                        type="checkbox"
                                        name="acceptedTerms"
                                        className="form-checkbox text-yellow-500 h-5 w-5 rounded border-gray-300 focus:ring-yellow-500 bg-white checked:bg-yellow-500" // Custom styling for checkbox
                                        checked={isTermsAccepted}
                                        onChange={handleTermsChange}
                                    />
                                    <span className="text-gray-300 font-normal">
                                        I accept the Terms and Conditions.
                                    </span>
                                </label>
                                <button
                                    className="text-xs text-yellow-300 hover:underline"
                                    onClick={() => setIsTermsModalOpen(true)}
                                >
                                    Click to read Terms and Conditions
                                </button>

                                {/* Terms and Conditions Modal */}
                                {isTermsModalOpen && (
                                    <div
                                        className="fixed inset-0 bg-black bg-opacity-50 z-50 overflow-y-auto h-full w-full"
                                        id="my-modal"
                                    >
                                        <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-10/12 h-5/6 shadow-lg rounded-md bg-black">
                                            <div className="flex flex-col h-full">
                                                <div className="text-center">
                                                    <h3 className="text-lg leading-6 font-medium text-yellow-500">
                                                        Terms and Conditions
                                                    </h3>
                                                </div>
                                                <div className="flex-grow overflow-y-auto px-7 py-3 text-sm text-gray-300">
                                                    {" "}
                                                    <p>
                                                        Introduction
                                                        <br />
                                                        Welcome to Our App. By accessing or using Our App, you are
                                                        agreeing to these terms and conditions ("Terms"). Please read
                                                        them carefully.
                                                    </p>
                                                    <p>
                                                        Use of Our App
                                                        <br />
                                                        You may use Our App only as permitted by law. Do not misuse Our
                                                        App by trying to access it using a method other than the
                                                        interface and instructions that we provide.
                                                    </p>
                                                    <p>
                                                        Your Account
                                                        <br />
                                                        If you create an account on Our App, you are responsible for
                                                        maintaining the security of your account, and you are fully
                                                        responsible for all activities that occur under the account and
                                                        any other actions taken in connection with it.
                                                    </p>
                                                    <p>
                                                        Modifications to the Service
                                                        <br />
                                                        We are constantly changing and improving Our App. We may add or
                                                        remove functionalities or features, and we may suspend or stop a
                                                        service altogether.
                                                    </p>
                                                    <p>
                                                        Liabilities
                                                        <br />
                                                        We provide Our App using a commercially reasonable level of
                                                        skill and care, but there are certain things that we don't
                                                        promise about Our App.
                                                    </p>
                                                    <p>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                                                        ullamcorper est ac velit volutpat ullamcorper. Fusce accumsan
                                                        tempor nisi, laoreet placerat diam semper vel. Nunc nec suscipit
                                                        est, id dictum nulla. In laoreet et dolor id semper. Donec
                                                        volutpat dapibus odio, tincidunt scelerisque massa varius vel.
                                                        Pellentesque vitae turpis vehicula nisl porta ultricies. Fusce
                                                        at augue quis libero gravida congue. Maecenas efficitur massa et
                                                        rhoncus luctus. Vestibulum rutrum congue odio ac iaculis. Fusce
                                                        pulvinar in tellus aliquam rutrum. Morbi eu tellus et metus
                                                        euismod sodales. Vivamus elementum luctus neque eu lobortis.
                                                        Maecenas imperdiet erat massa, quis placerat tellus dignissim
                                                        eget. Sed quis porttitor nulla. Praesent bibendum libero vitae
                                                        odio hendrerit, id aliquam felis iaculis. Praesent tincidunt at
                                                        tellus non rhoncus. Fusce a molestie augue, vitae efficitur mi.
                                                        Curabitur volutpat commodo ipsum, et sagittis sapien lobortis
                                                        at. Quisque non velit quis tellus porta imperdiet eu vitae eros.
                                                        Etiam scelerisque posuere ante, et congue ante pellentesque eu.
                                                        Duis nec maximus arcu, id tristique enim. Nulla facilisi.
                                                        Vestibulum lorem lacus, malesuada at purus eu, tempor malesuada
                                                        sapien. Praesent convallis condimentum augue, at varius ex
                                                        ullamcorper eu. Proin efficitur malesuada elit, vel feugiat arcu
                                                        sodales sit amet. Aenean lacinia, tortor quis aliquam fermentum,
                                                        sapien lacus euismod erat, ut fringilla nisi nunc at lacus.
                                                        Donec quam ex, bibendum interdum ligula sit amet, convallis
                                                        facilisis sapien. Praesent vehicula purus sit amet ligula
                                                        ultrices lacinia. Aliquam erat volutpat. Integer laoreet luctus
                                                        felis vitae suscipit. Nunc ornare mollis odio, quis blandit
                                                        mauris ultrices id. Nulla facilisi. Etiam tempus mauris id
                                                        varius rutrum. Phasellus nec risus sit amet enim finibus
                                                        interdum molestie a leo. Nam convallis ultricies hendrerit.
                                                        Class aptent taciti sociosqu ad litora torquent per conubia
                                                        nostra, per inceptos himenaeos. Aliquam convallis tristique
                                                        magna vel semper. Integer cursus, nisi eget feugiat tempus,
                                                        magna orci fringilla diam, maximus mattis est nunc pulvinar
                                                        felis. Donec eros urna, venenatis vitae venenatis ac, ultrices
                                                        at felis. Suspendisse lobortis non ante ac malesuada. Integer
                                                        varius tortor quis metus vestibulum iaculis. Nulla euismod elit
                                                        id lacinia pulvinar. Phasellus dapibus ipsum ut feugiat
                                                        ullamcorper. Cras sed odio vel massa congue fringilla. Aenean
                                                        bibendum metus sed mauris lacinia, id finibus libero iaculis.
                                                        Nam enim sem, tempus sit amet lacinia in, dapibus sit amet sem.
                                                        Sed lectus lectus, sollicitudin vel eros ultrices, hendrerit
                                                        dignissim massa. Integer a porttitor erat, egestas fermentum
                                                        odio. Morbi eu pharetra risus. Vivamus velit lacus, volutpat ut
                                                        nunc commodo, dignissim auctor nibh. Nam scelerisque diam nec
                                                        libero cursus, semper semper lacus accumsan. Mauris sit amet
                                                        venenatis nisl, quis lacinia diam. Ut sit amet mauris rhoncus,
                                                        mollis lacus ut, mattis sapien. Mauris nec dapibus odio, a
                                                        aliquam est. Praesent vestibulum nunc sed tristique blandit.
                                                        Vivamus ut mi fringilla mi convallis auctor quis nec justo.
                                                        Integer non tristique orci, at consequat turpis. Curabitur
                                                        vulputate a sem id faucibus. Ut sagittis purus sem, ut porta
                                                        sapien facilisis sed. Maecenas tristique ante tortor, eget
                                                        consequat turpis viverra a. Ut convallis dui non libero sodales,
                                                        et laoreet ipsum viverra. Sed ullamcorper, felis vitae
                                                        vestibulum pellentesque, mi lectus mattis leo, eget bibendum
                                                        nisi neque eu enim. Mauris scelerisque tellus et porta euismod.
                                                        Nam posuere sagittis posuere. Mauris venenatis ornare justo ac
                                                        ullamcorper. Donec elementum imperdiet libero, vitae feugiat dui
                                                        posuere a. Vestibulum at sagittis dui, et posuere nunc. Proin
                                                        rhoncus aliquet mollis. Sed maximus, velit eu blandit
                                                        ullamcorper, lorem nibh lobortis ante, a fringilla nulla mi quis
                                                        risus. Quisque iaculis urna auctor eros rutrum, in efficitur
                                                        mauris luctus. Suspendisse id nisi diam. Quisque interdum
                                                        porttitor luctus. Sed ut nulla dictum, finibus neque quis,
                                                        ultrices elit. Vivamus id convallis metus. Sed ut orci ut mauris
                                                        iaculis facilisis ut id neque. Vivamus lacinia urna sed nisl
                                                        tincidunt, vel sagittis dui tempor. Donec a nisi orci. Donec
                                                        dictum lorem vel magna consequat dapibus. Proin ac commodo
                                                        ligula. Phasellus id nunc ultrices, laoreet lectus sed, dapibus
                                                        est. Aenean faucibus elit mi, venenatis varius dolor imperdiet
                                                        nec. Sed ligula justo, condimentum eu dignissim eget, feugiat
                                                        sit amet tortor. Pellentesque pharetra orci placerat massa
                                                        mattis porttitor. Etiam enim eros, feugiat id blandit eu,
                                                        pulvinar eu mi. Vestibulum elementum nunc ut magna dignissim
                                                        accumsan. Duis malesuada lorem a urna fermentum, quis convallis
                                                        quam condimentum. In nulla est, consequat vel dui nec, cursus
                                                        porttitor purus. Suspendisse id interdum augue. Aenean tempor,
                                                        tellus quis faucibus sagittis, neque orci porta sapien, nec
                                                        dapibus enim nulla in odio. Nullam vulputate ornare eleifend.
                                                        Quisque ipsum ipsum, malesuada eu bibendum sit amet, maximus
                                                        laoreet mauris. Fusce eu fermentum ex. Cras ornare metus in
                                                        interdum luctus. Fusce tincidunt pellentesque risus, non
                                                        fermentum odio volutpat ac. Vestibulum ante ipsum primis in
                                                        faucibus orci luctus et ultrices posuere cubilia curae; Vivamus
                                                        vel facilisis sapien, sed volutpat leo. Quisque id ipsum in quam
                                                        tristique accumsan commodo eu tortor. Donec eu tellus quis massa
                                                        lacinia posuere eu eu urna. Phasellus arcu tortor, dictum non
                                                        gravida non, hendrerit ut enim. Cras laoreet ante justo, at
                                                        interdum odio feugiat nec. Curabitur tincidunt justo vel mauris
                                                        malesuada tempor. Praesent vel massa augue. Duis maximus metus
                                                        sed luctus sollicitudin. Nunc id leo nec ante consequat lacinia.
                                                        Sed a sapien urna. Maecenas convallis lacinia tristique. Cras
                                                        ultricies lectus et justo feugiat finibus. Aenean varius dictum
                                                        felis quis dapibus. Donec at metus dolor. Morbi augue diam,
                                                        sagittis vel tortor id, sagittis vestibulum purus. Sed semper
                                                        nulla eu quam commodo, id placerat ex tempus. Phasellus luctus
                                                        efficitur tellus. Nullam tempor accumsan risus, in fringilla leo
                                                        fermentum et. Vestibulum aliquet metus orci, at mollis lectus
                                                        pharetra in. Duis tempus massa lectus, eu dapibus urna luctus
                                                        at. In tristique tempor nibh, ut mollis lacus cursus sit amet.
                                                        Pellentesque ut dapibus quam, vel venenatis justo. Ut maximus
                                                        tempor ipsum quis facilisis. Morbi tristique vel ligula vehicula
                                                        porta. Sed tortor dolor, placerat vel interdum eget, euismod a
                                                        neque. Donec vel finibus ante, sagittis viverra nisi. Nullam
                                                        aliquet libero sed pretium interdum. In nec urna lacus. Etiam
                                                        sed laoreet lacus, sit amet bibendum velit. Morbi viverra magna
                                                        sed pellentesque rhoncus. Integer malesuada risus ante. Donec
                                                        vestibulum nulla lacus, nec dignissim turpis semper quis.
                                                        Maecenas venenatis quam quis diam lacinia.
                                                    </p>
                                                </div>
                                                <div className="items-center px-4 py-3">
                                                    <button
                                                        className="px-4 py-2 bg-yellow-500 text-black text-base font-medium rounded-md w-full shadow-sm hover:bg-yellow-600"
                                                        onClick={() => setIsTermsModalOpen(false)}
                                                    >
                                                        Close
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="mb-6">
                                <button
                                    disabled={!isTermsAccepted}
                                    onClick={async () => {
                                        setIsLogginIn(true);
                                        await props.signInWithCcoinCallBack({
                                            appName: formSignIn.appName,
                                            username: formSignIn.emailOrUsername,
                                            password: formSignIn.password
                                        });
                                        setIsLogginIn(false);
                                    }}
                                    className={`flex items-center justify-center w-full py-4 text-base font-medium text-black transition duration-300 ease-in-out rounded-md px-9 ${
                                        isTermsAccepted
                                            ? "bg-yellow-500 hover:bg-yellow-600"
                                            : "bg-yellow-300 cursor-not-allowed"
                                    }`}
                                >
                                    {isLogginIn ? (
                                        <div className="flex-container">
                                            <span className="logging-in-loader" />
                                            <div> Logging in </div>
                                        </div>
                                    ) : (
                                        "Log In"
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignInBody;
