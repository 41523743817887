import React from "react";
import "./style.css";
interface props {
    style?: React.CSSProperties;
}
export const FooterDesk = ({ style }: props): JSX.Element => {
    return (
        <div className={"home-desk-footer-section"} style={style}>
            <div className={"home-desk-footer-col"}>
                <div className={"home-desk-title"}>COMMUNITY</div>
                <div className={"home-desk-sub-menu-list !gap-6"}>
                    <div
                        className={"home-desk-text-wrapper-5 cursor-pointer hover:text-white"}
                        onClick={() => (window.location.href = "https://www.c.community/")}
                    >
                        CCOMMU
                    </div>
                    <div
                        className={"home-desk-text-wrapper-4 cursor-pointer hover:text-white"}
                        onClick={() => (window.location.href = "https://www.cweb3.io/")}
                    >
                        CWEB3
                    </div>
                    <div
                        className={"home-desk-text-wrapper-4 cursor-pointer hover:text-white"}
                        onClick={() => (window.location.href = "https://ccoinbot.com/")}
                    >
                        CCOINBOT
                    </div>
                </div>
            </div>
            <div className={"home-desk-footer-col-2"}>
                <div className={"home-desk-infomations"}>INFOMATIONS</div>
                <div className={"home-desk-frame-302 !gap-6"}>
                    <div
                        className={"home-desk-text-wrapper-5 cursor-pointer hover:text-white"}
                        onClick={() => (window.location.href = "/")}
                    >
                        Home
                    </div>
                    <div
                        className={"home-desk-text-wrapper-4 cursor-pointer hover:text-white"}
                        onClick={() => (window.location.href = "https://ccoinbot.com/")}
                    >
                        Randowm Jigsaws
                    </div>
                    <div
                        className={"home-desk-text-wrapper-4 cursor-pointer hover:text-white"}
                        onClick={() => (window.location.href = "https://ccoinbot.com/")}
                    >
                        How It Work
                    </div>
                </div>
            </div>
            <div />
        </div>
    );
};
