import { useEffect, useState } from "react";
import {
    BoxProductForAdmin,
    getBoxProductByProductIdForAdmin,
    updateBoxProductByProductIdForAdmin,
} from "../../../api/admin";
import { useUserContext } from "../../../hooks/auth";
import { useDialogContext } from "../../../hooks/dialog";
import { AdminHeader, AdminPanel } from "../../../components/Layout/AdminPanel";
import { AdminPageEnum, useAdminSystemContext } from "../../../hooks/admin";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AdminButton } from "../../../components/AdminButton";

export const BoxProductAdminPage = () => {
    const { boxId, productId } = useParams();
    const { isAdmin, user } = useUserContext();
    const { handlerActiveAdminPage } = useAdminSystemContext();
    const { showLoading, closeLoading } = useDialogContext();

    const numberFormat = new Intl.NumberFormat("th", {
        minimumFractionDigits: 2,
    });
    const dateFormater = new Intl.DateTimeFormat("th", { dateStyle: "medium", timeZone: "Asia/Bangkok" });
    const [boxProduct, setBoxProduct] = useState<BoxProductForAdmin>({
        id: 0,
        boxId: 0,
        name: "",
        brand: "",
        imageUrl: "",
        currentDropRate: 0,
        defaultDropRate: 0,
        grade: 0,
        price: 0,
        minMarginToDrop: 0,
        maxDrop: 0,
        dropCount: 0,
        isDefaultBoxProduct: false,
        isDeleted: false,
        createdAt: new Date(),
        updatedAt: new Date(),
        deletedAt: new Date(),
        shippingFee: 0,
    });

    const handleError = (err: { title: string; message: string }) => {
        toast(
            <div>
                <h1 className="font-bold text-red-400">{err.title}</h1>
                <p className="text-xs">{err.message}</p>
            </div>,
            {
                type: "error",
            },
        );
    };

    const [isEditData, setIsEditData] = useState(false);

    useEffect(() => {
        showLoading();
        if (user) {
            if (!isAdmin) {
                window.location.href = "/403";
            } else {
                if (boxId && !isNaN(Number(boxId)) && !isNaN(Number(productId))) {
                    getBoxProductByProductIdForAdmin(Number(productId))
                        .then(setBoxProduct)
                        .catch((error) => {
                            handleError({
                                title: "Something went wrong!",
                                message: "Please try again",
                            });
                        })
                        .finally(() => {
                            closeLoading();
                        });
                } else {
                    closeLoading();
                    handleError({
                        title: "Something went wrong!",
                        message: "Please try again",
                    });
                }
            }
        }
    }, [user]);

    handlerActiveAdminPage(AdminPageEnum.boxs);
    return (
        <>
            <AdminPanel>
                <>
                    {/* Header */}
                    <AdminHeader
                        pageName="Dashboard"
                        historyList={[
                            {
                                name: "Boxs",
                                route: "/admin/boxs",
                            },
                            {
                                name: "BoxProducts",
                                route: `/admin/boxs/${boxId}/products`,
                            },
                            {
                                name: "BoxProduct",
                                route: `/admin/boxs/${boxId}/products/${productId}`,
                            },
                        ]}
                    />
                    {/* Table Data */}
                    <div className="flex flex-col p-4 m-10 max-lg:m-0 max-lg:pt-10 bg-white rounded-lg shadow">
                        <div className="flex flex-row justify-between">
                            <div className="flex flex-col">
                                <h1 className="text-lg font-bold">BoxProduct</h1>
                                <p className="text-sm text-gray-400">ข้อมูลสินค้า</p>
                            </div>

                            <div className="flex">
                                {isEditData ? (
                                    <div className="flex flex-row gap-5 justify-center items-center">
                                        <AdminButton
                                            main="secondary"
                                            onClick={() => {
                                                setIsEditData(false);
                                                getBoxProductByProductIdForAdmin(Number(productId))
                                                    .then(setBoxProduct)
                                                    .catch((error) => {
                                                        handleError({
                                                            title: "Something went wrong!",
                                                            message: "Please try again",
                                                        });
                                                    });
                                            }}
                                        >
                                            Cancel
                                        </AdminButton>

                                        <AdminButton
                                            main="primary"
                                            onClick={() => {
                                                setIsEditData(false);
                                                showLoading();
                                                updateBoxProductByProductIdForAdmin(Number(productId), boxProduct)
                                                    .then(setBoxProduct)
                                                    .catch((error) => {
                                                        handleError({
                                                            title: "Something went wrong!",
                                                            message: "Please try again",
                                                        });
                                                    })
                                                    .finally(() => {
                                                        closeLoading();
                                                    });
                                            }}
                                        >
                                            Save
                                        </AdminButton>
                                    </div>
                                ) : (
                                    <div className="flex justify-center items-center">
                                        <AdminButton main="default" onClick={() => setIsEditData(true)}>
                                            Edit
                                        </AdminButton>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="flex flex-col gap-5 p-5">
                            {/* id */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>id</h1>
                                </div>
                                <div className="flex text-[#464747]">{boxProduct?.id}</div>
                            </div>

                            {/* boxId */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>boxId</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={boxProduct.boxId.toString().replace(/^0+/, "")}
                                            onChange={(e) => {
                                                setBoxProduct({
                                                    ...boxProduct,
                                                    boxId: Number(e.target.value),
                                                });
                                            }}
                                            type="number"
                                            id="boxProduct-input-boxId"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="boxId"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">{boxProduct?.boxId}</div>
                                )}
                            </div>

                            {/* name */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>Name</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={boxProduct?.name}
                                            onChange={(e) => {
                                                setBoxProduct({
                                                    ...boxProduct,
                                                    name: e.target.value,
                                                });
                                            }}
                                            type="text"
                                            id="boxProduct-input-name"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="name"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">{boxProduct?.name}</div>
                                )}
                            </div>

                            {/* brand */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>brand</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={boxProduct?.brand}
                                            onChange={(e) => {
                                                setBoxProduct({
                                                    ...boxProduct,
                                                    brand: e.target.value,
                                                });
                                            }}
                                            type="text"
                                            id="boxProduct-input-brand"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="brand"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">{boxProduct?.brand}</div>
                                )}
                            </div>

                            {/* imageUrl */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>imageUrl</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={boxProduct?.imageUrl}
                                            onChange={(e) => {
                                                setBoxProduct({
                                                    ...boxProduct,
                                                    imageUrl: e.target.value,
                                                });
                                            }}
                                            type="text"
                                            id="boxProduct-input-imageUrl"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="imageUrl"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">
                                        <img className="w-[10rem]" src={boxProduct.imageUrl} alt="imageUrl" />
                                    </div>
                                )}
                            </div>

                            {/* currentDropRate */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>currentDropRate</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={boxProduct.currentDropRate}
                                            onChange={(e) => {
                                                setBoxProduct({
                                                    ...boxProduct,
                                                    currentDropRate: Number(e.target.value),
                                                });
                                            }}
                                            type="number"
                                            id="boxProduct-input-currentDropRate"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="currentDropRate"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">{boxProduct?.currentDropRate}</div>
                                )}
                            </div>

                            {/* defaultDropRate */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>defaultDropRate</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={boxProduct.defaultDropRate}
                                            onChange={(e) => {
                                                setBoxProduct({
                                                    ...boxProduct,
                                                    defaultDropRate: Number(e.target.value),
                                                });
                                            }}
                                            type="number"
                                            id="boxProduct-input-defaultDropRate"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="defaultDropRate"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">{boxProduct?.defaultDropRate}</div>
                                )}
                            </div>

                            {/* grade */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>grade</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <select
                                            id="boxProduct-select-grade"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-md focus:ring-gray-400 focus:border-gray-400 w-full"
                                            onChange={(e) => {
                                                setBoxProduct({
                                                    ...boxProduct,
                                                    grade: Number(e.target.value),
                                                });
                                            }}
                                        >
                                            <option selected={boxProduct.grade == 0} value={0}>
                                                0
                                            </option>
                                            <option selected={boxProduct.grade == 1} value={1}>
                                                1
                                            </option>
                                            <option selected={boxProduct.grade == 2} value={2}>
                                                2
                                            </option>
                                            <option selected={boxProduct.grade == 3} value={3}>
                                                3
                                            </option>
                                            <option selected={boxProduct.grade == 4} value={4}>
                                                4
                                            </option>
                                            <option selected={boxProduct.grade == 5} value={5}>
                                                5
                                            </option>
                                        </select>
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">
                                        {boxProduct.grade == 0 ? (
                                            <div className={"w-[5rem] bg-[#D6D7D5] text-center"}>
                                                {boxProduct.grade}
                                            </div>
                                        ) : boxProduct.grade == 1 ? (
                                            <div className={"w-[5rem] bg-[#5168F7] text-center"}>
                                                {boxProduct.grade}
                                            </div>
                                        ) : boxProduct.grade == 3 ? (
                                            <div className={"w-[5rem] bg-[#7F4AF7] text-center"}>
                                                {boxProduct.grade}
                                            </div>
                                        ) : boxProduct.grade == 3 ? (
                                            <div className={"w-[5rem] bg-[#C23EDE] text-center"}>
                                                {boxProduct.grade}
                                            </div>
                                        ) : boxProduct.grade == 4 ? (
                                            <div className={"w-[5rem] bg-[#D95752] text-center"}>
                                                {boxProduct.grade}
                                            </div>
                                        ) : boxProduct.grade == 5 ? (
                                            <div className={"w-[5rem] bg-[#ecc834] text-center"}>
                                                {boxProduct.grade}
                                            </div>
                                        ) : (
                                            <div className={"w-[5rem] bg-[#000000] text-center"}>
                                                {boxProduct.grade}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>

                            {/* price */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>price</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={boxProduct.price.toString().replace(/^0+/, "")}
                                            onChange={(e) => {
                                                setBoxProduct({
                                                    ...boxProduct,
                                                    price: Number(e.target.value),
                                                });
                                            }}
                                            type="number"
                                            id="boxProduct-input-price"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="price"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">
                                        {boxProduct.price ? `฿ ${numberFormat.format(boxProduct.price)}` : "null"}
                                    </div>
                                )}
                            </div>

                            {/* minMarginToDrop */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>minMarginToDrop</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={
                                                boxProduct.minMarginToDrop
                                                    ? boxProduct.minMarginToDrop.toString().replace(/^0+/, "")
                                                    : undefined
                                            }
                                            onChange={(e) => {
                                                setBoxProduct({
                                                    ...boxProduct,
                                                    minMarginToDrop: Number(e.target.value),
                                                });
                                            }}
                                            type="number"
                                            id="boxProduct-input-minMarginToDrop"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="minMarginToDrop"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">
                                        {boxProduct.minMarginToDrop
                                            ? `฿ ${numberFormat.format(boxProduct.minMarginToDrop)}`
                                            : "null"}
                                    </div>
                                )}
                            </div>

                            {/* maxDrop */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>maxDrop</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={boxProduct.maxDrop.toString().replace(/^0+/, "")}
                                            onChange={(e) => {
                                                setBoxProduct({
                                                    ...boxProduct,
                                                    maxDrop: Number(e.target.value),
                                                });
                                            }}
                                            type="number"
                                            id="boxProduct-input-maxDrop"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="maxDrop"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">{boxProduct?.maxDrop}</div>
                                )}
                            </div>

                            {/* dropCount */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>dropCount</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={boxProduct.dropCount.toString().replace(/^0+/, "")}
                                            onChange={(e) => {
                                                setBoxProduct({
                                                    ...boxProduct,
                                                    dropCount: Number(e.target.value),
                                                });
                                            }}
                                            type="number"
                                            id="boxProduct-input-dropCount"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="dropCount"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">{boxProduct?.dropCount}</div>
                                )}
                            </div>

                            {/* isDefaultBoxProduct */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>isDefaultBoxProduct</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <select
                                            id="boxProduct-select-isDefaultBoxProduct"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-md focus:ring-gray-400 focus:border-gray-400 w-full"
                                            onChange={(e) => {
                                                setBoxProduct({
                                                    ...boxProduct,
                                                    isDefaultBoxProduct: e.target.value === "true",
                                                });
                                            }}
                                        >
                                            <option selected={boxProduct.isDefaultBoxProduct == true} value={"true"}>
                                                true
                                            </option>
                                            <option selected={boxProduct.isDefaultBoxProduct == false} value={"false"}>
                                                false
                                            </option>
                                        </select>
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">
                                        {boxProduct?.isDefaultBoxProduct ? "true" : "false"}
                                    </div>
                                )}
                            </div>

                            {/* isDeleted */}
                            {/* <div className="flex flex-row">
                <div className="flex w-1/4">
                  <h1>isDeleted</h1>
                </div>
                <div className="flex text-[#464747]">
                  {boxProduct?.isDeleted}
                </div>
              </div> */}

                            {/* createdAt */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>createdAt</h1>
                                </div>
                                <div className="flex text-[#464747]">
                                    {boxProduct.createdAt ? dateFormater.format(new Date(boxProduct.createdAt)) : ""}
                                </div>
                            </div>

                            {/* updatedAt */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>updatedAt</h1>
                                </div>
                                <div className="flex text-[#464747]">
                                    {boxProduct.updatedAt ? dateFormater.format(new Date(boxProduct.updatedAt)) : ""}
                                </div>
                            </div>

                            {/* shippingFee */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>shippingFee</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={boxProduct.shippingFee.toString().replace(/^0+/, "")}
                                            onChange={(e) => {
                                                setBoxProduct({
                                                    ...boxProduct,
                                                    shippingFee: Number(e.target.value),
                                                });
                                            }}
                                            type="number"
                                            id="boxProduct-input-shippingFee"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="shippingFee"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">{boxProduct?.shippingFee}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            </AdminPanel>
        </>
    );
};
