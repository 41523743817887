import DataTable, { TableColumn } from "react-data-table-component";
import { TopupHistoryForAdmin } from "../../../api/admin";
import { customStyles } from "../AdminTable";
import numberFormat from "../../../helpers/number-format";
import Badge from "../../BadgeStatus";

interface DataTableProps {
    data?: TopupHistoryForAdmin[];
    dateFormater: Intl.DateTimeFormat;
}

const AdminTopupHistoryTable = (props: DataTableProps) => {
    const { data, dateFormater } = props;

    const columns: TableColumn<TopupHistoryForAdmin>[] = [
        {
            name: <div>Id</div>,
            cell: (row) => <div>{row.id}</div>,
        },
        {
            name: <div>Account</div>,
            cell: (row) => <div>{row.accountEmail}</div>,
        },
        {
            name: <div>Type</div>,
            center: true,
            cell: (row) => <div>{row.type}</div>,
        },
        {
            name: <div>Amount</div>,
            center: true,
            cell: (row) => <div>{numberFormat.format(row.amount)}</div>,
        },
        {
            name: <div>Status</div>,
            center: true,
            cell: (row) => <Badge status={row.status}>{row.status.replace(/\w+/g,
            function(w){return w[0].toUpperCase() + w.slice(1).toLowerCase();})}</Badge>,
        },
        {
            name: <div>TopupDateAt</div>,
            center: true,
            cell: (row) => <div>{row.topupDateAt ? dateFormater.format(new Date(row.topupDateAt)) : ""}</div>,
        },
    ];

    return (
        <DataTable
            pagination
            noDataComponent={<>No Data Not Found</>}
            columns={columns}
            data={data ?? []}
            customStyles={customStyles}
            className="max-w-[1112px] overflow-x-scroll"
        />
    );
};

export default AdminTopupHistoryTable;
