import httpClient from "../client/client";
import { DetailShipping, ShippingDetail, ShippingInfo, ShippingWithTimelineDetail } from "../interfaces/Shipping";

export const createShipping = async (shipping: ShippingInfo) => {
    return httpClient
        .post<{
            id: number;
        }>("/shipping", shipping)
        .then(res => res.data);
};

export const fetchAllShipping = async (): Promise<ShippingDetail[]> => {
    return httpClient.get("/shipping").then(res => res.data);
};

export const getShippingDetail = async (shippingId: number): Promise<ShippingWithTimelineDetail> => {
    return httpClient.get(`/shipping/${shippingId}`).then(res => res.data);
};

export const deleteShipping = async (id: number) => {
    return httpClient.delete(`/shipping/${id}`).then(res => res.data);
};

export const patchShippingDetail = async (id: number, request: DetailShipping) => {
    console.log("Calll");
    return httpClient
        .patch(`/shipping/${id}`, {
            ...request
        })
        .then(res => res.data);
};
