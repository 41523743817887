import $ from "jquery";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { openBoxAPI } from "../../../api/ItemBox";
import { WEB_SOCKET_URL } from "../../../client/web-socket";
import { useUserContext } from "../../../hooks/auth";
import {
    JigsawInventoryPiece,
    JigsawPiece,
    JigsawPieceGroupCount,
    JigsawProduct,
    Round,
} from "../../../interfaces/Box";
import { ItemInTheBox_Unused, RandomBoxItemResult } from "../../../interfaces/ItemInTheBox";
import { ItemrandomDesktop } from "./ItemrandomDesktop";
import { ItemresultDesktop } from "./ItemresultDesktop";
import "./style.css";
import { getjigsawPiecesImgFromName } from "../../../hooks/useJigsaw";
import { useSound } from "../../../hooks/useSound";
import { io } from "socket.io-client";
import { BurnPopup } from "./BurnPopup";
import { ShowRandomJigsawHistories } from "./RandomJigsawHistories";
import { useTimeCounter } from "../../../helpers/utils";
export interface MainItemProps {
    boxData: JigsawProduct;
    unusedJigsawPiece: JigsawInventoryPiece[];
    round: Round | undefined;
    ItemsInTheBox: JigsawPiece[];
    myJigsaw: JigsawPieceGroupCount[];
    onSpinningCompleted: (completed: boolean) => void;
}

export const MainItem = ({
    boxData,
    ItemsInTheBox,
    onSpinningCompleted,
    round,
    myJigsaw,
    unusedJigsawPiece,
}: MainItemProps): JSX.Element => {
    const testMode = false; // เปิด ปิด เพื่อทดสอบ
    const [isSpinning, setInSpinning] = useState(false);
    const [isSpinCompleted, setIsSpinCompleted] = useState(false);
    const [resultItem, setResultItem] = useState<RandomBoxItemResult | null>(null);
    const { user, stsTokenManager, reloadUserPoint } = useUserContext();
    const [itemRandomPiece, setItemRandomPiece] = useState<ItemInTheBox_Unused[]>([]);
    const [isSpinSoundPlaying, toggleSpinSound] = useSound("/sound/spin-sound.mp3");
    const [messageHistory, setMessageHistory] = useState([]);
    const [boxCompletedPlaying, toggleBoxCompleted] = useSound("/sound/box-open-completed.mp3");
    const [waitforWebsocketResponse, setWaitforWebsocketResponse] = useState<boolean>(false); // รอรับข้อมูลจาก websocket ก่อน
    const [weHaveAllPieces, setWeHaveAllPieces] = useState<boolean>(false);
    const [showBurnPopup, setShowBurnPopup] = useState<boolean>(false);
    const transactionIdRef = useRef("");
    const socketConnected = useRef(false);
    const [showRandomJigsawHistories, setShowRandomJigsawHistories] = useState<boolean>(false);
    const duration = useTimeCounter(new Date(round?.created_at || ""));
    useEffect(() => {
        // Using .then()
        convertToItemInTheBox(ItemsInTheBox).then((results) => {
            setItemRandomPiece(results);
        });
    }, [ItemsInTheBox]);
    function onConnect() {
        socketConnected.current = true;
        console.log("socket connected");
        // socket.emit("randomJigsaw", "Hello world");
    }

    useEffect(() => {
        if (myJigsaw.length > 0) {
            setWeHaveAllPieces(myJigsaw.every((item) => item.amount > 0));
        }
    }, [myJigsaw]);

    function onDisconnect() {
        socketConnected.current = false;
        console.log("socket disconnected");
    }
    async function onRandomJigsawEvent(message) {
        console.log("onRandomJigsawEvent", message);
        // check transactionId match
        if (message.request != transactionIdRef.current) {
            console.error("transactionId not match", message.request, transactionIdRef.current);
            return;
        }
        setWaitforWebsocketResponse(false);
        if (message.status != "success") {
            if (message.status == "ROUND_ALREADY_FINISH") {
                toast("Round Already Finish", {
                    type: "error",
                });
            } else {
                toast(message.message, {
                    type: "error",
                });
            }
            console.error("WebSocket message error:", message);
            onSpinningCompleted(false);
            setInSpinning(false);
            setResultItem(null);
            return;
        }
        // start spinning
        setInSpinning(true);
        //@ts-ignore
        toggleSpinSound();
        onSpinningCompleted(false);
        setResultItem(null);
        animateOnSipining();
        setInSpinning(true);
        console.log("WebSocket message received:", message);

        let JigsawPiece = message.result as JigsawPiece;
        let JigsawImage = await getjigsawPiecesImgFromName(
            boxData.jigsawImageUrl,
            boxData.jigsawSizeX,
            boxData.jigsawSizeY,
            JigsawPiece.col,
            JigsawPiece.row
        );
        setResultItem({
            brand: JigsawPiece.name,
            grade: 0,
            id: JigsawPiece.id,
            imageUrl: JigsawImage,
            inventoryBoxProductId: 0,
            name: boxData.name,
            price: 0,
            shippingFee: 0,
        });
    }
    useEffect(() => {
        if (stsTokenManager?.accessToken && !socketConnected.current) {
            console.log("connecting to socket.io with token", stsTokenManager?.accessToken);
            const socket = io(WEB_SOCKET_URL);
            socket.connect();
            socket.on("connect", onConnect);
            socket.on("disconnect", onDisconnect);
            socket.on("randomJigsaw", onRandomJigsawEvent);
            socket.on("message", (data) => {
                console.log("socket data message", data);
            });
            // error
            socket.on("error", (error) => {
                console.error("Socket error", error);
            });
        }
    }, [stsTokenManager]);
    const openBox = (InventoryJigsawPieceId?: number[]) => {
        console.log("handleOpenBox");
        if (user == null) return;
        if (user.point < boxData.productPrice) {
            // english
            toast("You don't have enough credit", {
                type: "error",
            });
            return;
        }
        if (!socketConnected.current) {
            console.error("WebSocket is not connected");
            toast("เกิดข้อผิดพลาดในการเชื่อมต่อกับเซิฟเวอร์ กรุณาลองใหม่อีกครั้ง", {
                type: "error",
            });
        }

        // test
        if (testMode) {
            //@ts-ignore
            toggleSpinSound();
            reloadUserPoint();
            setInSpinning(true);
            // if (isSpinning) setShowResultItem(true);
            getjigsawPiecesImgFromName(boxData.jigsawImageUrl, boxData.jigsawSizeX, boxData.jigsawSizeY, 1, 2).then(
                (dataUrl) => {
                    setResultItem({
                        brand: "A2",
                        id: 1,
                        grade: 0,
                        imageUrl: dataUrl,
                        inventoryBoxProductId: 1,
                        name: "(A2) " + boxData.name,
                        price: 1000,
                        shippingFee: 0,
                    });
                }
            );
            return;
        }
        console.log("round", round);
        if (round) {
            setWaitforWebsocketResponse(true);
            openBoxAPI(boxData.id, round.round_id, InventoryJigsawPieceId)
                .then((data) => {
                    console.log("call random-jigsaw api", data);
                    transactionIdRef.current = data.transactionRequest;
                    reloadUserPoint();
                })
                .catch((err) => {
                    console.error("random-jigsaw api error", err);
                    if (err.message == "YOU_ALREADY_HAVE_ALL_JIGSAW") {
                        toast("you already have all jigsaw pieces", {
                            type: "error",
                        });
                        return;
                    } else {
                        toast("error", {
                            type: "error",
                        });
                    }
                });
        } else {
            toast("round not found", {
                type: "error",
            });
        }
    };

    const animateOnSipining = () => {
        $("#random-btn").css("display", "none");
    };

    const handleReOpenBox = () => {
        setInSpinning(false);
        setIsSpinCompleted(false);
    };

    const handleSpinCompleted = () => {
        $("#random-btn").css("display", "block");
        $("#live-drop-popup-left").css("display", "block");
        $("#live-drop-popup-right").css("display", "block");
        setIsSpinCompleted(true);
        onSpinningCompleted(true);
        //@ts-ignore
        toggleBoxCompleted();
    };

    const handleSellItems = () => {
        // TODO: handle sell items
    };

    const convertToItemInTheBox = async (item: JigsawPiece[]): Promise<ItemInTheBox_Unused[]> => {
        const promises = item.map(async (piece) => {
            const dataUrl = await getjigsawPiecesImgFromName(
                boxData.jigsawImageUrl,
                boxData.jigsawSizeX,
                boxData.jigsawSizeY,
                piece.col,
                piece.row
            );
            return {
                id: piece.id,
                name: `${boxData.name}`,
                imageUrl: dataUrl,
                brand: `${piece.name}`,
                grade: 0,
                price: 0,
                shippingFee: 0,
            };
        });

        return Promise.all(promises);
    };

    return (
        <>
            {showBurnPopup && (
                <BurnPopup
                    unusedJigsawPiece={unusedJigsawPiece}
                    onClosed={() => setShowBurnPopup(false)}
                    handleBurnClick={(InventoryJigsawPieceId) => {
                        openBox(InventoryJigsawPieceId);
                    }}
                ></BurnPopup>
            )}
            {isSpinCompleted && resultItem != null && round ? (
                <ItemresultDesktop
                    boxInfo={boxData}
                    round={round}
                    itemResult={[resultItem]}
                    handleSellClick={handleSellItems}
                    onReOpenBoxClick={handleReOpenBox}
                ></ItemresultDesktop>
            ) : (
                <>
                    {isSpinning && resultItem !== null && (
                        <ItemrandomDesktop
                            boxInfo={boxData}
                            round={round!}
                            onSpinFinished={handleSpinCompleted}
                            ItemsInTheBox={itemRandomPiece}
                            ItemResult={resultItem}
                        ></ItemrandomDesktop>
                    )}
                    <div className={"MainBox-MainBox-wrapper"}>
                        <div className={"MainBox-MainBox"}>
                            <div className={"MainBox-box-content"}>
                                <div
                                    className={
                                        "grid grid-cols-3 justify-center	items-center gap-2 min-h-[100px]" +
                                        (isSpinning ? " animated-hide" : " animated-show")
                                    }
                                >
                                    <div className="flex justify-end items-end w-0 xl:w-full">
                                        {/* <img alt="" src={"/img/ImgLines/line3.png"} /> */}
                                    </div>
                                    <div className="flex justify-center	items-center flex-col">
                                        <h1 className={"MainBox-name-box-label"}>{boxData.name}</h1>
                                        <h1 className={"MainBox-name-box-label-round"} style={{ fontSize: "3rem" }}>
                                            🏳️Round {round?.round_number}
                                        </h1>
                                        <h1 className={"MainBox-name-box-label"} style={{ fontSize: "1.2rem" }}>
                                            {duration}
                                        </h1>
                                    </div>
                                    <div className="w-0 xl:w-full">
                                        {/* <img alt="" src={"/img/ImgLines/line4.png"} /> */}
                                    </div>
                                    <div className="col-span-full mx-3">
                                        <img className={"MainBox-box-image"} src={boxData.imageUrl} alt="" />
                                    </div>
                                </div>
                                {weHaveAllPieces && (
                                    <div className={"MainBox-random-button-2 mb-20 flex flex-col"}>
                                        <div
                                            id="random-btn"
                                            onClick={() => {
                                                // console.log("isWebSocketConnected", isWebSocketConnected);
                                                // handleOpenBox();
                                            }}
                                        >
                                            <button className="flex uppercase text-lg items-center justify-center py-4 text-base font-bold text-[#040403] transition duration-300 ease-in-out rounded-[25px] px-9 hover:shadow-signUp bg-signin ">
                                                Claim Prize !!
                                            </button>
                                        </div>
                                        <div className="uppercase text-white congratulations-text">congratulations</div>
                                    </div>
                                )}
                                {user == null && (
                                    <Link id="random-btn" className={"MainBox-random-button-2 mb-20"} to="/login">
                                        <button className="flex items-center justify-center  py-4 text-base font-medium text-[#734700] transition duration-300 ease-in-out rounded-[25px] px-9 hover:shadow-signUp bg-signin">
                                            Log In for random Jigsaw
                                        </button>
                                    </Link>
                                )}
                                {waitforWebsocketResponse && (
                                    <div className="mx-auto text-center text-white">waiting for your turn...</div>
                                )}
                                {!waitforWebsocketResponse && !weHaveAllPieces && user != null && (
                                    <div id="random-btn" className="mx-auto mb-20">
                                        <div className="MainBox-random-button-2 handle-MainBox-random-button-2  mb-5 ">
                                            <button
                                                onClick={() => {
                                                    console.log("isWebSocketConnected", socketConnected.current);
                                                    openBox();
                                                }}
                                                className="flex items-center justify-center  py-4 text-base font-medium text-[#734700] transition duration-300 ease-in-out rounded-[25px] px-9 hover:shadow-signUp bg-signin"
                                            >
                                                ${boxData.productPrice} / PIECE
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setShowBurnPopup(true);
                                                }}
                                                className="flex items-center justify-center  py-4 text-base font-medium text-[#734700] transition duration-300 ease-in-out rounded-[25px] px-9 hover:shadow-signUp bg-signin"
                                            >
                                                FREE PIECE
                                            </button>
                                        </div>
                                        <div
                                            className="text-gray-500 text-center underline cursor-pointer Random-jigsaw-histories"
                                            onClick={() => {
                                                setShowRandomJigsawHistories(true);
                                            }}
                                        >
                                            Random jigsaw histories
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
            {showRandomJigsawHistories && (
                <ShowRandomJigsawHistories
                    onClosed={() => {
                        setShowRandomJigsawHistories(false);
                    }}
                    boxData={boxData}
                    title={boxData.name}
                ></ShowRandomJigsawHistories>
            )}
        </>
    );
};
