export enum CwalletAppnames {
    CCOMMU = "CCOMMU",
    CWEB3 = "CWEB3",
    CCOINBOT = "CCOINBOT",
}
export enum CCurrency {
    CCOIN = "CCOIN",
    CWALLET = "CWALLET",
    CCOUPON = "CCOUPON",
}

export interface CWallet {
    id: number;
    appName: CwalletAppnames;
    username: string;
}

export interface balanceCWalletRequest  {
    amount: number;
    currency: CCurrency;
}

export type balanceCWalletReponse = Omit<balanceCWalletRequest, "id" | "appName" | "username">;
