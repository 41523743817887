import DataTable, { TableColumn } from "react-data-table-component";
import { IAdminLiveDropHistory, RoundWinnerHistory } from "../../../api/admin";
import { customStyles } from "../AdminTable";

interface DataTableProps {
    data?: RoundWinnerHistory[];
    dateFormater: Intl.DateTimeFormat;
}

const AdminAwardWinner = (props: DataTableProps) => {
    const { data, dateFormater } = props;

    const columns: TableColumn<RoundWinnerHistory>[] = [
        {
            name: <div>Date</div>,
            cell: (row) => <div>{dateFormater.format(new Date(row.win_datetime))}</div>,
        },
        {
            name: <div>Round</div>,
            center: true,
            cell: (row) => <div>{row.round_number}</div>,
        },
        {
            name: <div>Winner</div>,
            center: true,
            cell: (row) => <div>{row.account_win_username}</div>,
        },
        {
            name: <div>ProductId</div>,
            center: true,
            cell: (row) => <div>{row.jigsaw_product_id}</div>,
        },
        {
            name: <div>ProductName</div>,
            center: true,
            cell: (row) => <div>{row.name}</div>,
        },
        {
            name: <div>image</div>,
            center: true,
            cell: (row) => <div>
            {row.imageUrl && (
                <img
                    src={row.imageUrl}
                    style={{
                        objectFit: "contain",
                        alignSelf: "stretch",
                        width: "89px",
                        height: "100px",
                    }}
                />
            )}
        </div>,
        },
    ];

    return (
        <DataTable
            pagination
            noDataComponent={<>No Data Not Found</>}
            columns={columns}
            data={data ?? []}
            customStyles={customStyles}
            className="max-w-[1112px] overflow-x-scroll"
        />
    );
};

export default AdminAwardWinner;
