import moment from "moment";
import number from "../../../helpers/number-format";
import "./style.css";
export interface LiveDropProps {
    itemName: string;
    itemImage: string;
    itemBrand: string;
    itemDescription: string;
    date: Date;
    ownerUsername?: string;
    onOpenDialog?: () => void;
}

export const LiveDrop = ({
    itemName,
    itemImage,
    itemBrand,
    itemDescription,
    date,
    ownerUsername,
    onOpenDialog,
}: LiveDropProps): JSX.Element => {
    return (
        <div className="grid grid-cols-1 gap-2 ">
            <div className={"itembox-desktop-live-drop-item cursor-pointer"} onClick={() => onOpenDialog?.()}>
                <div className={"itembox-desktop-box-label-date"}>{moment(date).fromNow()}</div>
                <div className={"itembox-desktop-live-drop-item-2 "}>
                    <div className="grid grid-cols-3  gap-2 ">
                        <div className="col-span-2 md:col-span-full lg:col-span-2 md:mt-1">
                            <div className={"itembox-desktop-item-live-drop-label "}>
                                <div className={"itembox-desktop-name-item-label"}>{itemName}</div>
                                <div className={"itembox-desktop-frame"}>
                                    <img
                                        className={"itembox-desktop-icon-box"}
                                        src={"/img/ItemBoxDesktop/Vector.png"}
                                        alt=""
                                    />
                                    <div className={"itembox-desktop-box-label "}>
                                        <div className=" text-clip overflow-hidden  ">{itemDescription}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-span-1 md:col-span-full lg:col-span-1">
                            <div className="flex items-center justify-center mx-5 mb-1">
                                <img className={"itembox-desktop-item-live-drop-image"} src={itemImage} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
