// useSound.js
import { useState, useEffect } from "react";

export function useSound(url) {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);

    const toggle: () => void = () => setPlaying(!playing);

    useEffect(() => {
        playing ? audio.play() : audio.pause();
    }, [playing, audio]);

    useEffect(() => {
        audio.addEventListener("ended", () => setPlaying(false));
        return () => {
            audio.removeEventListener("ended", () => setPlaying(false));
        };
    }, [audio]);

    return [playing, toggle];
}
