import { useState } from "react";
import { toast } from "react-toastify";
import { createShipping } from "../../../api/shiping";
import { IAwardWinnerInventoryResponse } from "../../../interfaces/ItemInTheBox";
import { ItemToShip } from "./ItemToShip/ItemToShip";
import "./style.css";
import { useUserContext } from "../../../hooks/auth";
import { useNavigate } from "react-router-dom";
import number from "../../../helpers/number-format";

interface Props {
    onBackwardClick: () => void;
    selectedItems: IAwardWinnerInventoryResponse[];
}

export const ShippingProcessDesktop = (props: Props): JSX.Element => {
    const { reloadUserPoint } = useUserContext();
    const navigate = useNavigate();

    const [receiverName, setReceiverName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [subDistrict, setSubDistrict] = useState<string>("");
    const [district, setDistrict] = useState<string>("");
    const [province, setProvice] = useState<string>("");
    const [postCode, setPostCode] = useState<string>("");
    const [remark, setRemark] = useState<string>("");

    const fee = 0;
    const shippingFeeSummary = props.selectedItems.reduce((prev, curr) => {
        return prev + curr.product.shippingFee;
    }, 0);

    const handleShipAcceptClick = () => {
        createShipping({
            nameShipping: receiverName,
            phoneShipping: phoneNumber,
            addressShipping: address,
            provinceShipping: province,
            districtShipping: district,
            subDistrictShipping: subDistrict,
            postalCodeShipping: postCode,
            shippingFee: shippingFeeSummary,
            remark: remark,
            products: props.selectedItems.map((el) => el.id),
        })
            .then((data) => {
                toast(
                    <div>
                        <h1 className="font-bold text-green-500">Success</h1>
                        <p className="text-xs">Delivery confirm !</p>
                    </div>,
                    {
                        type: "success",
                    }
                );
                reloadUserPoint();
                navigate("/shipping/detail/" + data.id);
            })
            .catch((err) => {
                toast(
                    <div>
                        <h1 className="font-bold text-red-400">Failed</h1>
                        <p className="text-xs">{err.response.data.error.message}</p>
                    </div>,
                    {
                        type: "error",
                    }
                );
            });
    };

    return (
        <div className={"shipping-process-desktop-content-section"}>
            <div className={"shipping-process-desktop-delivery-title"}>
                <div className={"shipping-process-desktop-vecter"}>
                    <div className={"shipping-process-desktop-div"}>
                        <img
                            alt=""
                            className={"shipping-process-desktop-line"}
                            src={"/img/shippingProcess/line-12.png"}
                        />
                        <div className={"shipping-process-desktop-ellipse"} />
                    </div>
                </div>
                <img
                    alt=""
                    className={"shipping-process-desktop-icon-delivery"}
                    src={"/img/shippingProcess/icondelivery.png"}
                />
                <div className={"shipping-process-desktop-title"}>
                    <h1 className={"shipping-process-desktop-label"}>Delivery</h1>
                </div>
                <div className={"shipping-process-desktop-overlap-wrapper"}>
                    <div className={"shipping-process-desktop-div"}>
                        <img
                            alt=""
                            className={"shipping-process-desktop-line-12"}
                            src={"/img/shippingProcess/line-12-1.png"}
                        />
                        <div className={"shipping-process-desktop-ellipse"} />
                    </div>
                </div>
            </div>
            <div className={"shipping-process-desktop-body-section"}>
                <div className={"shipping-process-desktop-address-detail"}>
                    <div className={"shipping-process-desktop-address-detail-2"}>
                        <div className={"shipping-process-desktop-title-address-detail-label"}>
                            รายละเอียดที่อยู่จัดส่ง
                        </div>
                        <div className={"shipping-process-desktop-address-detail-form"}>
                            <div className={"shipping-process-desktop-div-2"}>
                                <div className={"shipping-process-desktop-text-wrapper"}>First name - Last name :</div>
                                <input
                                    title="First name - Last name"
                                    value={receiverName}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        setReceiverName(value);
                                    }}
                                    type={"text"}
                                    className={"shipping-process-desktop-input"}
                                />
                            </div>
                            <div className={"shipping-process-desktop-div-2"}>
                                <div className={"shipping-process-desktop-text-wrapper"}>Phone number :</div>
                                <input
                                    title="Phone number"
                                    value={phoneNumber}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        setPhoneNumber(value);
                                    }}
                                    type={"text"}
                                    className={"shipping-process-desktop-input"}
                                />
                            </div>
                            <div className={"shipping-process-desktop-div-2"}>
                                <div className={"shipping-process-desktop-text-wrapper"}>Address :</div>
                                <textarea
                                    title="Address"
                                    value={address}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        setAddress(value);
                                    }}
                                    className={"shipping-process-desktop-input-2"}
                                />
                            </div>
                            <div className={"shipping-process-desktop-address-detail-3"}>
                                <div className={"shipping-process-desktop-div-2"}>
                                    <div className={"shipping-process-desktop-text-wrapper"}>Sub district :</div>
                                    <input
                                        title="Sub district"
                                        value={subDistrict}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setSubDistrict(value);
                                        }}
                                        type={"text"}
                                        className={"shipping-process-desktop-input-3"}
                                    />
                                </div>
                                <div className={"shipping-process-desktop-div-2"}>
                                    <div className={"shipping-process-desktop-text-wrapper"}>District :</div>
                                    <input
                                        title="District"
                                        value={district}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setDistrict(value);
                                        }}
                                        type={"text"}
                                        className={"shipping-process-desktop-input-3"}
                                    />
                                </div>
                            </div>
                            <div className={"shipping-process-desktop-address-detail-3"}>
                                <div className={"shipping-process-desktop-div-2"}>
                                    <div className={"shipping-process-desktop-text-wrapper"}>Province :</div>
                                    <input
                                        title="province"
                                        value={province}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setProvice(value);
                                        }}
                                        type={"text"}
                                        className={"shipping-process-desktop-input-3"}
                                    />
                                </div>
                                <div className={"shipping-process-desktop-div-2"}>
                                    <div className={"shipping-process-desktop-text-wrapper"}>Postcode :</div>
                                    <input
                                        title="Postcode"
                                        value={postCode}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setPostCode(value);
                                        }}
                                        type={"text"}
                                        className={"shipping-process-desktop-input-3"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"shipping-process-desktop-button-group"}>
                            <div className={"shipping-process-desktop-backward-button"} onClick={props.onBackwardClick}>
                                <img
                                    className={"shipping-process-desktop-iconsax-linear-arrowleft"}
                                    src={"/img/shippingProcess/iconsax-linear-arrowleft.png"}
                                />
                                <div className={"shipping-process-desktop-text-wrapper-2"}>Back</div>
                            </div>
                            <div
                                className={"shipping-process-desktop-pay-shipping-button"}
                                onClick={handleShipAcceptClick}
                            >
                                <div className={"shipping-process-desktop-text-wrapper-3"}>Confirm</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"shipping-process-desktop-dlivery-product-list"}>
                    <div className={"shipping-process-desktop-dlivery-product-list-2"}>
                        <div className={"shipping-process-desktop-dlivery-product-list-3"}>
                            <div className={"shipping-process-desktop-dlivery-product-list-label"}>
                                Items to be shipped {/* ({props.selectedItems.length} List) */}
                            </div>
                            <div className={"shipping-process-desktop-list"}>
                                {props.selectedItems.map((item, index) => {
                                    return <ItemToShip key={index} itemInTheBox={item.product} />;
                                })}
                            </div>
                        </div>
                        <img
                            alt=""
                            className={"shipping-process-desktop-line-18"}
                            src={"/img/shippingProcess/line-18.png"}
                        />
                        <div className={"shipping-process-desktop-frame"}>
                            <div className={"shipping-process-desktop-frame-175"}>
                                <div className={"shipping-process-desktop-text-wrapper-8"}>Fee</div>
                                <div className={"shipping-process-desktop-frame-2"}>
                                    <div className={"shipping-process-desktop-text-wrapper-9"}>
                                        {number.format(fee)}
                                    </div>
                                    <div className={"shipping-process-desktop-text-wrapper-10"}>USD</div>
                                </div>
                            </div>
                            <div className={"shipping-process-desktop-frame-176"}>
                                <div className={"shipping-process-desktop-text-wrapper-11"}>Shipping fee</div>
                                <div className={"shipping-process-desktop-frame-2"}>
                                    <div className={"shipping-process-desktop-text-wrapper-12"}>
                                        {number.format(shippingFeeSummary)}
                                    </div>
                                    <div className={"shipping-process-desktop-text-wrapper-10"}>USD</div>
                                </div>
                            </div>
                            <div className={"shipping-process-desktop-frame-177"}>
                                <div className={"shipping-process-desktop-text-wrapper-13"}>Total</div>
                                <div className={"shipping-process-desktop-frame-174"}>
                                    <div className={"shipping-process-desktop-text-wrapper-12"}>
                                        {number.format(shippingFeeSummary + fee)}
                                    </div>
                                    <div className={"shipping-process-desktop-text-wrapper-10"}>USD</div>
                                </div>
                            </div>
                            <div className={"shipping-process-desktop-remark"}>
                                <div className={"shipping-process-desktop-text-wrapper-14"}>
                                    Remark ex. size, color, etc.
                                </div>
                                <textarea
                                    title="remark"
                                    value={remark}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        setRemark(value);
                                    }}
                                    className={"shipping-process-desktop-input-4"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
