// @ts-nocheck
import {
    GoogleAuthProvider,
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signInWithRedirect,
    signOut,
} from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { AuthService } from "../api/auth";
import { fetchUserPoint } from "../api/user";
import httpClient from "../client/client";
import { User } from "../interfaces/User";
import { auth } from "./firebase";
import { toast } from "react-toastify";
import axios from "axios";

enum AuthName {
    signInEmailAndPassword = "signInEmailAndPassword",
    signUpEmailAndPassword = "signUpEmailAndPassword",
    signInGoogle = "signInGoogle",
    signUpGoogle = "signUpGoogle",
}

interface ISTSTokenManager {
    refreshToken: string;
    accessToken: string;
    expirationTime: number;
}

interface IUserTokenData {
    user: User;
    stsTokenManager: ISTSTokenManager;
}

interface ISignInEmailPassword {
    emailOrUsername: string;
    password: string;
}
export enum ECCoinAppName {
    CCOMMU = "CCOMMU",
    CWEB3 = "CWEB3",
    CCOINBOT = "CCOINBOT",
}
interface ISignInCCoin {
    appName: ECCoinAppName;
    username: string;
    password: string;
}

interface ISignUpEmailPassword {
    username: string;
    email: string;
    password: string;
    passwordCheck: string;
    parentInviteCode?: string;
}

interface IUserContext {
    user: User | null;
    isAdmin: boolean;
    stsTokenManager: ISTSTokenManager | null;
    signInEmailAndPassword: (data: ISignInEmailPassword) => Promise<void>;
    signUpEmailAndPassword: (data: ISignUpEmailPassword) => Promise<void>;
    signInGoogle: () => void;
    signUpGoogle: (inviteCode?: string) => void;
    logout: () => void;
    reloadUserPoint: () => void;
    signInWithCcoin: (data: ISignInCCoin) => Promise<void>;
}

const UserContext = createContext<IUserContext>({
    user: null,
    isAdmin: false,
    stsTokenManager: null,
    signInEmailAndPassword: () => Promise.resolve(),
    signUpEmailAndPassword: () => Promise.resolve(),
    signInWithCcoin: () => Promise.resolve(),
    signInGoogle: () => {},
    signUpGoogle: () => {},
    logout: () => {},
    reloadUserPoint: () => {},
});

const CCOIN_LOCAL_STORAGE = "ccoin_user";
export const UserContextWrapper = ({ children }: { children: JSX.Element }) => {
    const [user, setUser] = useState<User | null>(null);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    const [stsTokenManager, setStsTokenManager] = useState<{
        refreshToken: string;
        accessToken: string;
        expirationTime: number;
    } | null>(null);

    const signInWithCcoin = async (data?: ISignInCCoin) => {
        try {
            if (data) {
                let response = await AuthService.signInWithCcoin(data);
                if (response.accessToken) {
                    localStorage.setItem(CCOIN_LOCAL_STORAGE, JSON.stringify(response));
                }
                await AuthService.getAccountInfo({
                    idToken: response.accessToken,
                }).then((account) => {
                    handlerUser({
                        user: account,
                        stsTokenManager: {
                            refreshToken: response.accessToken,
                            accessToken: response.accessToken,
                            expirationTime: Number(1000000),
                        },
                    });
                });
                return;
            } else {
                const user = JSON.parse(localStorage.getItem(CCOIN_LOCAL_STORAGE)?.toString() ?? "{}");
                if (!user.accessToken) {
                    // logout();
                    return;
                }
                await AuthService.getAccountInfo({
                    idToken: user.accessToken,
                })
                    .then((account) => {
                        handlerUser({
                            user: account,
                            stsTokenManager: {
                                refreshToken: user.accessToken,
                                accessToken: user.accessToken,
                                expirationTime: Number(100000000),
                            },
                        });
                    })
                    .catch((err) => {
                        localStorage.removeItem("AUTH_NAME");
                        localStorage.removeItem(CCOIN_LOCAL_STORAGE);
                        if (err?.response?.status == 401) {
                            return handleError({
                                title: "Error",
                                message: "Account verification failed",
                            });
                        } else if (err?.response?.status == 404) {
                            return handleError({
                                title: "Error",
                                message: "Account not found",
                            });
                        } else {
                            return handleError({
                                title: "Error",
                                message: "Something went wrong. Please try again later.",
                            });
                        }
                    });
            }
        } catch (error: any) {
            if (axios.isAxiosError(error) && error.response?.data.message) {
                return handleError({
                    title: "Error",
                    message: error.response.data.message,
                });
            
            }
            console.error(error);
            return handleError({
                title: "Error",
                message: "Something went wrong. Please try again later.",
            });
        }
    };

    useEffect(() => {
        signInWithCcoin();
    }, []);

    const handlerUser = (data: IUserTokenData) => {
        console.log("handlerUser", data);
        setHttpHeader(data.stsTokenManager.accessToken);
        setStsTokenManager({ ...data.stsTokenManager });
        setIsAdmin(data.user.role === "ADMIN");
        setUser({ ...data.user });
    };

    const handleError = (err: { title: string; message: string }) => {
        toast(
            <div>
                <h1 className="font-bold text-red-400">{err.title}</h1>
                <p className="text-xs">{err.message}</p>
            </div>,
            {
                type: "error",
            }
        );
    };

    const setHttpHeader = (accessToken: string) => {
        httpClient.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    };

    const reloadUserPoint = () => {
        fetchUserPoint().then((userPoints) => {
            if (!user) return;
            setUser({
                ...user,
                point: userPoints.point,
                ccoinbotPoint: userPoints.ccoinbotPoint,
                ccommuPoint: userPoints.ccommuPoint,
                cweb3Point: userPoints.cweb3Point,
            });
        });
    };

    const signInEmailAndPassword = async (data: ISignInEmailPassword) => {
        localStorage.setItem("AUTH_NAME", AuthName.signInEmailAndPassword);
        try {
            await signInWithEmailAndPassword(auth, data.emailOrUsername, data.password);
        } catch (error: any) {
            console.error(error);
            let errorCode = error?.code;
            let errorMessage = error?.message;

            if (errorCode === "auth/user-not-found") {
                return handleError({
                    title: "Error",
                    message: "Account not found",
                });
            } else if (errorCode === "auth/wrong-password") {
                return handleError({
                    title: "Error",
                    message: "Password is incorrect",
                });
            } else if (errorCode === "auth/invalid-email") {
                console.log(error);

                return handleError({
                    title: "Error",
                    message: "Email is incorrect",
                });
            } else {
                return handleError({
                    title: "Error",
                    message: "Email is incorrect",
                });
            }
        }
    };

    const signUpEmailAndPassword = async (data: ISignUpEmailPassword) => {
        localStorage.setItem("AUTH_NAME", AuthName.signUpEmailAndPassword);
        localStorage.setItem("USERNAME", data.username);
        localStorage.setItem("INVITE_CODE", data.parentInviteCode ?? "");

        try {
            if (data.password.trim() != data.passwordCheck.trim()) {
                return handleError({
                    title: "Error",
                    message: "Re-enter password does not match",
                });
            }
            await createUserWithEmailAndPassword(auth, data.email, data.password);
        } catch (error: any) {
            let errorCode = error?.code;
            let errorMessage = error?.message;

            if (errorCode === "auth/weak-password") {
                return handleError({
                    title: "Error",
                    message: "รหัสผ่านควรมีอย่างน้อย 6 ตัวอักษร",
                });
            } else if (errorCode === "auth/email-already-in-use") {
                return handleError({
                    title: "Error",
                    message: "This email address is already in use by another account.",
                });
            } else if (errorCode === "auth/invalid-email") {
                return handleError({
                    title: "Error",
                    message: "Email is incorrect",
                });
            } else if (errorCode === "auth/missing-password") {
                return handleError({
                    title: "Error",
                    message: "Password is incorrect",
                });
            } else {
                console.log(error);
                return handleError({
                    title: "Error",
                    message: errorMessage,
                });
            }
        }
    };

    const signInGoogle = () => {
        localStorage.setItem("AUTH_NAME", AuthName.signInGoogle);
        const provider = new GoogleAuthProvider();
        signInWithRedirect(auth, provider);
    };

    const signUpGoogle = (inviteCode?: string) => {
        localStorage.setItem("INVITE_CODE", inviteCode ?? "");
        localStorage.setItem("AUTH_NAME", AuthName.signUpGoogle);

        const provider = new GoogleAuthProvider();
        signInWithRedirect(auth, provider);
    };


    const logout = async () => {
        try {
            // await signOut(auth);
            localStorage.removeItem(CCOIN_LOCAL_STORAGE);
            setUser(null);
            setStsTokenManager(null);
            window.location.href = "/";
        } catch (error) {
            console.error(error);
        }
    };

    const shareState = {
        user,
        isAdmin,
        stsTokenManager,
        signInWithCcoin,
        signInEmailAndPassword,
        signUpEmailAndPassword,
        signInGoogle,
        signUpGoogle,
        logout,
        reloadUserPoint,
    };

    return <UserContext.Provider value={shareState}>{children}</UserContext.Provider>;
};

export const useUserContext = () => {
    return useContext(UserContext);
};
