import "./style.css";
interface IJigsawInventoryPieceCard {
    name: string;
    round: number;
    image: string;
}
const JigsawInventoryPieceCard = (props: IJigsawInventoryPieceCard): JSX.Element => {
    return (
        <div className="jigsaw-inventory-card">
            <div className="jigsaw-inventory-card-round">
                <p>ROUND {props.round}</p>
            </div>
            <div className="jigsaw-inventory-card-image">
                <img src={props.image} alt="" />
            </div>
            <div className="jigsaw-inventory-card-name">{props.name}</div>
        </div>
    );
};

export default JigsawInventoryPieceCard;
