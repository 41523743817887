import React, { useState, useEffect, useMemo } from "react";
import JigsawPieceComponent from "../Piece";
import "./style.css";
import useJigsaw, { Inventory } from "../../../hooks/useJigsaw";
import { JigsawPiece, JigsawPieceGroupCount } from "../../../interfaces/Box";
import { useMediaQuery } from "react-responsive";

export interface JigsawPieceBoard {
    x: number;
    y: number;
    dataUrl: string;
    name?: string;
    width: number;
    height: number;
    isReceived: boolean;
    num: number;
    isModal?: boolean;
}
interface JigsawBoardProps {
    x?: number;
    y?: number;
    src?: string;
    bg?: string;
    data: JigsawPieceGroupCount[];
    isModal?: boolean;
}
// get screen width
const getScreenWidth = () => {
    let targetWidth = window.innerWidth * 0.7;
    if (targetWidth > 1000) return 1000;
    if (targetWidth < 380) return 380;
    return targetWidth;
};
// get screen height
const getScreenHeight = () => {
    return window.innerHeight;
};
export default function JigsawBoard({ x = 10, y = 4, src, bg = "#010100", data, isModal }: JigsawBoardProps) {
    if (src === undefined) {
        console.error("src is undefined");
        return <></>;
    }
    const { sumWidth, sumHeight, jigsawPieces, reloadImage } = useJigsaw(x, y, src, data);
    const [imageWidthRatio, setImageWidthRatio] = useState(0);

    useEffect(() => {
        reloadImage();
    }, [data]);

    useEffect(() => {
        setImageWidthRatio(getScreenWidth() / sumWidth);
    }, [sumWidth]);

    const isIPad = useMediaQuery({
        query: "(max-width: 768px)",
    });

    const isIPadXL = useMediaQuery({
        query: "(max-width: 1024px)",
    });

    const handleScreenSize = useMemo(() => {
        if (isModal) {
            if (isIPad) {
                return "111%";
            }

            if (isIPadXL) {
                return "90%";
            }

            return "60%";
        }
        return sumWidth * imageWidthRatio + 20;
    }, [isModal, isIPad, isIPadXL, sumWidth, imageWidthRatio]);

    return (
        <>
            <div className="mx-auto jigsaw-board-position">
                <div
                    className="w-full rounded-lg flex flex-wrap relative z-10"
                    style={{
                        width: handleScreenSize,
                        // width: isModal ? "60%" : sumWidth * imageWidthRatio + 20,
                        height: isModal ? "" : sumHeight * imageWidthRatio + 20,
                        background: isModal ? "" : bg,
                        justifyContent: isModal ? "center" : "",
                        border: isModal ? "" : "9px solid #050503",
                        left: isIPad ? "-20px" : "",
                    }}
                >
                    {jigsawPieces.map((pieces, piecesIndex) =>
                        pieces.map((piece, pieceIndex) => (
                            <JigsawPieceComponent
                                key={`Jigsaw${piecesIndex}${pieceIndex}`}
                                x={piece.x}
                                y={piece.y}
                                dataUrl={piece.dataUrl}
                                name={piece.name}
                                width={isModal ? 100 : piece.width * imageWidthRatio}
                                height={isModal ? 100 : piece.height * imageWidthRatio}
                                isReceived={piece.isReceived}
                                num={piece.num}
                                isModal={isModal}
                            />
                        ))
                    )}
                </div>
            </div>
        </>
    );
}
