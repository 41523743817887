import React, { useCallback } from "react";
import DataTable, { TableColumn, TableStyles } from "react-data-table-component";
import { BoxForAdmin, removeBoxByBoxIdForAdmin } from "../../../api/admin";
import number from "../../../helpers/number-format";
import { AdminButton } from "../../AdminButton";
import { toast } from "react-toastify";
import { useDialogContext } from "../../../hooks/dialog";
import { customStyles } from "../AdminTable";

interface DataTableProps {
    data?: BoxForAdmin[];
}

const AdminMysteryBoxsTable = (props: DataTableProps) => {
    const { data } = props;
    const { showLoading, closeLoading } = useDialogContext();

    const columns: TableColumn<BoxForAdmin>[] = [
        {
            name: <div>Id</div>,
            width: "50px",
            cell: (row) => <div>{row.id}</div>,
        },
        {
            name: <div>Name</div>,
            width: "125px",
            cell: (row) => <div>{row.name}</div>,
        },
        {
            name: <div>Category</div>,
            width: "100px",
            center: true,
            cell: (row) => <div>{row.categoryId}</div>,
        },
        {
            name: <div>Image</div>,
            center: true,
            cell: (row) => (
                <div>
                    {row.imageUrl && (
                        <img
                            src={row.imageUrl}
                            style={{
                                objectFit: "contain",
                                alignSelf: "stretch",
                                width: "89px",
                                height: "100px",
                            }}
                        />
                    )}
                </div>
            ),
        },
        {
            name: <div>Price</div>,
            width: "100px",
            center: true,
            cell: (row) => <div>{number.format(row.productPrice)}</div>,
        },
        {
            name: <div>Jigsaw (piece)</div>,
            width: "100px",
            center: true,
            cell: (row) => <div>{row.id}</div>,
        },
        {
            name: <div>Margin</div>,
            width: "100px",
            cell: (row) => <div>{number.format(row.margin)}</div>,
        },
        {
            name: <div>Margin breakpoints</div>,
            width: "100px",
            center: true,
            cell: (row) => <div>{number.format(row.marginBreakPoint)}</div>,
        },
        {
            name: <div>ItemRemain</div>,
            width: "100px",
            center: true,
            cell: (row) => <div>{number.format(row.itemRemain)}</div>,
        },
        {
            name: <div>Action</div>,
            cell: (row) => {
                return (
                    <div className="flex flex-col gap-2">
                        <AdminButton
                            main="secondary"
                            onClick={() => {
                                window.location.href = `/admin/box/${row.id}`;
                            }}
                        >
                            Manage
                        </AdminButton>
                        <AdminButton
                            main="default"
                            onClick={() => {
                                showLoading();
                                removeBoxByBoxIdForAdmin(Number(row.id))
                                    .then(() => {
                                        toast(
                                            <div>
                                                <h1 className="font-bold text-green-400">Success</h1>
                                                <p className="text-xs">Remove box success</p>
                                            </div>,
                                            {
                                                type: "success",
                                            }
                                        );
                                        window.location.href = `/admin/boxs`;
                                    })
                                    .catch(() => {
                                        toast(
                                            <div>
                                                <h1 className="font-bold text-red-400">Error</h1>
                                                <p className="text-xs">Remove box error please try again</p>
                                            </div>,
                                            {
                                                type: "error",
                                            }
                                        );
                                    })
                                    .finally(() => {
                                        closeLoading();
                                    });
                            }}
                        >
                            Remove
                        </AdminButton>
                    </div>
                );
            },
        },
    ];

    return (
        <DataTable
            pagination
            noDataComponent={<>No Data Not Found</>}
            columns={columns}
            data={data ?? []}
            customStyles={customStyles}
            className="max-w-[1112px] overflow-x-scroll"
        />
    );
};

export default AdminMysteryBoxsTable;
