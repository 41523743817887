import clsx from "clsx";

interface Props {
    children?: JSX.Element | string;
    main: "default" | "primary" | "secondary" | "delete";
    onClick?: () => void;
    type?: "button" | "submit" | "reset";
    className?: string;
}

export const AdminButton = ({ children, main, onClick, type, className }: Props): JSX.Element => {
    const renderButton = (m: "default" | "main" | "primary" | "secondary" | "delete") => {
        switch (m) {
            case "primary":
                return (
                    <>
                        <button
                            onClick={onClick}
                            className={clsx(
                                "flex text-md items-center justify-center w-[100px] h-[2rem] text-[#ffffff] bg-[#2B68D5] rounded-md  hover:bg-opacity-80",
                                className
                            )}
                            type={type}
                        >
                            {children}
                        </button>
                    </>
                );
            case "secondary":
                return (
                    <>
                        <button
                            onClick={onClick}
                            className={clsx(
                                "flex text-md items-center justify-center w-[100px] h-[2rem] text-[#2B68D5] bg-[#ffffff] border border-[#D9DBE0] rounded-md  hover:bg-opacity-80",
                                className
                            )}
                            type={type}
                        >
                            {children}
                        </button>
                    </>
                );
            case "main":
                return (
                    <>
                        <button
                            onClick={onClick}
                            className={clsx(
                                "flex text-md items-center justify-center w-[100px] h-[2rem] text-[#2B68D5] bg-[#EDF3FF] rounded-md  hover:bg-opacity-80",
                                className
                            )}
                            type={type}
                        >
                            {children}
                        </button>
                    </>
                );
            default:
                return (
                    <>
                        <button
                            onClick={onClick}
                            className={clsx(
                                "flex text-md items-center justify-center w-[100px] h-[2rem] text-[#2B68D5] bg-[#EDF3FF] rounded-md  hover:bg-[#9abbf4]",
                                className
                            )}
                            type={type}
                        >
                            {children}
                        </button>
                    </>
                );
        }
    };

    return <>{renderButton(main)}</>;
};
