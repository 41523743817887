import axios from "axios";

const API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;

export const requestResetPassword = (email: string) => {
    return axios.post(`https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${API_KEY}`, {
        requestType: "PASSWORD_RESET",
        email: email,
    });
};
