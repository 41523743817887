import React from "react";
import "./style.css";
export const Forbidden = () => {
    return (
        <div className="forbidden-screen">
            <h1>403</h1>
            <p>Page Forbidden</p>
        </div>
    );
};
