const numberFormatter = new Intl.NumberFormat("th");

const format = (num: number): string => {
    return numberFormatter.format(num);
};

const number = {
    format,
};

export default number;
