import { useEffect, useState } from "react";
import { AccountForAdmin, getAccountByAccountIdForAdmin, updateAccountByAccountIdForAdmin } from "../../../api/admin";
import { useUserContext } from "../../../hooks/auth";
import { useDialogContext } from "../../../hooks/dialog";
import { AdminHeader, AdminPanel } from "../../../components/Layout/AdminPanel";
import { AdminPageEnum, useAdminSystemContext } from "../../../hooks/admin";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AdminButton } from "../../../components/AdminButton";
import { AccountRoleEnum } from "../../../enums/account";

export const AccountAdminPage = () => {
    const { accountId } = useParams();
    const { isAdmin, user } = useUserContext();
    const { handlerActiveAdminPage } = useAdminSystemContext();
    const { showLoading, closeLoading } = useDialogContext();

    const numberFormat = new Intl.NumberFormat("th", {
        minimumFractionDigits: 2,
    });
    const dateFormater = new Intl.DateTimeFormat("th", { dateStyle: "medium", timeZone: "Asia/Bangkok" });
    const [account, setAccount] = useState<AccountForAdmin>({
        id: 0,
        myInviteCodeId: 0,
        parentInviteCodeId: 0,
        firebaseUid: "",
        firebaseProviderId: "google.com",
        role: AccountRoleEnum.MEMBER,
        email: "",
        displayName: "",
        username: "",
        firstName: "",
        lastName: "",
        banned: new Date(),
        bannedReason: "",
        point: 0,
        level: 0,
        exp: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
    });

    const handleError = (err: { title: string; message: string }) => {
        toast(
            <div>
                <h1 className="font-bold text-red-400">{err.title}</h1>
                <p className="text-xs">{err.message}</p>
            </div>,
            {
                type: "error",
            },
        );
    };

    const [isEditData, setIsEditData] = useState(false);

    useEffect(() => {
        showLoading();
        if (user) {
            if (!isAdmin) {
                window.location.href = "/403";
            } else {
                if (accountId && !isNaN(Number(accountId))) {
                    getAccountByAccountIdForAdmin(Number(accountId))
                        .then(setAccount)
                        .catch((error) => {
                            handleError({
                                title: "Something went wrong!",
                                message: "Please try again",
                            });
                        })
                        .finally(() => {
                            closeLoading();
                        });
                } else {
                    closeLoading();
                    handleError({
                        title: "Something went wrong!",
                        message: "Please try again",
                    });
                }
            }
        }
    }, [user]);

    handlerActiveAdminPage(AdminPageEnum.accounts);
    return (
        <>
            <AdminPanel>
                <>
                    {/* Header */}
                    <AdminHeader
                        pageName="Dashboard"
                        historyList={[
                            {
                                name: "Accounts",
                                route: "/admin/accounts",
                            },
                            {
                                name: "Account",
                                route: `/admin/account/${accountId}`,
                            },
                        ]}
                    />
                    {/* Table Data */}
                    <div className="flex flex-col p-4 m-10 max-lg:m-0 max-lg:pt-10 bg-white rounded-lg shadow">
                        <div className="flex flex-row justify-between">
                            <div className="flex flex-col">
                                <h1 className="text-lg font-bold">Account</h1>
                                <p className="text-sm text-gray-400">Account infomations</p>
                            </div>

                            <div className="flex">
                                {isEditData ? (
                                    <div className="flex flex-row gap-5 justify-center items-center">
                                        <AdminButton
                                            main="secondary"
                                            onClick={() => {
                                                setIsEditData(false);
                                                getAccountByAccountIdForAdmin(Number(accountId))
                                                    .then(setAccount)
                                                    .catch((error) => {
                                                        handleError({
                                                            title: "Something went wrong!",
                                                            message: "Please try again",
                                                        });
                                                    });
                                            }}
                                        >
                                            Cancel
                                        </AdminButton>

                                        <AdminButton
                                            main="primary"
                                            onClick={() => {
                                                setIsEditData(false);
                                                showLoading();
                                                updateAccountByAccountIdForAdmin(Number(accountId), account)
                                                    .then(setAccount)
                                                    .catch((error) => {
                                                        handleError({
                                                            title: "Something went wrong!",
                                                            message: "Please try again",
                                                        });
                                                    })
                                                    .finally(() => {
                                                        closeLoading();
                                                    });
                                            }}
                                        >
                                            Save
                                        </AdminButton>
                                    </div>
                                ) : (
                                    <div className="flex justify-center items-center">
                                        <AdminButton main="default" onClick={() => setIsEditData(true)}>
                                            Edit
                                        </AdminButton>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="flex flex-col gap-5 p-5">
                            {/* id */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>id</h1>
                                </div>
                                <div className="flex text-[#464747]">{account?.id}</div>
                            </div>

                            {/* myInviteCodeId */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>myInviteCodeId</h1>
                                </div>
                                <div className="flex text-[#464747]">{account?.myInviteCodeId}</div>
                            </div>

                            {/* parentInviteCodeId */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>parentInviteCodeId</h1>
                                </div>
                                <div className="flex text-[#464747]">{account?.parentInviteCodeId ?? "null"}</div>
                            </div>

                            {/* firebaseUid */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>firebaseUid</h1>
                                </div>
                                <div className="flex text-[#464747]">{account?.firebaseUid}</div>
                            </div>

                            {/* firebaseProviderId */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>firebaseProviderId</h1>
                                </div>
                                <div className="flex text-[#464747]">{account?.firebaseProviderId}</div>
                            </div>

                            {/* role */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>role</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <select
                                            id="account-select-role"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-md focus:ring-gray-400 focus:border-gray-400 w-full"
                                            onChange={(e) => {
                                                setAccount({
                                                    ...account,
                                                    role: e.target.value as AccountRoleEnum,
                                                });
                                            }}
                                        >
                                            <option
                                                selected={account.role == AccountRoleEnum.MEMBER}
                                                value={AccountRoleEnum.MEMBER}
                                            >
                                                {AccountRoleEnum.MEMBER}
                                            </option>
                                            <option
                                                selected={account.role == AccountRoleEnum.ADMIN}
                                                value={AccountRoleEnum.ADMIN}
                                            >
                                                {AccountRoleEnum.ADMIN}
                                            </option>
                                        </select>
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">{account?.role}</div>
                                )}
                            </div>

                            {/* email */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>email</h1>
                                </div>
                                <div className="flex text-[#464747]">{account?.email}</div>
                            </div>

                            {/* displayName */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>displayName</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={account?.displayName}
                                            onChange={(e) => {
                                                setAccount({
                                                    ...account,
                                                    displayName: e.target.value,
                                                });
                                            }}
                                            type="text"
                                            id="account-input-displayName"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="displayName"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">{account?.displayName}</div>
                                )}
                            </div>

                            {/* username */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>username</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={account?.username}
                                            onChange={(e) => {
                                                setAccount({
                                                    ...account,
                                                    username: e.target.value,
                                                });
                                            }}
                                            type="text"
                                            id="account-input-username"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="username"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">{account?.username}</div>
                                )}
                            </div>

                            {/* firstName */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>firstName</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={account?.firstName}
                                            onChange={(e) => {
                                                setAccount({
                                                    ...account,
                                                    firstName: e.target.value,
                                                });
                                            }}
                                            type="text"
                                            id="account-input-firstName"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="firstName"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">
                                        {account?.firstName != "" ? account?.firstName : "null"}
                                    </div>
                                )}
                            </div>

                            {/* lastName */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>lastName</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={account?.lastName}
                                            onChange={(e) => {
                                                setAccount({
                                                    ...account,
                                                    lastName: e.target.value,
                                                });
                                            }}
                                            type="text"
                                            id="account-input-lastName"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="lastName"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">
                                        {account?.lastName != "" ? account?.lastName : "null"}
                                    </div>
                                )}
                            </div>

                            {/* banned */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>banned</h1>
                                </div>
                                <div className="flex text-[#464747]">
                                    {account.banned ? dateFormater.format(new Date(account.banned)) : "null"}
                                </div>
                            </div>

                            {/* bannedReason */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>bannedReason</h1>
                                </div>
                                <div className="flex text-[#464747]">{account?.bannedReason ?? "null"}</div>
                            </div>

                            {/* point */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>point</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={account.point.toString().replace(/^0+/, "")}
                                            onChange={(e) => {
                                                setAccount({
                                                    ...account,
                                                    point: Number(e.target.value),
                                                });
                                            }}
                                            type="number"
                                            id="account-input-point"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="point"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">
                                        {`฿ ${numberFormat.format(account.point)}`}
                                    </div>
                                )}
                            </div>

                            {/* level */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>level</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={account.level.toString().replace(/^0+/, "")}
                                            onChange={(e) => {
                                                setAccount({
                                                    ...account,
                                                    level: Number(e.target.value),
                                                });
                                            }}
                                            type="number"
                                            id="account-input-level"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="level"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">{account.level}</div>
                                )}
                            </div>

                            {/* exp */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>exp</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={account.exp.toString().replace(/^0+/, "")}
                                            onChange={(e) => {
                                                setAccount({
                                                    ...account,
                                                    exp: Number(e.target.value),
                                                });
                                            }}
                                            type="number"
                                            id="account-input-exp"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="exp"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">{account.exp}</div>
                                )}
                            </div>

                            {/* createdAt */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>createdAt</h1>
                                </div>
                                <div className="flex text-[#464747]">
                                    {account.createdAt ? dateFormater.format(new Date(account.createdAt)) : ""}
                                </div>
                            </div>

                            {/* updatedAt */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>updatedAt</h1>
                                </div>
                                <div className="flex text-[#464747]">
                                    {account.updatedAt ? dateFormater.format(new Date(account.updatedAt)) : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </AdminPanel>
        </>
    );
};
