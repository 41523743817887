import { useEffect, useState } from "react";
import { IJigsawHistory, JigsawProduct } from "../../../../interfaces/Box";
import "./style.css";
import { fetchRandomJigsawHistories } from "../../../../api/ItemBox";
import moment from "moment";
import { toast } from "react-toastify";

interface IProps {
    title: string;
    boxData: JigsawProduct;
    onClosed: () => void;
}

export const ShowRandomJigsawHistories = (props: IProps): JSX.Element => {
    const [randomHistories, setRandomHistories] = useState<IJigsawHistory[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        if (props.boxData) {
            fetchRandomJigsawHistories(props.boxData.id)
                .then((res) => {
                    res = res.map((item, index) => {
                        let stack = 1;
                        for (let i = res.length - 1; i > index; i--) {
                            if (item.roundId === res[i].roundId && item.jigsawPiece === res[i].jigsawPiece) {
                                stack++;
                            }
                        }
                        return {
                            ...item,
                            stack: stack,
                        };
                    });
                    setRandomHistories(res);
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);
    return (
        <>
            <div className="history-popup-bg" onClick={props.onClosed}></div>
            <div className="history-popup">
                <div className="text-white">Random Jigsaw Histories</div>
                <div className="history-popup-header">{props.title}</div>
                {/* table */}
                {loading && <div className="text-white">Loading...</div>}
                {!loading && randomHistories.length > 0 && (
                    <div className="table-container">
                        <div className="table-wrapper">
                            <table className="table-auto text-white">
                                <thead>
                                    <tr>
                                        <th>DATE</th>
                                        <th>TIME</th>
                                        <th>ROUND</th>
                                        <th>PIECE</th>
                                        <th>PRICE</th>
                                        <th>STACK</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {randomHistories?.map((item) => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{moment(item.createdAt).format("DD/MM/YYYY")}</td>
                                                <td>{moment(item.createdAt).format("HH:mm")}</td>
                                                <td>{item.roundNumber}</td>
                                                <td>
                                                    {item.jigsawPiece}{" "}
                                                    {item.stack == 1 && <span className="text-[orange]">NEW!!</span>}
                                                </td>
                                                <td>{item.price}</td>
                                                <td className={item.stack === 1 ? "text-[orange]" : ""}>
                                                    {item.stack}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
                {!loading && randomHistories.length === 0 && (
                    <div className="text-gray-500 mt-6">No data histories</div>
                )}
            </div>
        </>
    );
};
