import { useMemo } from "react";
import JigsawBoard from "../../../components/Jigsaw/Board";
import { JigsawPieceGroupCount, JigsawProduct } from "../../../interfaces/Box";
import "./style.css";

interface SearchModalProps {
    onSearch: (value: string) => void;
    value?: string;
    boxData?: JigsawProduct;
    myJigsaw: JigsawPieceGroupCount[];
    searchStatus?: string;
}

const SearchModal = (props: SearchModalProps) => {
    const { onSearch, value, boxData, myJigsaw, searchStatus } = props;
    const validateValue = useMemo(() => !(value && value !== ""), [value]);

    const renderResutlContent = () => {
        if (validateValue) {
            return (
                <div className="m-56">
                    <span className="search-not-found">Please insert username</span>
                </div>
            );
        }

        if (searchStatus === "Not Found") {
            return (
                <div className="my-56">
                    <span className="search-not-found">{value} not founded</span>
                </div>
            );
        }

        if (myJigsaw.length > 0) {
            const jigsawRemaining = myJigsaw.reduce((total, item) => {
                if (item.amount === 0) {
                    return total + 1;
                }
                return total;
            }, 0);
            return (
                <div className="text-center">
                    <div className="mb-2 mt-6 title-dialog-user">{value}</div>
                    <div className="mb-6 subtitle-dialog-jigsaw-pieces">{jigsawRemaining} pieces remaining</div>
                    <JigsawBoard
                        isModal
                        x={boxData?.jigsawSizeX}
                        y={boxData?.jigsawSizeY}
                        src={boxData?.jigsawImageUrl}
                        data={myJigsaw}
                    />
                </div>
            );
        }
    };

    return (
        <>
            <div className="search-dialog inset-0 fixed z-20">
                <div className="mx-12 my-10 flex flex-col items-center">
                    <div className="w-full max-w-[715px] h-full flex flex-col items-center">
                        {" "}
                        <div className="title-dialog">Jigsaw FINDER</div>
                        <>
                            <div
                                title="search-input"
                                className="flex search-input px-[29px] py-[18px] items-center mt-[17px]"
                            >
                                <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 25"
                                        fill="none"
                                    >
                                        <path
                                            d="M22 22.5L20 20.5M11.5 21.5C12.7476 21.5 13.9829 21.2543 15.1355 20.7769C16.2881 20.2994 17.3354 19.5997 18.2175 18.7175C19.0997 17.8354 19.7994 16.7881 20.2769 15.6355C20.7543 14.4829 21 13.2476 21 12C21 10.7524 20.7543 9.5171 20.2769 8.36451C19.7994 7.21191 19.0997 6.16464 18.2175 5.28249C17.3354 4.40033 16.2881 3.70056 15.1355 3.22314C13.9829 2.74572 12.7476 2.5 11.5 2.5C8.98044 2.5 6.56408 3.50089 4.78249 5.28249C3.00089 7.06408 2 9.48044 2 12C2 14.5196 3.00089 16.9359 4.78249 18.7175C6.56408 20.4991 8.98044 21.5 11.5 21.5Z"
                                            stroke="white"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Search By Username"
                                        className="px-6 lg:w-[450px] xl:w-[600px] py-3 text-base text-white bg-transparent border-0 outline-none rounded-md input-search-style "
                                        value={value}
                                        onChange={(e) => onSearch(e.target.value)}
                                    />
                                </div>
                            </div>
                        </>
                        {renderResutlContent()}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SearchModal;
