import { LoadingIcon } from "./LoadingIcon";

const LoadingDialog = () => {
    return (
        <div className="fixed z-50 inset-0 overflow-y-auto" role="dialog" aria-modal="true">
            <div className="flex items-center justify-center min-h-screen p-4 text-center sm:block sm:p-0 opacity-100">
                <div id="bg" className="fixed inset-0 bg-black bg-opacity-70" />
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>
                <div
                    style={{ width: "20rem", maxWidth: "40rem" }}
                    className="h-40 bg-white inline-block rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <LoadingIcon />
                </div>
            </div>
        </div>
    );
};

export default LoadingDialog;
