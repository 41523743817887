import { TableStyles } from "react-data-table-component";

export const customStyles: TableStyles = {
    headCells: {
        style: {
            fontSize: 16,
            color: "#9ca3af",
            padding: "0 4px 0 4px",
        },
    },
    cells: {
        style: {
            fontSize: 14,
            padding: "0 4px 0 4px",
        },
    },
};
