import httpClient from "../client/client";
import { ECCoinAppName } from "../hooks/auth";
import { Account } from "../models/accounts";

export namespace AuthService {
    export const getAccountInfo = async (data: { idToken: string }): Promise<Account.viewT> => {
        return httpClient
            .get("/auth/account", {
                headers: {
                    authorization: `Bearer ${data.idToken}`
                }
            })
            .then(res => res.data);
    };
    export const signInWithCcoin = async (data: {
        username: string;
        password: string;
        appName: ECCoinAppName;
    }): Promise<{ accessToken: string }> => {
        return httpClient
            .post("/auth/login", {
                username: data.username,
                password: data.password,
                appName: data.appName
            })
            .then(res => res.data);
    };

    export const signUpWithEmailAndPassword = async (data: {
        idToken: string;
        username?: string;
        parentInviteCode?: string;
    }): Promise<Account.viewT> => {
        return httpClient
            .post("/auth/signup/email-password", {
                idToken: data.idToken,
                username: data.username,
                parentInviteCode: data.parentInviteCode
            })
            .then(res => res.data);
    };

    export const signUpWithGoogle = async (data: {
        idToken: string;
        username?: string;
        parentInviteCode?: string;
    }): Promise<Account.viewT> => {
        return httpClient
            .post("/auth/signup/google", {
                idToken: data.idToken,
                username: data.username,
                parentInviteCode: data.parentInviteCode
            })
            .then(res => res.data);
    };

    export const verifyInviteCode = async (body: { inviteCode: string }) => {
        return httpClient.post("/auth/verify-invite-code", body).then(res => res.data);
    };
}
