import { useEffect, useState } from "react";
import { useUserContext } from "../../../hooks/auth";
import { AdminHeader, AdminPanel } from "../../../components/Layout/AdminPanel";
import { AdminPageEnum, useAdminSystemContext } from "../../../hooks/admin";
import AdminDeliveryTable from "../../../components/AdminTable/AdminDeliveryTable";
import { ShippingDetail } from "../../../interfaces/Shipping";
import { fetchAllShipping } from "../../../api/shiping";

export const DeliveryAdminPage = () => {
    const { handlerActiveAdminPage } = useAdminSystemContext();

    const dateFormater = new Intl.DateTimeFormat("th", { dateStyle: "medium", timeZone: "Asia/Bangkok" });
    const { stsTokenManager } = useUserContext();

    const [shippingList, setShippingList] = useState<ShippingDetail[]>([]);

    useEffect(() => {
        if (stsTokenManager?.accessToken) {
            fetchAllShipping().then((res) => {
                setShippingList(res);
            });
        }
    }, [stsTokenManager?.accessToken]);

    handlerActiveAdminPage(AdminPageEnum.delivery);
    return (
        <>
            <AdminPanel>
                <>
                    <AdminHeader
                        pageName="Delivery"
                        historyList={[
                            {
                                name: "delivery",
                                route: "/admin/delivery",
                            },
                        ]}
                    />

                    <div className="p-4 m-8 bg-white rounded-lg shadow">
                        <div className="flex justify-between">
                            <div className="flex flex-col">
                                <h1 className="text-lg font-bold">Delivery</h1>
                                <p className="text-sm text-gray-400">ข้อมูลการจัดส่งสินค้า</p>
                            </div>
                        </div>
                        <div>
                            <AdminDeliveryTable data={shippingList} dateFormater={dateFormater} />
                        </div>
                    </div>
                </>
            </AdminPanel>
        </>
    );
};
