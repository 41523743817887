import { useEffect, useState } from "react";
import {
    TopupSummaryForAdmin,
    getTopupSummaryForAdmin,
    TopupHistoryForAdmin,
    getTopupHistoryForAdmin,
} from "../../../api/admin";
import { useUserContext } from "../../../hooks/auth";
import { useDialogContext } from "../../../hooks/dialog";
import { AdminHeader, AdminPanel } from "../../../components/Layout/AdminPanel";
import { AdminPageEnum, useAdminSystemContext } from "../../../hooks/admin";
import AdminTopupHistoryTable from "../../../components/AdminTable/AdminTopupHistoryTable";

export const TopupHistoryAdminPage = () => {
    const { user, isAdmin } = useUserContext();
    const { handlerActiveAdminPage } = useAdminSystemContext();
    const { showLoading, closeLoading } = useDialogContext();

    const numberFormat = new Intl.NumberFormat("th", {
        minimumFractionDigits: 2,
    });
    const dateFormater = new Intl.DateTimeFormat("th", { dateStyle: "medium", timeZone: "Asia/Bangkok" });

    const [topupSummary, setTopupSummary] = useState<TopupSummaryForAdmin>({
        total: 0,
        thisMonth: 0,
        toDay: 0,
    });

    const [topupHistories, setTopupHistories] = useState<TopupHistoryForAdmin[]>([]);

    useEffect(() => {
        showLoading();
        if (user) {
            if (!isAdmin) {
                window.location.href = "/403";
            } else {
                getTopupSummaryForAdmin().then(setTopupSummary);
                getTopupHistoryForAdmin().then(setTopupHistories);
                closeLoading();
            }
        }
    }, [user]);

    handlerActiveAdminPage(AdminPageEnum.topupHistory);
    return (
        <>
            <AdminPanel>
                <>
                    {/* Header */}
                    <AdminHeader
                        pageName="Dashboard"
                        historyList={[
                            {
                                name: "TopupHIstory",
                                route: "/admin/topup-history",
                            },
                        ]}
                    />
                    {/* Table Data */}
                    <div className="flex flex-col p-4 m-10 max-lg:m-0 max-lg:pt-10 bg-white rounded-lg shadow">
                        <h1 className="text-lg font-bold">Topup Summary</h1>
                        <div className="flex mt-4 mb-8 gap-x-8">
                            <div
                                className={`flex flex-col items-center justify-center p-4 rounded-lg min-w-64 gap-y-2 bg-green-100`}
                            >
                                <h1 className={`text-xl font-bold text-green-500`}>
                                    ฿ {numberFormat.format(topupSummary.total)}
                                </h1>
                                <h3 className={`text-sm text-green-500`}>Total</h3>
                            </div>
                            <div
                                className={`flex flex-col items-center justify-center p-4 rounded-lg min-w-64 gap-y-2 bg-blue-100`}
                            >
                                <h1 className={`text-xl font-bold text-blue-500`}>
                                    ฿ {numberFormat.format(topupSummary.thisMonth)}
                                </h1>
                                <h3 className={`text-sm text-blue-500`}>This Month</h3>
                            </div>
                            <div
                                className={`flex flex-col items-center justify-center p-4 rounded-lg min-w-64 gap-y-2 bg-yellow-100`}
                            >
                                <h1 className={`text-xl font-bold text-yellow-500`}>
                                    ฿ {numberFormat.format(topupSummary.toDay)}
                                </h1>
                                <h3 className={`text-sm text-yellow-500`}>Today</h3>
                            </div>
                        </div>

                        <h1 className="text-lg font-bold">Topup History</h1>
                        <p className="text-sm text-gray-400">ประวัติการการเติมเงิน</p>
                        <div>
                            <AdminTopupHistoryTable data={topupHistories} dateFormater={dateFormater} />
                        </div>
                    </div>
                </>
            </AdminPanel>
        </>
    );
};
