import { JigsawProduct, Round } from "../../../../interfaces/Box";
import { ItemInTheBox_Unused } from "../../../../interfaces/ItemInTheBox";
import { Reel } from "./Reel/Reel";
import "./style.css";
interface Props {
    ItemsInTheBox: ItemInTheBox_Unused[];
    ItemResult: ItemInTheBox_Unused;
    boxInfo: JigsawProduct;
    round: Round;
    onSpinFinished: () => void;
}
export const ItemRandomDesktop = ({ onSpinFinished, ItemsInTheBox, ItemResult, boxInfo, round }: Props) => {
    return (
        <div className={"itemrandom-desktop-header-section"}>
            <div className={"itemrandom-desktop-product-ramdom"}>
                <div className={"box-title-box-title"} style={{ left: "unset" }}>
                    <div className={"box-title-info-box"}>
                        <img className={"box-title-box-img"} src={boxInfo.imageUrl} alt="" />
                    </div>
                    <div className={"box-title-div"}>
                        <div className={"box-title-namebox"}>{boxInfo.name}</div>
                        <p className={"box-title-descriptionbox"}>Round {round.round_number}</p>
                    </div>
                </div>
                <Reel onSpinFinished={onSpinFinished} ItemsInTheBox={ItemsInTheBox} ItemResult={ItemResult} />
            </div>
        </div>
    );
};
