import { createContext, useContext, useState } from "react";
import LoadingDialog from "../components/Dialog/LoadingDialog";

export interface IDialogContext {
    loadingDialog: boolean;
    showLoading: () => void;
    closeLoading: () => void;
}

const DialogContext = createContext<IDialogContext>({} as IDialogContext);

export const DialogWrapper = ({ children }: { children?: JSX.Element }) => {
    const [loadingDialog, setLoadingDialog] = useState(false);

    const showLoading = () => setLoadingDialog(true);
    const closeLoading = () => setLoadingDialog(false);

    const sharedState = {
        loadingDialog,
        showLoading,
        closeLoading,
    };

    return (
        <>
            {loadingDialog && <LoadingDialog />}
            <DialogContext.Provider value={sharedState}>{children}</DialogContext.Provider>
        </>
    );
};

export function useDialogContext() {
    return useContext(DialogContext);
}
