import { JigsawPieceBoard } from "../Board";
import "./style.css";

export default function JigsawPieceComponent({
    dataUrl,
    x,
    y,
    name,
    width,
    height,
    isReceived,
    num,
    isModal,
}: JigsawPieceBoard) {
    return (
        <div style={{ position: "relative" }}>
            <span
                style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width,
                    height,
                    zIndex: 1,
                    opacity: isReceived ? "1" : "1",
                }}
                className="text-[#f7f7f7] text-center flex w-100 text-2xl drop-shadow-2xl "
            >
                <div
                    className={isModal ? "mx-auto my-auto p-1 flex flex-col" : "mx-auto my-auto p-2 flex flex-col"}
                    style={{ fontSize: "2rem", background: "#000000c7", borderRadius: isModal ? "6px" : "10px" }}
                >
                    <div className="flex">
                        {isReceived && (
                            <img
                                className={
                                    isModal
                                        ? "relative object-cover h-[20.32px] w-[18.43px]"
                                        : "relative object-cover h-[24.32px] min-w-[25.43px] pt-2"
                                }
                                src="/img/ItemBoxDesktop/Vector.png"
                                alt=""
                            />
                        )}
                        <div className={isModal ? "text-sm" : "jigsaw-text"}>{name}</div>
                    </div>
                    {isReceived && (
                        <div style={{ fontSize: "0.8rem", lineHeight: "0.8rem" }}>
                            <div className={isModal ? "text-sm mx-auto" : " mx-auto text-gray-600"}>{`${
                                num || "0"
                            } pieces`}</div>
                        </div>
                    )}
                </div>
            </span>
            <img
                className={"flex"}
                style={{
                    position: "relative",
                    width,
                    height,
                    filter: isReceived ? "" : "grayscale(100%)",
                    opacity: isReceived ? "1" : "0.5",
                    border: "1px solid",
                    boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.3)",
                }}
                src={dataUrl}
                alt="Jigsaw Piece"
            />
        </div>
    );
}
