import clsx from "clsx";
import { AdminPageEnum, useAdminSystemContext } from "../../../hooks/admin";
import { useUserContext } from "../../../hooks/auth";
import { Link } from "react-router-dom";

interface Props {
    pageName: string;
    historyList: { name: string; route: string }[];
}

export const AdminHeader = ({ pageName, historyList }: Props): JSX.Element => {
    const { user, logout } = useUserContext();
    const { showAdminMenu, handlerShowAdminMenu } = useAdminSystemContext();

    return (
        <div className="flex flex-col w-full min-w-screen">
            <div className="flex flex-row bg-white min-w-screen h-[70px] justify-between items-center pr-5 pl-5">
                <div className="curser-pointer flex flex-row items-center gap-2 justify-start">
                    <button className="" onClick={() => handlerShowAdminMenu(!showAdminMenu)}>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M30.9487 12.0225C31.0993 12.0225 31.25 11.9101 31.25 11.6854V10.3371C31.25 10.1685 31.0993 10 30.9487 10H9.05134C8.85045 10 8.75 10.1685 8.75 10.3371V11.6854C8.75 11.9101 8.85045 12.0225 9.05134 12.0225H30.9487ZM30.9487 21.0112C31.0993 21.0112 31.25 20.8989 31.25 20.6742V19.3258C31.25 19.1573 31.0993 18.9888 30.9487 18.9888H9.05134C8.85045 18.9888 8.75 19.1573 8.75 19.3258V20.6742C8.75 20.8989 8.85045 21.0112 9.05134 21.0112H30.9487ZM30.9487 30C31.0993 30 31.25 29.8876 31.25 29.6629V28.3146C31.25 28.1461 31.0993 27.9775 30.9487 27.9775H9.05134C8.85045 27.9775 8.75 28.1461 8.75 28.3146V29.6629C8.75 29.8876 8.85045 30 9.05134 30H30.9487Z"
                                fill="#333333"
                                fillOpacity="0.75"
                            />
                        </svg>
                    </button>

                    <h1 className="uppercase text-2xl font-medium">{pageName}</h1>
                </div>

                <button className="flex flex-row items-center gap-2 text-[#616161] hover:text-[#464747]">
                    <svg
                        className="w-[30px] h-[30px] fill-[#616161]"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M19.7274 20.4471C19.2716 19.1713 18.2672 18.0439 16.8701 17.2399C15.4729 16.4358 13.7611 16 12 16C10.2389 16 8.52706 16.4358 7.12991 17.2399C5.73276 18.0439 4.72839 19.1713 4.27259 20.4471"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <circle cx="12" cy="8" r="4" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                    <h1 className="">{user?.displayName}</h1>
                </button>
            </div>
            <hr />
            <div className="flex flex-row bg-white min-w-screen h-[50px] justify-between items-center px-10">
                <div className="flex flex-row">
                    {historyList.map((d, index) => {
                        return (
                            <div key={"route-id-" + index} className="flex flex-row">
                                <h1 className="text-base font-extralight text-[#929AA8]">{" / "}</h1>
                                <Link to={d.route}>
                                    <h1 className="text-base font-extralight text-[#929AA8] pl-1 pr-1 hover:text-[#464747]">
                                        {d.name}
                                    </h1>
                                </Link>
                            </div>
                        );
                    })}
                </div>
                <button className="flex flex-row justify-center items-center" onClick={logout}>
                    <svg
                        aria-hidden="true"
                        className="flex-shrink-0 w-6 h-6 text-gray-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                    <span className="flex-1 ml-3 text-[#929AA8] hover:text-[#464747]">Log Out</span>
                </button>
            </div>
        </div>
    );
};
