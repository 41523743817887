import moment from "moment";
import "moment/locale/th";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { UserContextWrapper } from "./hooks/auth";
import { NotFound } from "./screens/404";
import HomeScreen from "./screens/Home";
import { InventoryDesktop } from "./screens/InventoryDesktop";
import { InvitefriendDesktop } from "./screens/InvitefriendDesktop";
import { ItemBoxDesktop } from "./screens/ItemboxDesktop";
import { Login } from "./screens/Login";
import { Sandbox } from "./screens/Sandbox";
import { ShippingSuccessDesktop } from "./screens/ShippingSuccessDesktop";
import { Topup } from "./screens/Topup";
import { ShippingDetail } from "./screens/ShippingDetail";
import { OpenBoxHistoryAdminPage } from "./screens/AdminPage/OpenBoxHistory";
import { TopupHistoryAdminPage } from "./screens/AdminPage/TopupHIstory";
import { DialogWrapper } from "./hooks/dialog";
import { AdminSystemWrapper } from "./hooks/admin";
import { Forbidden } from "./screens/403";
import { AccountsAdminPage } from "./screens/AdminPage/Accounts";
import { BoxsAdminPage } from "./screens/AdminPage/Boxs";
import { BoxProductsAdminPage } from "./screens/AdminPage/BoxProducts";
import { BoxProductAdminPage } from "./screens/AdminPage/BoxProduct";
import { AccountAdminPage } from "./screens/AdminPage/Account";
import { BoxAdminPage } from "./screens/AdminPage/Box"; 
import { ShippingCreatDesktop } from "./screens/ShippingCreatDesktop";
import { AwardWinnerAdminPage } from "./screens/AdminPage/Awardwinner";
import { CategoryAdminPage } from "./screens/AdminPage/Category";
import { RandomJigsawHistory } from "./screens/AdminPage/RandomJigsawWinners";
import { DeliveryAdminPage } from "./screens/AdminPage/Delivery";

moment.locale("en");

const routerPaths = [
    {
        path: "/",
        element: <HomeScreen />,
    },

    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/invite-friend",
        element: <InvitefriendDesktop />,
    },
    {
        path: "/box/:id",
        element: <ItemBoxDesktop />,
    },
    {
        path: "/shipping",
        element: <ShippingSuccessDesktop />,
    },
    {
        path: "/shipping/detail/:shippingId",
        element: <ShippingDetail />,
    },
    {
        path: "/inventory",
        element: <InventoryDesktop />,
    },
    {
        path: "/topup",
        element: <Topup />,
    },
    // admin page
    {
        path: "/admin/accounts",
        element: <AccountsAdminPage />,
    },
    {
        path: "/admin/account/:accountId",
        element: <AccountAdminPage />,
    },
    {
        path: "/admin/boxs",
        element: <BoxsAdminPage />,
    },
    {
        path: "/admin/box/:boxId",
        element: <BoxAdminPage />,
    },
    {
        path: "/admin/boxs/:boxId/products",
        element: <BoxProductsAdminPage />,
    },
    {
        path: "/admin/boxs/:boxId/products/:productId",
        element: <BoxProductAdminPage />,
    },
    {
        path: "/admin/openbox-history",
        element: <OpenBoxHistoryAdminPage />,
    },
    {
        path: "/admin/topup-history",
        element: <TopupHistoryAdminPage />,
    },
    {
        path: "/shipping-creat",
        element: <ShippingCreatDesktop />,
    },
    {
        path: "/admin/award-winner",
        element: <AwardWinnerAdminPage />,
    },
    // {
    //     path: "/admin/category",
    //     element: <CategoryAdminPage />,
    // },
    {
        path: "/admin/delivery",
        element: <DeliveryAdminPage />,
    },
    // {
    //     path: "/admin/random-jigsaw-history",
    //     element: <RandomJigsawHistory />,
    // },
    // sandbox page for dev server
    // {
    //   path: "/sandbox",
    //   element: <Sandbox />,
    // },
    // forbidden page
    {
        path: "/403",
        element: <Forbidden />,
    },
    // no found page
    {
        path: "*",
        element: <NotFound />,
    },
];

const router = createBrowserRouter(routerPaths);

export const App = () => {
    return (
        <DialogWrapper>
            <UserContextWrapper>
                <AdminSystemWrapper>
                    <RouterProvider router={router} />
                </AdminSystemWrapper>
            </UserContextWrapper>
        </DialogWrapper>
    );
};
