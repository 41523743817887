import { useEffect, useState } from "react";
import { FooterDesk } from "../../components/Footer/Desktop/FooterDesk";
import { Navbar } from "../../components/Navbar/Desktop";
import { useUserContext } from "../../hooks/auth";
import AuthCard from "./AuthCard/AuthCard";
import "./style.css";
import { toast } from "react-toastify";

export const Login = () => {
    const { user } = useUserContext();

    useEffect(() => {
        if (user) {
            toast.success("Login success!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setTimeout(() => {
                window.location.href = "/";
            }, 1000);
        }
    }, [user]);
    return (
        <>
            <div
                className={"login-desktop-bg"}
                // style={{
                //   backgroundImage:
                //     "url(https://anima-uploads.s3.amazonaws.com/projects/640b6764044c6df351260457/releases/64102c1923eae77c046d2d54/img/vibrance-1.gif)",
                // }}
            />
            <div className={"login-desktop"}>
                <Navbar />

                <div className="content flex flex-col justify-center items-center m-[100px]">
                    <AuthCard />
                </div>

                <FooterDesk />
            </div>
        </>
    );
};
