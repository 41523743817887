import { useCallback, useEffect, useMemo, useState } from "react";
import { Navbar } from "../../components/Navbar/Desktop";
import "./style.css";
import BankTransferSlip from "./BankTransferSlip/BankTransferSlip";
import { FooterDesk } from "../../components/Footer/Desktop/FooterDesk";
import TruemoneyGiftVouchers from "./TruemoneyGiftVouchers/TruemoneyGiftVouchers";
import { useUserContext } from "../../hooks/auth";
import number from "../../helpers/number-format";
import { AdminButton } from "../../components/AdminButton";
import { ICcoinBalance, getWalletBalance, withdrawWallet } from "../../api/cwallet";
import { CCurrency, CwalletAppnames } from "../../models/wallet";
import { toast } from "react-toastify";
import clsx from "clsx";
import { get } from "jquery";

enum TopupType {
    BankTransferSlip = "BANK_TRANSFER_SLIP",
    TruemoneyGiftVouchers = "TRUEMONEY_GIFT_VOUCHERS"
}

const handleSuccess = (success: { title: string; message: string }) => {
    toast(
        <div>
            <h1 className="font-bold text-green-400">{success.title}</h1>
            <p className="text-xs">{success.message}</p>
        </div>,
        {
            type: "success"
        }
    );
};

const handleError = (err: { title: string; message: string }) => {
    toast(
        <div>
            <h1 className="font-bold text-red-400">{err.title}</h1>
            <p className="text-xs">{err.message}</p>
        </div>,
        {
            type: "error"
        }
    );
};

export const Topup = () => {
    const [topupType, setTopupType] = useState<TopupType>(TopupType.TruemoneyGiftVouchers);
    const { user, reloadUserPoint } = useUserContext();
    const [ccoinBalance, setCcoinBalance] = useState<ICcoinBalance>({
        ccoin: 0,
        cwallet: 0,
        ccoupon: 0
    });
    const [selectedOption, setSelectedOption] = useState<CCurrency>(CCurrency.CCOIN);
    const [amount, setAmount] = useState<number>();
    const [openModal, setOpenModal] = useState<boolean>(false);

    const handleOnDeposit = useCallback(() => {
        setOpenModal(true);
    }, []);

    const handleSelectChange = e => setSelectedOption(e.target.value);

    useEffect(() => {
        if (user?.id) {
            getWalletBalanceHandle();
        }
    }, [user?.id]);

    const getWalletBalanceHandle = () => {
        getWalletBalance()
            .then(res => {
                console.log(res);
                setCcoinBalance(res);
            })
            .catch(err => {
                console.log(err);
            });
    };
    const validateSubmitDeposit = useMemo(() => {
        if (!user?.id) return true;
        if (selectedOption === CCurrency.CWALLET && amount && ccoinBalance.cwallet < amount) {
            return true;
        }

        if (selectedOption === CCurrency.CCOUPON && amount && ccoinBalance.ccoupon < amount) {
            return true;
        }

        if (selectedOption === CCurrency.CCOIN && amount && ccoinBalance.ccoin < amount) {
            return true;
        }

        return false;
    }, [amount, selectedOption, user]);

    const validateInput = useMemo(() => {
        const validate = /^\d*\.?\d+$/;

        if (amount) {
            return !validate.test(amount.toString());
        }
    }, [amount]);

    const handleSubmit = async () => {
        if (user && amount && selectedOption) {
            await withdrawWallet({
                amount,
                currency: selectedOption
            })
                .then(res => {
                    toast.success("Withdraw wallet success");
                    setCcoinBalance(res);
                    reloadUserPoint();
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message || "Withdraw wallet failed");
                    setAmount(undefined);
                })
                .finally(() => {
                    setOpenModal(false);
                });
        } else {
            setOpenModal(false);
            return handleError({
                title: "Error",
                message: "Please select currency and amount"
            });
        }
    };

    return (
        <div
            className="bg-cover topup-desktop min-h-screen"
            style={{
                backgroundImage: "url(/img/inventory/bg.png)"
            }}
        >
            <div className="bg-bulr">
                <Navbar />
                <div className="w-full py-12">
                    <div className="boxOutlay flex flex-col w-1/2  mx-auto mt-8 border border-[#3c4069] border-solid rounded-lg backdrop-blur-3xl gap-y-8 text-primary-400">
                        <div className="">
                            <img src={"/img/vibrance1.png"} />
                        </div>
                        <div>
                            <div className="grid grid-cols-3 justify-center	items-center gap-2">
                                <div className="flex justify-end items-end w-0 xl:w-full">
                                    <img src={"/img/ImgLines/line3.png"} />
                                </div>
                                <div className="flex justify-center	items-center ">
                                    <img
                                        className={"shipping-success-desktop-iconsax-linear-truckfast mr-3"}
                                        src={"/img/topup/walletmoney.svg"}
                                    />
                                    <h1 className={"MainBox-name-box-label"}>Deposit</h1>
                                </div>
                                <div className="w-0 xl:w-full">
                                    <img src={"/img/ImgLines/line4.png"} />
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col justify-center items-center gap-2 px-5 mb-10 mt-8">
                            <div className="w-full">
                                <div className="flex gap-4 mb-3">
                                    <div className="md:text-lg text-white flex-1">CCOIN</div>
                                    <div className="md:text-lg">${number.format(ccoinBalance.ccoin || 0)}</div>
                                </div>

                                <div className="flex gap-4 mb-3">
                                    <div className="md:text-lg text-white flex-1">CWALLET</div>
                                    <div className="md:text-lg">${number.format(ccoinBalance.cwallet || 0)}</div>
                                </div>

                                <div className="flex gap-4 mb-3">
                                    <div className="md:text-lg text-white flex-1">CCOUPON</div>
                                    <div className="md:text-lg">${number.format(ccoinBalance.ccoupon || 0)}</div>
                                </div>

                                <div className="w-full">
                                    <div className="flex items-center gap-2 justify-center">
                                        <label className="mr-2 flex-1 md:w-[200px]" htmlFor="select">
                                            Select Your Wallet
                                        </label>
                                        <select
                                            id="select"
                                            name="select"
                                            value={selectedOption}
                                            onChange={handleSelectChange}
                                            className="p-2 border border-gray-500 rounded-md w-full max-w-[150px] flex-1 bg-black"
                                        >
                                            <option value="CCOIN" className="">
                                                CCOIN
                                            </option>
                                            <option value="CWALLET">CWALLET</option>
                                            <option value="CCOUPON">CCOUPON</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="md:flex gap-4 items-center">
                                <div className="col-start-1 md:text-xl text-white mt-10">AMOUNT (USD) :</div>
                                <div className="md:text-xl flex flex-col mt-10">
                                    <input
                                        type="number"
                                        name="amount"
                                        placeholder="Ex. 300"
                                        className={clsx(
                                            "w-full px-6 py-3 text-base bg-black border rounded-md",
                                            validateSubmitDeposit ? "border-red-500" : "",
                                            Boolean(validateInput) ? "!border-red-500 ring-red-500" : ""
                                        )}
                                        onChange={e => {
                                            const parsedValue = parseFloat(e.target.value.trim());
                                            setAmount(parseFloat(isNaN(parsedValue) ? "0" : parsedValue.toString()));
                                        }}
                                    />

                                    {Boolean(validateSubmitDeposit) && (
                                        <span className="text-red-500 text-xs absolute handle-error-text">
                                            The wallet balnace has not enough
                                        </span>
                                    )}
                                    {Boolean(validateInput) && (
                                        <span className="text-red-500 text-xs absolute handle-error-text">
                                            {`The value has not matching format (should be positive number only)`}
                                        </span>
                                    )}
                                </div>

                                <div className=" col-span-2 md:col-span-1 mt-10">
                                    <div className="flex items-center justify-center ">
                                        <button
                                            onClick={handleOnDeposit}
                                            disabled={validateInput || validateSubmitDeposit}
                                            className={clsx(
                                                "flex items-center justify-center  py-2 text-base font-medium text-[#734700] transition duration-300 ease-in-out rounded-[25px] px-9  hover:shadow-signUp",
                                                validateSubmitDeposit || validateInput
                                                    ? "bg-slate-300 text-white hover:text-white"
                                                    : "bg-signin cursor-pointer"
                                            )}
                                        >
                                            Deposit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {openModal && (
                        <div>
                        {/* TODO: Should spilt to compoenet */}
                        <div className="modal ">
                            <div className="modal-content !top-[25%] ">
                                <div className="flex flex-col gap-8">
                                    <div className="font-semibold">
                                        Are you sure, You want to widthdraw the wallet?
                                    </div>
                                    <div className="flex gap-2 justify-end">
                                        <AdminButton main="secondary" onClick={() => setOpenModal(false)}>
                                            Close
                                        </AdminButton>
                                        <AdminButton main="secondary"  onClick={() => handleSubmit()}>
                                            Confirm
                                        </AdminButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
                <FooterDesk />
            </div>
        </div>
    );
};
