import React from 'react';

const Badge = ({ status, children }) => {
  const normalizedStatus = status.toLowerCase();

  const getStatusColor = () => {
    switch (normalizedStatus) {
      case 'success':
        return 'bg-green-500';
      case 'จัดส่งแล้ว':
        return 'bg-green-500';
      case 'inprogress':
          return 'bg-blue-500';
      case 'กำลังดำเนินการจัดส่ง':
          return 'bg-blue-500';
      case 'warning':
        return 'bg-yellow-500';
      case 'error':
        return 'bg-red-500';
      default:
        return 'bg-gray-500';
    }
  };

  return (
    <span
      className={`inline-block text-white font-bold px-2 py-1 rounded ${getStatusColor()}`}
    >
      {children}
    </span>
  );
};

export default Badge;
