import { useEffect, useState } from "react";
import {
    BoxForAdmin,
    createBoxsForAdmin,
    getBoxByBoxIdForAdmin,
    removeBoxByBoxIdForAdmin,
    updateBoxByBoxIdForAdmin,
} from "../../../api/admin";
import { useUserContext } from "../../../hooks/auth";
import { useDialogContext } from "../../../hooks/dialog";
import { AdminHeader, AdminPanel } from "../../../components/Layout/AdminPanel";
import { AdminPageEnum, useAdminSystemContext } from "../../../hooks/admin";
import { Navigate, useParams } from "react-router-dom";
import number from "../../../helpers/number-format";
import { AdminButton } from "../../../components/AdminButton";
import { toast } from "react-toastify";
import ImageUpload from "../../../components/UploadImageToFirebase";
import { fetchAllCategory } from "../../../api/Home";
import { IJigsawProductCategory } from "../../../interfaces/Box";
import JigsawBoard from "../../../components/Jigsaw/Board";

export const BoxAdminPage = () => {
    const { boxId } = useParams();
    const { isAdmin, user } = useUserContext();
    const { handlerActiveAdminPage } = useAdminSystemContext();
    const { showLoading, closeLoading } = useDialogContext();
    const [category, setCategory] = useState<IJigsawProductCategory[]>([]);

    const numberFormat = new Intl.NumberFormat("th", {
        minimumFractionDigits: 2,
    });
    const dateFormater = new Intl.DateTimeFormat("th", { dateStyle: "medium", timeZone: "Asia/Bangkok" });
    const [box, setBox] = useState<BoxForAdmin>({
        id: 0,
        name: "",
        imageUrl: "",
        jigsawPrice: 0,
        detail: "",
        jigsawImageUrl: "",
        productPrice: 0,
        margin: 0,
        itemRemain: 0,
        jigsawSizeX: 2,
        jigsawSizeY: 2,
        marginBreakPoint: 0,
        isDeleted: false,
        createdAt: new Date(),
        updatedAt: new Date(),
        categoryId: 0,
    });

    const handleError = (err: { title: string; message: string }) => {
        toast(
            <div>
                <h1 className="font-bold text-red-400">{err.title}</h1>
                <p className="text-xs">{err.message}</p>
            </div>,
            {
                type: "error",
            }
        );
    };

    const [isEditData, setIsEditData] = useState(true);

    useEffect(() => {
        showLoading();

        if (user) {
            if (!isAdmin) {
                window.location.href = "/403";
            } else {
                fetchAllCategory()
                    .then((res) => {
                        setCategory(res);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                if (boxId == "add") {
                    setIsEditData(true);
                    setBox({
                        createdAt: new Date(),
                        detail: "",
                        id: 0,
                        imageUrl: "",
                        isDeleted: false,
                        itemRemain: 0,
                        jigsawImageUrl: "",
                        jigsawPrice: 0,
                        jigsawSizeX: 2,
                        jigsawSizeY: 2,
                        margin: 0,
                        marginBreakPoint: 0,
                        name: "",
                        productPrice: 0,
                        updatedAt: new Date(),
                        categoryId: 0,
                    });
                    closeLoading();
                } else if (boxId && !isNaN(Number(boxId))) {
                    getBoxByBoxIdForAdmin(Number(boxId))
                        .then(setBox)
                        .catch((error) => {
                            handleError({
                                title: "Something went wrong!",
                                message: "Please try again",
                            });
                        })
                        .finally(() => {
                            closeLoading();
                        });
                } else {
                    closeLoading();
                    handleError({
                        title: "Something went wrong!",
                        message: "Please try again",
                    });
                }
            }
        }
    }, [user]);

    handlerActiveAdminPage(AdminPageEnum.boxs);
    return (
        <>
            <AdminPanel>
                <>
                    {/* Header */}
                    <AdminHeader
                        pageName="Dashboard"
                        historyList={[
                            {
                                name: "Boxs",
                                route: "/admin/boxs",
                            },
                            {
                                name: "Box",
                                route: `/admin/box/${boxId}`,
                            },
                        ]}
                    />
                    {/* Table Data */}
                    <div className="flex flex-col p-4 m-10 max-lg:m-0 max-lg:pt-10 bg-white rounded-lg shadow">
                        <div className="flex flex-row justify-between">
                            <div className="flex flex-col">
                                <h1 className="text-lg font-bold">BoxProduct</h1>
                                <p className="text-sm text-gray-400">ข้อมูลกล่องสุ่ม</p>
                            </div>

                            <div className="flex">
                                {isEditData ? (
                                    <div className="flex flex-row gap-5 justify-center items-center">
                                        <AdminButton
                                            main="secondary"
                                            onClick={() => {
                                                setIsEditData(false);
                                                if (boxId == "add") {
                                                    window.location.href = `/admin/boxs`;
                                                }
                                                getBoxByBoxIdForAdmin(Number(boxId))
                                                    .then(setBox)
                                                    .catch((error) => {
                                                        handleError({
                                                            title: "Something went wrong!",
                                                            message: "Please try again",
                                                        });
                                                    });
                                            }}
                                        >
                                            Cancel
                                        </AdminButton>

                                        <AdminButton
                                            main="primary"
                                            onClick={() => {
                                                setIsEditData(false);
                                                showLoading();
                                                if (boxId == "add") {
                                                    createBoxsForAdmin(box)
                                                        .then((box) => {
                                                            window.location.href = `/admin/boxs`;
                                                            toast(
                                                                <div>
                                                                    <h1 className="font-bold text-green-400">
                                                                        Success
                                                                    </h1>
                                                                    <p className="text-xs">Create box success</p>
                                                                </div>,
                                                                {
                                                                    type: "success",
                                                                }
                                                            );
                                                        })
                                                        .catch((error) => {
                                                            handleError({
                                                                title: "Something went wrong!",
                                                                message: "Please try again",
                                                            });
                                                        })
                                                        .finally(() => {
                                                            closeLoading();
                                                        });
                                                } else {
                                                    updateBoxByBoxIdForAdmin(Number(boxId), box)
                                                        .then(setBox)
                                                        .catch((error) => {
                                                            handleError({
                                                                title: "Something went wrong!",
                                                                message: "Please try again",
                                                            });
                                                        })
                                                        .finally(() => {
                                                            closeLoading();
                                                        });
                                                }
                                            }}
                                        >
                                            Save
                                        </AdminButton>
                                    </div>
                                ) : (
                                    <div className="flex justify-center items-center">
                                        <AdminButton main="default" onClick={() => setIsEditData(true)}>
                                            Edit
                                        </AdminButton>
                                        <AdminButton
                                            main="default"
                                            onClick={() => {
                                                showLoading();
                                                removeBoxByBoxIdForAdmin(Number(boxId));
                                                toast(
                                                    <div>
                                                        <h1 className="font-bold text-green-400">Success</h1>
                                                        <p className="text-xs">Remove box success</p>
                                                    </div>,
                                                    {
                                                        type: "success",
                                                    }
                                                );
                                                window.location.href = `/admin/boxs`;
                                            }}
                                        >
                                            Remove
                                        </AdminButton>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="flex flex-col gap-5 p-5">
                            {/* id */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>id</h1>
                                </div>
                                <div className="flex text-[#464747]">{box?.id}</div>
                            </div>

                            {/* name */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>name</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={box.name}
                                            onChange={(e) => {
                                                setBox({
                                                    ...box,
                                                    name: e.target.value,
                                                });
                                            }}
                                            type="text"
                                            id="box-input-name"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="name"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">{box?.name}</div>
                                )}
                            </div>
                            {/*  category */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>categoryId</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <select
                                            title="categoryId"
                                            id="boxProduct-select-jigsawSizeX"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-md focus:ring-gray-400 focus:border-gray-400 w-full"
                                            onChange={(e) => {
                                                setBox({
                                                    ...box,
                                                    categoryId: Number(e.target.value),
                                                });
                                            }}
                                        >
                                            <option
                                                selected={
                                                    category.filter((cate) => cate.id == box.categoryId).length == 0
                                                }
                                                value={0}
                                            >
                                                uncategorized
                                            </option>
                                            {category
                                                .filter((item) => !item.isDeleted)
                                                .map((item) => (
                                                    <option selected={box.categoryId == item.id} value={item.id}>
                                                        {item.id} - {item.name}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">
                                        {(box?.categoryId || "0") +
                                            " - " +
                                            (category.filter((item) => item.id == box.categoryId)[0]?.name ||
                                                "uncategories")}
                                    </div>
                                )}
                            </div>
                            {/* imageUrl */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>imageUrl</h1>
                                </div>
                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <ImageUpload
                                            image={box.imageUrl}
                                            onUploaded={(url) => {
                                                setBox({
                                                    ...box,
                                                    imageUrl: url,
                                                });
                                            }}
                                        ></ImageUpload>
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">
                                        <img className="w-[10rem]" src={box.imageUrl} alt="imageUrl" />
                                    </div>
                                )}
                            </div>
                            {/* jigsawImageUrl */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>jigsawImageUrl</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <ImageUpload
                                            image={box.jigsawImageUrl}
                                            onUploaded={(url) => {
                                                setBox({
                                                    ...box,
                                                    jigsawImageUrl: url,
                                                });
                                            }}
                                        ></ImageUpload>
                                        {/* <input
                                            value={box.jigsawImageUrl}
                                            onChange={(e) => {
                                                setBox({
                                                    ...box,
                                                    jigsawImageUrl: e.target.value,
                                                });
                                            }}
                                            type="text"
                                            id="box-input-imageUrl"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="imageUrl"
                                            required
                                        /> */}
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">
                                        <img className="w-[10rem]" src={box.jigsawImageUrl} alt="jigsawImageUrl" />
                                    </div>
                                )}
                            </div>
                            {/* productPrice */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>price per bet</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={box.productPrice}
                                            onChange={(e) => {
                                                setBox({
                                                    ...box,
                                                    productPrice: Number(e.target.value),
                                                });
                                            }}
                                            type="number"
                                            id="box-input-price"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="price"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">
                                        {box.productPrice ? `${numberFormat.format(box.productPrice)}` : "null"}
                                    </div>
                                )}
                            </div>
                            {/* item remain */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>item remain</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={box.itemRemain}
                                            onChange={(e) => {
                                                setBox({
                                                    ...box,
                                                    itemRemain: Number(e.target.value),
                                                });
                                            }}
                                            type="number"
                                            id="box-input-price"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="item remain"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">
                                        {box.itemRemain ? `${numberFormat.format(box.itemRemain)}` : "0"}
                                    </div>
                                )}
                            </div>
                            {/* detail */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>detail</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={box.detail.toString()}
                                            onChange={(e) => {
                                                setBox({
                                                    ...box,
                                                    detail: e.target.value,
                                                });
                                            }}
                                            type="text"
                                            id="box-input-price"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="detail"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">{box.detail ? `${box.detail}` : "null"}</div>
                                )}
                            </div>
                            {/* Jigsaw (piece) */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>Jigsaw (piece)</h1>
                                </div>

                                {isEditData && boxId == "add" ? (
                                    <div className="flex text-[#464747]">
                                        {/* select dropdown values "2x2", "3x3", "5x5" */}
                                        <select
                                            title=""
                                            id="boxProduct-select-jigsawSizeX"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-md focus:ring-gray-400 focus:border-gray-400 w-full"
                                            onChange={(e) => {
                                                let split = e.target.value.split("x");
                                                split[0] = split[0].replace(/^0+/, "");
                                                split[1] = split[1].replace(/^0+/, "");
                                                console.log(split);
                                                setBox({
                                                    ...box,
                                                    jigsawSizeX: Number(split[0]),
                                                    jigsawSizeY: Number(split[1]),
                                                });
                                            }}
                                        >
                                            <option
                                                selected={box.jigsawSizeX == 2 && box.jigsawSizeY == 2}
                                                value={"2x2"}
                                            >
                                                2x2
                                            </option>
                                            <option
                                                selected={box.jigsawSizeX == 3 && box.jigsawSizeY == 3}
                                                value={"3x3"}
                                            >
                                                3x3
                                            </option>
                                            <option
                                                selected={box.jigsawSizeX == 4 && box.jigsawSizeY == 4}
                                                value={"4x4"}
                                            >
                                                4x4
                                            </option>
                                            <option
                                                selected={box.jigsawSizeX == 5 && box.jigsawSizeY == 5}
                                                value={"5x5"}
                                            >
                                                5x5
                                            </option>
                                            <option
                                                selected={box.jigsawSizeX == 5 && box.jigsawSizeY == 3}
                                                value={"5x3"}
                                            >
                                                5x3
                                            </option>
                                            <option
                                                selected={box.jigsawSizeX == 3 && box.jigsawSizeY == 5}
                                                value={"3x5"}
                                            >
                                                3x5
                                            </option>
                                        </select>
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">
                                        {box.jigsawSizeX ? `${box.jigsawSizeX}` : "null"} x
                                        {box.jigsawSizeY ? ` ${box.jigsawSizeY}` : "null"}
                                    </div>
                                )}
                            </div>
                            {/* preview jigsaw board */}
                            {box.jigsawImageUrl && box.jigsawSizeX && box.jigsawSizeY && (
                                <JigsawBoard
                                    x={box.jigsawSizeX}
                                    y={box.jigsawSizeY}
                                    src={box.jigsawImageUrl}
                                    data={[]}
                                />
                            )}
                            {/* margin */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>margin</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={box.margin}
                                            onChange={(e) => {
                                                setBox({
                                                    ...box,
                                                    margin: Number(e.target.value),
                                                });
                                            }}
                                            type="number"
                                            id="box-input-margin"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="margin"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">{`${numberFormat.format(box.margin)}`}</div>
                                )}
                            </div>
                            {/* margin breakpoint */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>margin break point</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <input
                                            value={box.marginBreakPoint}
                                            onChange={(e) => {
                                                setBox({
                                                    ...box,
                                                    marginBreakPoint: Number(e.target.value),
                                                });
                                            }}
                                            type="number"
                                            id="box-input-margin"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-gray-400 focus:border-gray-400 w-full"
                                            placeholder="margin"
                                            required
                                        />
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">{`${numberFormat.format(
                                        box.marginBreakPoint
                                    )}`}</div>
                                )}
                            </div>
                            {/* isDeleted */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>isDeleted</h1>
                                </div>

                                {isEditData ? (
                                    <div className="flex text-[#464747]">
                                        <select
                                            id="boxProduct-select-isDefaultBoxProduct"
                                            className="text-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-md focus:ring-gray-400 focus:border-gray-400 w-full"
                                            onChange={(e) => {
                                                setBox({
                                                    ...box,
                                                    isDeleted: e.target.value === "true",
                                                });
                                            }}
                                        >
                                            <option selected={box.isDeleted == true} value={"true"}>
                                                true
                                            </option>
                                            <option selected={box.isDeleted == false} value={"false"}>
                                                false
                                            </option>
                                        </select>
                                    </div>
                                ) : (
                                    <div className="flex text-[#464747]">{box?.isDeleted ? "true" : "false"}</div>
                                )}
                            </div>

                            {/* createdAt */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>createdAt</h1>
                                </div>
                                <div className="flex text-[#464747]">
                                    {box.createdAt ? dateFormater.format(new Date(box.createdAt)) : "null"}
                                </div>
                            </div>

                            {/* updatedAt */}
                            <div className="flex flex-row">
                                <div className="flex w-1/4">
                                    <h1>updatedAt</h1>
                                </div>
                                <div className="flex text-[#464747]">
                                    {box.updatedAt ? dateFormater.format(new Date(box.updatedAt)) : "null"}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </AdminPanel>
        </>
    );
};
