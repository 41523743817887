import { useEffect, useState } from "react";
import {
    IAdminLiveDropHistory,
    OpenBoxHistoryForAdmin,
    RoundWinnerHistory,
    getOpenBoxHistoryForAdmin,
    getRoundWinnerForAdmin
} from "../../../api/admin";
import { useUserContext } from "../../../hooks/auth";
import { useDialogContext } from "../../../hooks/dialog";
import { AdminHeader, AdminPanel } from "../../../components/Layout/AdminPanel";
import { AdminPageEnum, useAdminSystemContext } from "../../../hooks/admin";
import number from "../../../helpers/number-format";
import AdminAwardWinner from "../../../components/AdminTable/AdminAwardWinnerTable";

export const AwardWinnerAdminPage = () => {
    const { isAdmin, user } = useUserContext();
    const { handlerActiveAdminPage } = useAdminSystemContext();
    const { showLoading, closeLoading } = useDialogContext();

    const dateFormater = new Intl.DateTimeFormat("th", { dateStyle: "medium", timeZone: "Asia/Bangkok" });
    const [roundWinner, setRoundWinner] = useState<RoundWinnerHistory[]>([]);

    useEffect(() => {
        showLoading();
        if (user) {
            if (!isAdmin) {
                window.location.href = "/403";
            } else {
                getRoundWinnerForAdmin()
                    .then(data => {
                        setRoundWinner(data);
                    })
                    .catch(err => {
                        console.log(err);
                    })
                    .finally(() => {
                        closeLoading();
                    });
            }
        }
    }, [user]);

    handlerActiveAdminPage(AdminPageEnum.awardwinner);
    return (
        <>
            <AdminPanel>
                <>
                    {/* Header */}
                    <AdminHeader
                        pageName="Dashboard"
                        historyList={[
                            {
                                name: "Awardwinner",
                                route: "/admin/award-winner"
                            }
                        ]}
                    />
                    {/* Table Data */}
                    <div className="flex flex-col p-4 m-10 max-lg:m-0 max-lg:pt-10 bg-white rounded-lg shadow">
                        <h1 className="text-lg font-bold">Award Winner History</h1>
                        <p className="text-sm text-gray-400">ประวัติผู้ชนะรางวัล</p>
                        <AdminAwardWinner data={roundWinner} dateFormater={dateFormater} />
                    </div>
                </>
            </AdminPanel>
        </>
    );
};
